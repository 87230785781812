import React, {useRef, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import { toast } from 'react-toastify'
import { Layout } from '../../components/Layout/Layout'
import { formattedDate } from '../../utils/helpers'
import NextButton from '../../components/NextButton'
import BackButton from '../../components/BackButton'


import { useCSVReader } from 'react-papaparse';

import SampleCsv from '../../../src/assets/files/sample-csv.csv'


const RegisterPx = () => {

  const { REACT_APP_MD_MAINUS_BASEURL } = process.env;
  const { userState, userDispatch } = React.useContext(AuthContext) 
 
  // console.log(userState.user)

  const navigate = useNavigate()

  const first_name = useRef(null)
  const last_name =useRef(null)
  const address = useRef(null)
  const mobile_number = useRef(null)
  const email = useRef(null)
  const [gender, setGender] = useState()
  const [birthday, setBirthday] = useState();
  
  const requestHeaders = { 'Authenticate': `Bearer ${localStorage.token}`, 'content-type': 'application/json' }
  const md_id = localStorage.admin_id

  // const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)


  const { CSVReader } = useCSVReader()
    

  const handleRegisterPx = e => {
      e.preventDefault()
      
      // if (email?.current.value && password?.current.value ) {
        const customerData = {
          customer: {   
            first_name: first_name?.current.value,
            last_name: last_name?.current.value,
            gender: gender,
            address: address?.current.value,
            mobile_number: mobile_number?.current.value,
            birthday: formattedDate(birthday),
            email: email?.current?.value?.toLowerCase(),
            password: `${first_name?.current?.value?.split(' ').join('').toLowerCase().slice(0, 3)}LAH#2022`
          }
        };
                
        setLoading(true)
          axios({
            method: "POST",
            url:`${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/customers`,
            data: customerData,
            headers: requestHeaders 
          })
          .then((data) => {
            setLoading(false)
            if (data.status === 200) {
              toast.success("Successfully created a user")
              navigate('/md/customers')
            }
          })
          .catch((error)=> { 
            toast.error(error)
            setLoading(false)
          })
    // } else {
    //   console.error("Please check required fields.");
    //   setLoading(false)
    // }
  }

  // useEffect(() => {
  //   // regex for YYYY-MM-DD
  //   const regexddmmyyyy = (19|20)\d\d$/[- /.](0[1-9]|1[012])[- /.]/^(0[1-9]|[12][0-9]|3[01])[- /.];

  //   if (regexddmmyyyy.test(birthday)) {
  //     setValid(true);
  //   } else {
  //     setValid(false);
  //     // toast.error("Invalid format for birthdate")
  //   }
  // }, [birthday]);

  

  // console.log(`${first_name?.current?.value?.slice(0, 3)}LAH#2022`)


  // const [file, setFile] = useState(null);
  const [myPatients, setMyPatients] = useState(null)

  // const handleChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   console.log(file)
  // }

  const importPatientStyle = {
    "flex": "1 1 0%",
    "display": "flex",
    "flex-direction": "column",
    "align-items": "center",
    "justify-content": "center",
    "padding": "16px",
    "border-width": "4px",
    "border-radius": "2px",
    "min-width": "45%",
    "max-width": "45%",
    "border-color": "rgb(0, 202, 194)",
    "border-style": "dashed",
    "background-color": "rgb(164, 226, 224)",
    "color": "rgb(75, 76, 77)",
    "outline": "none",
    "transition": "border 0.24s ease-in-out 0s",
    "position": "relative",
    "cursor": "pointer",

  }


  const handleImportPx = () => {

    const patientsData = {
        patients: myPatients 
    }

    axios({
      method: "POST",
      url: `${ REACT_APP_MD_MAINUS_BASEURL }/doctor/${md_id}/customers/import_clinic_patients`,
      data: patientsData,
      headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },  
    })
    .then((data) => {
      console.log("mydata", data)
      toast.success("Successfully Imported Patients")
      navigate('/md/customers')
    })

    .catch(function (error) {
      if (error.response) {
        setLoading(false)
        toast.error(error.response.data.message[0])
      }
    })


  }
  
 
  return (
   <>
    <Layout> 
      <div className="container p-5">

      <h2 className='text-secondary'>Import Patients</h2>
      <hr className='mb-1'/>

      <p className='mb-1 mt-3'>Upload csv file to add multiple patients</p>
      <p>Please refer to the downloadable csv file for the proper format when uploading</p>

      



      <CSVReader
        onUploadAccepted={(results) => {
          console.log('---------------------------');
          console.log(results);
          console.log('---------------------------');
          setMyPatients(results?.data.slice(0, -1))
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }) => (
          <>
            <div >
              <div className="d-flex flex-column align-items-center">

                <div className="mb-3">
                  <a href={SampleCsv} download="SampleCsv.csv" target='_blank' className="">
                    <button style={{"width": "200px"}} className="btn btn-outline-secondary p-2 mt-2" >
                      Download Sample CSV
                    </button>
                  </a>
                </div>

                <div type='button' className="import-patient" {...getRootProps()} >
                  <p className='text-center m-auto'><i className='bx bx-cloud-upload upload-icon' ></i><br/><strong>Drag & drop <br/>or click to upload csv file</strong></p>
                  <div className="mt-3" >
                    {myPatients && (acceptedFile && acceptedFile.name)}
                  </div>
                </div>

                {myPatients && 
                <div className="mt-2">
                  <button  className="btn btn-danger text-white border-0 p-2 mt-2"  onClick={() => {setMyPatients(null); getRemoveFileProps()}} >
                      Remove Uploaded csv
                  </button>
                </div>}  

              </div>
            </div>
            <ProgressBar />
          </>
        )}
      </CSVReader>

      



      
      {myPatients && <h3 className="mt-4">Upload Preview</h3>}
      
        {myPatients && <div id="customers-table" className='card border rounded-3 table-card-container scrollbar-div'>  
          <div className="table-responsive scrollbar-div">
            <table className="bg-white table table-sm header-fixed">
              <thead className="sticky-top bg-white">
                <tr> 
                  <th>first_name</th>
                  <th>last_name</th>
                  <th>address</th>
                  <th>mobile_number</th>
                  <th>birthday</th>
                  <th>email</th>
                  <th>gender</th>  
                </tr>
              </thead>
              <tbody> 
              {myPatients && myPatients.slice(1, 11).map((patient, index) => (
                <tr key={`md-patients-preview-${index}`} className="text-center">
                  <td>{patient[0]}</td>
                  <td>{patient[1]}</td>
                  <td>{patient[2]}</td>
                  <td>{patient[3]}</td>
                  <td>{patient[4]}</td>
                  <td>{patient[5]}</td>
                  <td>{patient[6]}</td>
                </tr>
              ))}

              </tbody>
            </table>
            
          </div>
        </div>}
        
        {myPatients &&
          <div className="d-flex flex-column align-items-center">
            <button className="btn lah_gradient text-white border-0 p-2 mt-3 " onClick={handleImportPx}>Import Patients</button>
          </div>}
        
      








      
        <form onSubmit={ handleRegisterPx } className="mt-3 text-left ">
       
          <h2 className='text-secondary mt-5'>Add Patient</h2>
          <hr className='mb-5'/>

          <div className='row jumbotron'>
            <div className="col-sm-6 form-group mb-2">
              <label className='required' htmlFor="name-f">First Name</label>
              <input type="text" ref={ first_name } className="form-control rounded-0" placeholder="Enter First Name" required/>
            </div>

            <div class="col-sm-6 form-group mb-2">
              <label className='required' htmlFor="name-l">Last name</label>
              <input type="text" ref={ last_name } className="form-control rounded-0" placeholder="Last Name" required/>
            </div>

            <div class="col-sm-6 form-group mb-2">
              <label className='required' htmlFor="email">Email</label>
              <input type="email" ref={ email } className="form-control rounded-0" placeholder="Email" required/>
            </div>

            <div class="col-sm-6 form-group mb-2">
              <label className='required' htmlFor="sex">Gender</label>
              <select id="sex" class="form-select form-control browser-default custom-select rounded-0 required" onChange={(e)=> setGender(e.target.value)}>
              <option value="male" name="Male">Male</option>
              <option value="female" name="Female">Female</option>
          </select>
          </div>
          <div class="col-sm-6 form-group mb-2">
              <label className='required' htmlFor="Date">Date Of Birth</label>
              <input type="date" value={ birthday } className="form-control rounded-0" placeholder="YYYY-MM-DD" onChange={(e)=>setBirthday(e.target.value)} required/>
            </div>
            {/* <div class="col-sm-6 form-group">
              <label htmlFor="pass">Password</label>
              <input type="password" ref={ password } name="password" className="form-control rounded-0 required" id="pass" placeholder="Enter 6-digit password." required />
            </div> */}
             <div class="col-sm-6 form-group mb-2">
              <label className='required' htmlFor="tel">Phone</label>
              <input type="text" name="phone" ref={ mobile_number } maxlength="10" pattern="[0-9]*" className="form-control rounded-0" id="tel" placeholder="Enter 10-digit Contact Number." required />
            </div>

            <div class="col-sm-12 form-group mb-5">
              <label className='required' htmlFor="tel">Address</label>
              <input type="text" ref={ address } className="form-control rounded-0" placeholder="Enter Address" required/>
            </div>
                 
          <div className="d-flex justify-content-center align-items-center">
            <BackButton onClick={() => navigate(-1) }>Back</BackButton>
            <NextButton>Submit</NextButton>
          </div>
          </div>
        </form>     
      </div>
    </Layout>
  </>    
  )
}

export default RegisterPx