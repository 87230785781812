/* ------------------- Hooks -------------------- */
import React, { useContext, useState, useRef, useEffect, useCallback } from 'react'
import { useNavigate, Navigate } from 'react-router'
import axios from 'axios'
import { toast } from 'react-toastify';
import admin_logo from '../../assets/logo_md.png'
import { AuthContext } from '../../context/AuthContext';
import ParticlesConfig from '../../utils/ParticlesConfig';


const ForgotPassword = ({ forClinic }) => {
  // const { userDispatch } = React.useContext(AuthContext)
  const navigate = useNavigate()
//   const userEmail = useRef(null);
  const [email, setEmail] = useState("");
//   const userPassword = useRef(null);
  const { REACT_APP_MD_AUTH_BASEURL,  REACT_APP_CLINIC_AUTH_BASEURL } = process.env;
  const [loading, setLoading] = useState(false)


  const handleForgot = (e) => {
    e.preventDefault()

    const payload = {
      email: email
    }

    const mdData = forClinic ? { clinic: payload } : { doctor: payload }

    setLoading(true)
    // toast.success("Please check your email for reset password instructions")
    // navigate(forClinic ? "/md/clinic/reset_password" : "/md/reset_password")
    // console.log("FORGOT PAYLOAD: ", adminData)
    // setEmail("")

    axios({
      method: "POST",
      url: `${forClinic ? REACT_APP_CLINIC_AUTH_BASEURL : REACT_APP_MD_AUTH_BASEURL}/forgot_password`,
      headers: { 'content-type': 'application/json' },
      data: mdData
    })
      .then((data) => {
        setLoading(false)
        toast.success("Please check your email for reset password instructions.")
        // navigate(`/md/reset_password?email=${data?.data?.data?.doctor?.email}&token=${data?.data?.data?.token}`)
        // console.log(data)
        // navigate(`/md/${forClinic ? 'clinic/' : ''}login`)
        navigate('/md/forgot_password/success')
      })
      .catch((error) => {
        setLoading(false)
        if (error.response) {
          toast.error(error.response.data.error)
        }
      })
  };


  return (
    <>
      <div style={{ position: 'absolute' }}>

        <ParticlesConfig />
      </div>
      <div className="vh-100 bg-cyan">
        <div className="container h-100 py-5">
          <div className="row d-flex justify-content-center mt-5 h-100">
            <div className="col-sm col-md-8 col-lg-6 col-xl-5">
              <div className="card-login border-0 bg-cyan">
                <div className="card-body login p-5 text-center">
                  <div className="d-flex justify-content-center align-items-center py-5">
                    <img src={admin_logo} className="w-75 " />
                  </div>
                  <h2 className='text-secondary font-lato'>Forgot your password?</h2>
                  <form onSubmit={handleForgot} className="p-2 mt-3 text-center">
                    <h6 className="login-heading mb-4 fw-normal text-secondary text-greeting">Enter {forClinic ? "Clinic's" : "your"} email to receive reset password instructions.</h6>

                    <div className="input-group mb-3">
                      <span className="input-group-text secondary-color text-light">
                        <i className="bi bi-person"></i>
                      </span>
                      <input type="email" onChange={(e)=>setEmail(e.target.value)} value={email} className="form-control" placeholder="Email" required />
                    </div>

                    <div className="pt-1 mb-4">
                      <button className="cta" type="submit">Send me reset password instructions</button>
                    </div>

                  </form>
                  <p className="fw-normal text-secondary"><a href="/">Cancel</a></p>     
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword