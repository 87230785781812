import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios'
import { Layout } from '../../components/Layout/Layout'
import TableContainer from '../../components/TableContainer'
import { AuthContext } from '../../context/AuthContext';
import { BookingContext } from '../../context/BookingContext';
import { getCustomersData } from '../../utils/API';
import { getTimetime, handleSort , formattedDate , getrange, removezDate, handleCloseModal } from '../../utils/helpers';
import ShowPatientModal from '../../components/Modals/ShowPatientModal';
import Spinner from '../../components/Layout/Spinner';
import { toast } from 'react-toastify';
import no_data from '../../assets/nodata.svg';
import ReturnPrev from '../../components/ReturnPrev';


const Customers = () => {

  const { REACT_APP_MD_BASEURL } = process.env;
  const { userState } = useContext(AuthContext)
  const { bookingsState } = useContext(BookingContext)
  const navigate = useNavigate()
  const [mdPatients, setMDPatients] = useState([])
  const [patient, setPatient] = useState([])
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false);
  const [showToggle,setShowToggle] = useState(false);
  let sortedCustomers;
  
  const requestHeaders = { 'Authenticate': `Bearer ${localStorage.token}`, 'content-type': 'application/json' }
  const md_id = localStorage.admin_id

  useEffect(() => {
    setLoading(true)
    // api/v2/doctors/doctor/:doctor_id/labresults (upload)
    axios.get(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/patients`, { headers: requestHeaders })
    .then((data)=> {
        setLoading(false)
        // console.log(data?.data?.data?.patients);
        const patientsList = data?.data?.data?.patients
        setMDPatients(patientsList)
    })
    .catch((error)=> { 
        setLoading(false)
        toast.error(error)
    })      
}, []); 
  

  const togglePx=(data)=>{
      // console.log("hello")
      setPatient(data)
      setShowToggle(true)
  }
  
       
  return (
    <Layout>
        <main id="customers-list" className='container-fluid main-parent-container'>
              <div className='d-flex flex-col w-100 px-5 my-2'>  
                <div className='d-flex justify-content-end px-2 w-100'>
                    <ReturnPrev />

                    <button onClick={() => navigate('/md/register')}  className="mx-2 btn lah_gradient text-white border-0 align-self-end fw-bold">
                      <div className='d-flex justify-content-center align-items-center cta-action'>
                        <i className='bx bx-plus-medical'></i>
                          <span className='m-1'>Add Patient</span>
                      </div>
                    </button>
                </div>
              </div>
      
            { loading 
            ?
            <Spinner /> 
            :  
            <>
            { mdPatients.length !== 0
            ? 
            <div id="customers-table" className='card border rounded-3 table-card-container scrollbar-div'>  
              <div className="table-responsive scrollbar-div">
                  <table className="bg-white table table-sm header-fixed">
                      <thead className="sticky-top bg-white">
                          <tr> 
                            {/* <th>Type</th> */}
                            <th>ID</th>
                            <th>Patient Name</th>
                            <th>Patient Age</th>
                            <th>Email</th>
                            <th>Mobile Number</th>
                            <th>Address</th>  
                            <th>Attending Physician</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody> 
                    { handleSort(mdPatients)?.map((px, index) => {

                      const clinics = px?.clinics?.map((clinic)=> clinic.name).join(",")

                      return (
                      
                        <tr key={`md-patients-${px?.id}-${index}`} className="text-center">
                          <td>{px?.id}</td>
                          <td>{px?.last_name || "N/A" }, {px?.first_name || "N/A" }</td>
                          <td>{px?.age || "N/A" }</td>
                          <td>{px?.email || "N/A"}</td>
                          <td>{px?.mobile_number || "N/A" }</td>
                          <td className='text-truncate'>{px?.address || "N/A" }</td>
                          <td>{px?.doctor?.email || "N/A" }</td>
                          <td>
                            <button data-bs-toggle="modal" data-bs-target="#ShowPatientModal" onClick={()=> { togglePx(px) }} type="button" className="btn btn-info text-white border-1 w-75 btn-view">
                              <i className="far fa-eye"></i> <span className='btn-text'>View </span>
                              </button>
                            </td>
                        </tr>
                      );
                  })}
                    </tbody>
                </table>
              </div>
            </div>
            :   
            <div id="main-md-table">
              <div className="std-table-container">
                  <div className='m-auto d-flex flex-column justify-content-center align-items-center'><img src={no_data} className="no-data-img mb-4"/><span>No data available.</span></div>
              </div>
            </div> 
            }
            </>
            }
          </main>
    <ShowPatientModal setUpdate={setUpdate} update={update} patient={patient} showToggle={showToggle} setShowToggle={setShowToggle} /> 
  </Layout>
  )
}

export default Customers