import React, { useContext } from 'react'
import { useNavigate } from 'react-router';
// import { UserContext } from '../../context/AuthContext';
import { AuthContext } from '../../context/AuthContext';
import logo from'../../assets/logo_white.png'

const SidebarClinic = () => {

  let navigate = useNavigate()
  const mystyle={
    background: 'linear-gradient(225deg, #00cac2, #4d70e2)'
  }
  
  return (
    <>
      <div style={mystyle} id="sidebar-wrapper">
        <div className="sidebar-heading border-bottom text-center">
          <img onClick={() => navigate('/md/clinic/home')} src={logo} className="mx-auto brand-logo-white"/>
        </div>

        <div className="list-group list-group-flush my-3">

          <span onClick={()=>{navigate("/md/clinic/home")}} className="list-group-item list-group-item-action  text-white active" role="button">
            <span className="me-2 sidebar-item">🏠</span>Home
          </span>

          <span onClick={()=>{navigate("/md/clinic/bookings")}}  className="list-group-item list-group-item-action  text-white active" role="button">
            <span className="me-2 sidebar-item">🧾</span>Bookings
          </span>

          <span onClick={()=>{navigate("/md/clinic/patients")}}  className="list-group-item list-group-item-action  text-white active" role="button">
            <span className="me-2 sidebar-item">🧑</span>Patients
          </span>

          <span onClick={()=>{navigate("/md/clinic/lab_results")}}  className="list-group-item list-group-item-action  text-white active" role="button">
            <span className="me-2 sidebar-item">🧪</span>Lab Results
          </span>

          <span onClick={()=>{navigate("/md/contact_us")}}  className="d-none list-group-item list-group-item-action  text-white active" role="button">
            <span className="me-2 sidebar-item"> ☎️ </span>Contact Us
          </span>

        </div>
      </div> 
    </>
  )
}

export default SidebarClinic