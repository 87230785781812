import React, {useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../../components/Layout/Layout'
import out_of_pocket from '../../assets/out-of-pocket.png'
import with_insurance from '../../assets/WithInsurance.png'
import ReturnPrev from '../../components/ReturnPrev'
import { AuthContext } from '../../context/AuthContext'
import { toast } from 'react-toastify';
// import ParticlesConfig from '../../utils/ParticlesConfig'


const BookService = () => {
    const navigate = useNavigate()
    const { userState } = useContext(AuthContext)
        
    useEffect(()=>{
        if(userState?.role !== "Doctor"){
          toast.error("Unauthorized")
          return navigate("/")
        }
    },[])

    const handleNavigate = type => {
        type === "bookSelfPay"
        ? navigate('/md/book_out_of_pocket')
        : type === "bookWithInsurance"
        ? navigate('/md/book_with_insurance')
        : type === "register"
        ? navigate('/md/register')
        : navigate('/md/home')
    }
  
    

  return (
    <Layout>
      <main id="book__service"> 
      {/* <div ><ParticlesConfig /></div> */}
        <div className="row bookservice-main-header mt-5 px-5">
          <div className="col text-center">
            <h2 className='text-dark book-service-header'>Book A Service</h2>
            <p className='text-secondary book-service-subheader'><strong>Select Service-Type</strong> - This will determine the payment method of the service.</p>
          </div>
        </div>

        <div className="container-fluid bg-trasparent my-4 p-3"> 
          <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4">
            <div className="col service-1"></div>
            <div className="col service-2"> 
              <div className="card h-100 shadow-sm"> 
                <img src={out_of_pocket} className="card-img-top" alt="book_out_of_pocket"/> 
                
                <div className="card-body"> 
                  <div className="clearfix mb-3"> 
                  
                  </div> 
                    <h5 className="card-title text-center">Patient pays for <br/>the service.</h5>   
                    <div className="text-center my-2"> 
                      <span onClick={ ()=> handleNavigate("bookSelfPay") } className="btn lah_gradient text-white border-0 p-2 mt-2">Order Out-Of-Pocket</span> 
                    </div> 
                  </div> 
              </div> 
            </div> 

            <div className="col service-3"> 
              <div className="card h-100 shadow-sm"> 
                <img src={with_insurance} className="card-img-top" alt="book_with_insurance"/> 
                
                <div className="card-body"> 
                  <div className="clearfix mb-3"> 
                  
                  </div> 
                    <h5 className="card-title text-center">Upload Lab Requisition form for your patient.</h5> 
                    <div className="text-center my-2"> 
                      <span onClick={ ()=> handleNavigate("bookWithInsurance") } className="btn lah_gradient text-white border-0 p-2 mt-2">Upload Lab Order Form</span> 
                    </div> 
                  </div> 
              </div> 
            </div> 
        
            <div className="col service-4"></div>
          </div> 
        </div> 
      </main>
    </Layout>
  
  )
}

export default BookService