/* ------------------- Hooks -------------------- */
import React, { useContext, useState, useRef, useEffect, useCallback } from 'react'
import { useNavigate, Navigate } from 'react-router'
import axios from 'axios'
import { toast } from 'react-toastify';
import admin_logo from '../../assets/logo_md.png'
import { AuthContext } from '../../context/AuthContext';
import ParticlesConfig from '../../utils/ParticlesConfig';
import { useSearchParams } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar';


const ResetPassword = ({ forClinic }) => {
  // const { userDispatch } = React.useContext(AuthContext)
  const navigate = useNavigate()
  // const userEmail = useRef(null);
  // const userPassword = useRef(null);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { REACT_APP_MD_AUTH_BASEURL, REACT_APP_CLINIC_AUTH_BASEURL } = process.env;
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setEmail(searchParams.get('email'))
    setToken(searchParams.get('token'))
  }, [])

  const handleReset = (e) => {
    e.preventDefault()

    const payload = {
      email: email,
      token: token,
      new_password: newPassword,
      // confirm_password: confirmPassword
    }

    const mdData = forClinic ? { clinic: payload } : { doctor: payload }

    setLoading(true)
    if (newPassword === confirmPassword) {
        // toast.success("Password reset successful! Try logging in.")
        // navigate("/")
        axios({
          method: "POST",
          url: `${forClinic ? REACT_APP_CLINIC_AUTH_BASEURL : REACT_APP_MD_AUTH_BASEURL}/reset_password`,
          headers: { 'content-type': 'application/json' },
          data: mdData
        })
          .then((data) => {
            setLoading(false)
            toast.success("Password reset successful! Try logging in.")
            navigate(`/md/${forClinic ? 'clinic/' : ''}login`)
            // console.log("RESET PAYLOAD: ", data)
          })
          .catch((error) => {
            setLoading(false)
            if (error.response) {
              toast.error(error.response.data.error)
            }
          })
    }
    else {
        toast.error("Password confirmation doesn't match the new password.")
    }

  };


  return (
    <>
      <div style={{ position: 'absolute' }}>

        <ParticlesConfig />
      </div>
      <div className="vh-100 bg-cyan">
        <div className="container h-100 py-5">
          <div className="row d-flex justify-content-center mt-5 h-100">
            <div className="col-sm col-md-8 col-lg-6 col-xl-5">
              <div className="card-login border-0 bg-cyan">
                <div className="card-body login p-5 text-center">
                  <div className="d-flex justify-content-center align-items-center py-5">
                    <img src={admin_logo} className="w-75 " />
                  </div>
                    <h2 className='text-secondary font-lato'>Reset { forClinic ? "Clinic's" : "your" } password</h2>
                  <form onSubmit={handleReset} className="p-2 mt-3 text-center">
                    <h6 className="login-heading mb-4 fw-normal text-secondary text-greeting">Enter desired new password and confirm.</h6>

                <div className="input-group mb-3">
                  <span className="input-group-text secondary-color text-light">
                    <i className="bi bi-person"></i>
                  </span>
                  <input type="password" onChange={(e)=>setNewPassword(e.target.value)} value={newPassword} className="form-control" placeholder="New Password" required />
                </div>
                <PasswordStrengthBar password={newPassword} scoreWords={['Very Weak', 'Weak', 'Okay', 'Good', 'Strong']} shortScoreWord={'Too Short'}/>
                <br />

                <div className="input-group mb-3">
                  <span className="input-group-text secondary-color text-light">
                    <i className="bi bi-person"></i>
                  </span>
                  <input type="password" onChange={(e)=>setConfirmPassword(e.target.value)} value={confirmPassword} className="form-control" placeholder="Confirm New Password" required />
                </div>


                <div className="pt-1 mb-4">
                  <button className="cta" type="submit">Reset Password</button>
                </div>

                  </form>
                  <p className="fw-normal text-secondary"><a href="/">Cancel</a></p>     
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword