import React, { useState, useRef, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Layout } from '../../components/Layout/Layout';
import { createdatetoISO, getNewTime, getCurrentTime, disablePastDate, getFormattedPrice, handleCloseModal} from '../../utils/helpers';
import { getCustomersData, getTestsData, getPackagesData, getMedtechsData } from '../../utils/API';
import Select from 'react-select'
import MainPage from './AddToCart/MainPage';
import TestsPage from './AddToCart/TestsPage';
import Basket from './AddToCart/Basket';
import MapContainer from '../../components/Map/GoogleMaps';
import { toast } from 'react-toastify';
import { getRandomData } from '../../utils/helpers';


import PatientInfo from "./SelfPay/PatientInfo"
import GetLocation from './SelfPay/GetLocation'
import Scheduling from "./SelfPay/Scheduling";
import SelectProduct from "./SelfPay/SelectProduct";
import Additional from './SelfPay/Additional'
import Confirmation from "./SelfPay/Confirmation";
import Success from "./SelfPay/Success";
import SelfPayProgressBar from '../../components/ProgressBar/ SelfPayProgressBar';


function BookingMulti() {

  const { REACT_APP_MD_BASEURL, REACT_APP_MD_MAINUS_BASEURL } = process.env;
  /*** state for form data ***/
  const [formData, setFormData] = useState({
    customer_id:null,
    medtech_id:null,
    email:"",
    first_name:"",
    last_name:"",
    mobile_number:null,
    location:null,
    latitude:null,
    longitude:null,
    date:null,
    time_start:null,
    time_end:null,
    // tag_list: null,
    covid_tag: null,
    service_area:null,
    status: "Pending",
    remarks: "",
    admin_remarks: "",
    medical_condition: "",
    routing_status: "Pending",
    cart:null
  })

  const [step, setstep] = useState(1);
  const bookingStatusOptions =  [
    { value:"Confirmed", label:"Booking Confirmed"}, 
    { value:"Pending",  label:"Payment Pending"},
    { value:"Cancelled", label:"Booking Cancelled"}, 
    { value:"En Route",  label:"Phlebotomist En Route"},
    { value:"Arrived", label:"Phlebotomist Arrived"},
    { value:"Started", label:"Appointment Started"}, 
    { value:"Done",  label:"Appointment Completed"},
    { value:"Specimen Turned over", label: "Specimen Delivered"},
    { value:"Results received", label: "Full Results Received"},
    { value:"Partial results received", label:"Partial Results Received"}
    
  ]
  const covidTagOptions = [
    {value:"No Known Exposure", label: "No Known Exposure"},
    {value:"Confirmed Negative", label: "Confirmed Negative"},
    {value:"Exposed but asymptomatic", label: "Exposed but asymptomatic"},
    {value:"Experiencing symptoms but not tested for COVID-19 yet",label: "Experiencing symptoms but not tested for COVID-19 yet"},
    {value:"Tested for COVID-19 but results are inconclusive",label: "Tested for COVID-19 but results are inconclusive"},
    {value:"Confirmed Positive",label: "Confirmed Positive"}
  ]
  const tagListOptions = [
    {value: "tag1", label: "tag1"},
    {value: "tag2", label: "tag2"},
    {value: "tag3", label: "tag3"},
  ]

  const [cartItems, setCartItems] = useState([]);
  const [packages, setPackages] = useState([]);
  const [tests, setTests] = useState([]);
  const [showPackages, setShowPackages] = useState(true);
  const [count, setCount] = useState(0);
  const [totalQuantity,setTotalQuantity]=useState(0);
  const [totalPrice,setTotalPrice] = useState(0);
  const [optionPackages, setOptionPackages] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("Pending");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [update, setUpdate] = useState(false)

  const [emailSelected, setEmailSelected] = useState({
    email: '',
    id: null,
    first_name: '',
    last_name: '',
    mobile_number:'',
    email:''
  });
  const [selectOptions, setSelectOptions] = useState([])
  const [medtechs, setMedtechs] = useState([])
  const [currentPosition, setCurrentPosition] = useState({
    address:"",
    lat:0,
    lng:0
  });
  const schedule = [
    {slot:10, available:'', label: "-- Select Schedule -- "},
    {slot:0, available:'', label: "05:00am - 06:00am ", startTime:'05:00', endTime:'06:00'},
    {slot:1, available:'', label: "06:00am - 07:00am ", startTime:'06:00', endTime:'07:00'},
    {slot:2, available:'', label: "07:00am - 08:00am ", startTime:'07:00', endTime:'08:00'},
    {slot:3, available:'', label: "08:00am - 09:00am ", startTime:'08:00', endTime:'09:00'},
    {slot:4, available:'', label: "09:00am - 10:00am ", startTime:'09:00', endTime:'10:00'},
    {slot:5, available:'', label: "10:00am - 11:00am ", startTime:'10:00', endTime:'11:00'},
    {slot:6, available:'', label: "11:00am - 12:00nn ", startTime:'11:00', endTime:'12:00'},
    {slot:7, available:'', label: "12:00pm - 01:00pm ", startTime:'12:00', endTime:'13:00'},
    {slot:8, available:'', label: "01:00pm - 02:00pm ", startTime:'13:00', endTime:'14:00'},
    {slot:9, available:'', label: "02:00pm - 03:00pm ", startTime:'14:00', endTime:'15:00'}
  ]
  let day;
  let pref;

  const [preftime, setPreftime] = useState()
  const [availmedtech,setAvailmedtech] = useState([])
  const current = new Date();
  const curentdate = `${current.getFullYear()}-0${current.getMonth()+1}-0${current.getDate()}`
  const [medtechSelected, setMedtechSelected] = useState()
  const [paymentData, setPaymentData] = useState({
    customer_id: null,
    booking_id: null,
    method: "Stripe",
    amount: 0,
    status: "Unpaid"
  })
  /*** request headers ***/
  const requestHeaders = {Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json"}
  const md_id = localStorage.admin_id

  const [page, setPage] = useState("");

  const validateFields =()=>{
      if(formData?.customer_id && formData?.location && formData?.cart) {
          return true
      } else {
        return false
      }
  }
  // convert back to Test#ID:QTY payload format
  const formatcart=(c)=> {
    let list1={}
    let list2={}
    
    if (c){
        c.forEach(function (item) {
          if(item.type){
            let a = item?.type+'#'+item?.id
            var b = item?.qty;
            list1={[a]:b}
    
            list2 = {
                ...list2,
                ...list1
            }
          }else{
            let a = "Package#"+item?.id
            var b = item?.qty
            list1={[a]:b}
    
            list2 = {
                ...list2,
                ...list1
            }
    
          }
        })
        // setCart({cart: list2})
    }
    
    return list2
  }
  //  Booking POST request
  const handleAddBooking = async (e) =>{
    e.preventDefault()

    // setFormData({cart: cartItems})

  // setLoading(true)
  // if(validateFields() === true){

    const bookingData = {
      booking: {
        customer_id: emailSelected?.id,
        medtech_id: formData?.medtech_id,
        status: formData?.status,
        location: currentPosition ? currentPosition?.address : null,
        remarks: formData?.remarks,
        date: formData?.date ? formData?.date : null,
        time_start: formData?.time_start ? createdatetoISO(formData?.time_start) : null,   
        time_end: formData?.time_end ? createdatetoISO(formData?.time_end): null,
        longitude:currentPosition?.lng,
        latitude:currentPosition?.lat,  
        covid_tag: formData?.covid_tag,
        admin_remarks: formData?.admin_remarks,
        medical_condition:formData?.medical_condition,
        cart: cartItems ? formatcart(cartItems) : null,
        routing_status: formData?.routing_status
        // unit: null,
        // tag_list: formData?.tag_list
        // medtech: medtechSelected
      }
    }
    // console.log("BOOKING DATA: ", bookingData)
    
    //check duplicate bookings from website and upload
    //  const duplicatebooking = bookings?.find((x) => (
    //   x.status != 'Cancelled' &&
    //   x.medtech_id === bookingData?.booking?.medtech_id && 
    //   x.date === bookingData?.booking?.date &&
    //   (getNewTime(x.time_start) === getNewTime(bookingData?.booking?.time_start) || getNewTime(x.start_time) === getNewTime(bookingData?.booking?.time_start) )
    // ))


    // if(!duplicatebooking){
      axios({
        method: "POST",
        url: `${REACT_APP_MD_BASEURL}/doctor/${md_id}/bookings`,
        data: bookingData,
        // data: formData,
        headers: requestHeaders,
      })
        .then((data) => {
          setLoading(false)
          // console.log(data)
          if (data.status === 200) {
            let newBooking = data?.data?.data?.booking
            // console.log(newBooking.id)
            postPayment(newBooking.customer_id,newBooking.id, newBooking)
            nextStep()
          }
        })
        .catch(function (error) {
          
            toast.error(error)
          
        })
        // .catch(function (error) {
        //   if (error.response) {
        //     toast.error(error.response.data.message[0])
        //   }
        // })

      // console.log(formData)
  // } else{
  //   toast.error("Please check all fields.")  
  // }
      
  }
  const gettotalprice =(cartItems)=>{
    const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);
    // const taxPrice = itemsPrice * 0.14;
    // const shippingPrice = itemsPrice > 2000 ? 0 : 20;
    const discountFee = itemsPrice * 0
    const serviceFee = itemsPrice < 99 ? 59 : 0
    // const serviceFee = 500
    // const totalPrice = itemsPrice + taxPrice + shippingPrice;
    const totalPrice = (itemsPrice + serviceFee)-discountFee;
    return totalPrice
  }

  // fetch customers, medtechs, packages and tests from database
  useEffect(()=>{  
    setLoading(true)
    async function fetchData(){
      const testsData = await getTestsData()
      const packagesData = await getPackagesData()
      const medtechsData = await getMedtechsData()
      const customersData = await getCustomersData()

      setMedtechs(medtechsData)

      if(testsData || packagesData || customersData) {
        setLoading(false)
        setTests(testsData)
        setPackages(packagesData)
        setCustomers(customersData)
        const options = customersData?.map(d => ({
          "value" : d.id,
          "label" : d.email,
          "first_name": d.first_name,
          "last_name": d.last_name,
          "mobile_number": d.mobile_number,
          "email": d.email
        }))
        setSelectOptions(options)
      } 
      
      }
      fetchData()
      getavailmedtech()
  },[])

  // check medtech availability
  const getavailmedtech =()=>{
    setAvailmedtech()
    let av=[]
    if(day || pref){
      medtechs.map((x)=>{
        
        if(x?.availability_slot){
          let avail = x?.availability_slot
        // console.log(avail)

          Object.entries(avail).map((datekey)=>{
            // console.log(datekey)
            let d = datekey[0]
            let s = datekey[1]
              if(d==day){
                // console.log(x)
                av.push(x)
                
              }
          })
        }
      })
    }


    // console.log(av[0])


      setAvailmedtech(av[0])
      setFormData(prevState => ({
        ...prevState,
        medtech_id: availmedtech?.id}))
  }

  
  // // Setter for selected customer on email search
  const selectCustomer =(e)=>{
    // console.log(e)
    setEmailSelected({id:e.value,email:e.label,first_name:e.first_name,last_name:e.last_name, mobile_number: e.mobile_number, email: e.email})
    // setFormData({customer_id:e.value,email:e.label,first_name:e.first_name,last_name:e.last_name , mobile_number: e.mobile_number, email: e.email})
    // console.log(emailSelected)
  }

  const getLocation = coords => {
    // console.log(coords);
    setCurrentPosition(coords);
  };

  const style = {
    control: (base) => ({
      ...base,
      borderRadius: "15px"
    })
  }

  // Add to cart functionality
  const onAdd = (product) => {
  // console.log("Added product: ", product)
    setTotalQuantity(totalQuantity + 1)
    const exist = cartItems.find((x) => x.name === product.name);
    if(exist) {
      setCartItems(
        cartItems?.map((x) =>
          x.name === product.name ? { ...exist, qty: exist.qty + 1 } : x
        )
      )
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }]);
    }
  // console.log(cartItems)
    return cartItems
  }

  // useEffect(() => {
  //   console.log("CART: ", cartItems)
  // }, [cartItems])

  // Remove to cart functionality
  const onRemove = (product) => {
    if(totalQuantity !== 0) {
      setTotalQuantity(totalQuantity - 1)
    }
    const exist = cartItems.find((x) => x.name === product.name)
    if(exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.name !== product.name));
    } else {
      setCartItems(
        cartItems?.map((x) =>
          x.name === product.name ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
    }

    return cartItems
  }

  //set time to payload and check available medtech
  const makehour = (start) => {
    day = formData?.date
    pref = schedule?.find(obj => {
    return obj.startTime == start
  })

  setPreftime(pref)
    // setFormData({time_start: pref.startTime, time_end: pref.endTime})
    // setFormData({time_end: pref.endTime})
    getavailmedtech()
    
    return pref
  }



  //set date to payload and check available medtech
  const getDate = (datet) => {
    day = datet
    pref = preftime
    // console.log(day)
    // setFormData({date:day})
    getavailmedtech()

    return datet
  }

  const postPayment =(customer_id, booking_id, newBooking)=> { 

  const paymentData = {
    payment: {
      customer_id: customer_id,
      booking_id: booking_id,
      method: "Stripe", 
      amount: gettotalprice(cartItems),
      // amount: 0,
      status: "Unpaid"
    }
  }

  // console.log(paymentData?.payment)

  axios({
      method: "POST",
      url: `${ REACT_APP_MD_MAINUS_BASEURL }/payments`,
      data: paymentData,
      headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },  
  })
  .then((data) => {
    if(data.status === 200) {
      toast.success("Successfully created booking!")
      setUpdate(!update)
    }
  })
  .catch(error => toast.error(error));
  }

  const medtechdetails =()=> {
    const medtech = medtechs?.find(obj => { //or getAvailMedtechs?
      if(obj.name === "admin_medtech"){
        return obj
      }
    })

    // const medtechs = getRandomData(medtechs)
    // const medtech = medtechs.map(obj =>  { return obj})
    setMedtechSelected(medtech)
    // console.log(medtech)
    return medtech
  }
  // console.log(medtechSelected)

  // function for going to next step by increasing step state by 1
  const nextStep = (page) => {
    setstep(step + 1);
    setPage(page);  
  };
 

  // function for going to previous step by decreasing step state by 1
  const prevStep = (page) => {
    setstep(step - 1);
    setPage(page);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = input => e => {
    // input value from the form
    const {value } = e.target;

    //update data state taking previous state and then adding new value to create new object
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }

  // const setDefaultDate =()=> {
  //   const today = new Date()
  //   // to return the date number(1-31) for the specified date
  //   console.log("today => ",today)
  //   let tomorrow =  new Date()
  //   tomorrow.setDate(today.getDate() + 1)
  //   //returns the tomorrow date
  //   console.log("tomorrow => ",tomorrow)
  // }

  // setDefaultDate()

  // const date = new Date();

  // date.setDate(date.getDate() + 1);


  // console.log(date);

  
  // function padTo2Digits(num) {
  //   return num.toString().padStart(2, '0');
  // }

  // function formatDate(date) {
  //   return [
  //     date.getFullYear(),
  //     padTo2Digits(date.getMonth() + 1),
  //     padTo2Digits(date.getDate()),
  //   ].join('-');
  // }

    
  // console.log(formatDate(date));
 

  // show different form in each step
  switch (step) {
    case 1:
      return (
        <Layout>
          <SelfPayProgressBar page={"pageone"} />  
          <div className="container-fluid w-75">
            <div className="row">
              <div className="col">
                <PatientInfo 
                  nextStep={nextStep} 
                  handleFormData={handleInputData} 
                  values={formData} 
                  selectOptions={selectOptions} 
                  selectCustomer={selectCustomer} 
                  setFormData={setFormData}
                  style={style} 
                  emailSelected={emailSelected} 
                  setEmailSelected={setEmailSelected}
                  getLocation={getLocation} 
                  currentPosition={currentPosition} 
                  setCurrentPosition={setCurrentPosition} 
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </Layout>
      );
      break;
    case 2:
      return (
        <Layout>
          <SelfPayProgressBar page={"pagetwo"} />  
          <div className="container-fluid w-50">
            <div className="row">
              <div className="col">
                <GetLocation 
                  nextStep={nextStep} 
                  prevStep={prevStep}
                  setFormData={setFormData}
                  handleFormData={handleInputData} 
                  values={formData} 
                  getLocation={getLocation} 
                  currentPosition={currentPosition} 
                  setCurrentPosition={setCurrentPosition} 
                />
              </div>
            </div>
          </div>
        </Layout>
      );
      break;
    case 3:
      return (
        <Layout>
          <SelfPayProgressBar page={"pagethree"} />  
          <div className="container-fluid w-75">
            <div className="row">
              <div className="col">
                <Scheduling 
                  nextStep={nextStep} 
                  setFormData={setFormData}
                  prevStep={prevStep} 
                  handleFormData={handleInputData} 
                  values={formData} 
                  getDate={getDate} 
                  makehour={makehour} 
                  disablePastDate={disablePastDate} 
                  schedule={schedule} 
                  getCurrentTime={getCurrentTime} 
                  curentdate={curentdate} 
                  medtechSelected={medtechSelected} 
                  medtechdetails={medtechdetails} 
                  availmedtech={availmedtech} 
                  medtechs={medtechs}
                />
              </div>
            </div>
          </div>
        </Layout>
      );
      break;
    case 4:
      return (
        <Layout>
          <SelfPayProgressBar page={"pagefour"} />  
          <div className="container-fluid w-75">
            <div className="row">
              <div className="col">
                <SelectProduct 
                  nextStep={nextStep} 
                  prevStep={prevStep} 
                  setFormData={setFormData}
                  handleFormData={handleInputData} 
                  values={formData} 
                  setShowPackages={setShowPackages} 
                  totalQuantity={totalQuantity} 
                  setTotalQuantity={setTotalQuantity} 
                  setSearchTerm={setSearchTerm} 
                  showPackages={showPackages} 
                  packages={packages} 
                  onAdd={onAdd} 
                  searchTerm={searchTerm} 
                  tests={tests} 
                  cartItems={cartItems} 
                  onRemove={onRemove}
                  // generateArray={generateArray}
                  formatcart={formatcart}
                  />
              </div>
            </div>
          </div>
        </Layout>
      );
      break;
    case 5:
      return (
        <Layout>
          <SelfPayProgressBar page={"pagefive"} />  
          <div className="container-fluid w-75">
            <div className="row">
              <div className="col">
                <Additional 
                  nextStep={nextStep} 
                  prevStep={prevStep} 
                  setFormData={setFormData}
                  handleFormData={handleInputData} 
                  values={formData} 
                  covidTagOptions={covidTagOptions}
                  bookingStatusOptions={bookingStatusOptions}
                  />
              </div>
            </div>
          </div>
        </Layout>
      );
      break;
    case 6:
      return (
        <Layout>
          <SelfPayProgressBar page={"pagesix"} />  
          <div className="container-fluid w-75">
            <div className="row">
              <div className="col">
                <Confirmation 
                  nextStep={nextStep} 
                  prevStep={prevStep} 
                  handleFormData={handleInputData} 
                  setFormData={setFormData}
                  values={formData}  
                  tests={tests} 
                  packages={packages}
                  handleAddBooking={handleAddBooking}
                  medtechdetails={medtechdetails}
                  medtechSelected={medtechSelected}
                  formatcart={formatcart}
                />
                </div>
            </div>
          </div>
        </Layout>
      );
      break;
    case 7:
      return (
        <Layout>
          <SelfPayProgressBar page={"pageseven"} />  
          <div className="container-fluid w-75">
            <div className="row">
              <div className="col">
                <Success values={formData} />
                </div>
            </div>
          </div>
        </Layout>
      );
    default:
      return (
        <div>
            <Layout></Layout>
        </div>
      );
      break;
  }

  
}



export default BookingMulti