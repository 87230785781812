import React, { useContext } from 'react'
import { useNavigate } from 'react-router';
import { AuthContext } from '../../context/AuthContext';
import logo from'../../assets/logo_white.png'
import Footer from './Footer';

const Sidebar = () => {

  let navigate = useNavigate()
  const mystyle={
    background: 'linear-gradient(225deg, #00cac2, #4d70e2)'
  }
  const { userState, userDispatch } = React.useContext(AuthContext) 
  
  return (
    <>
      <div style={mystyle} id="sidebar-wrapper">
        <div>
          <div className="sidebar-heading border-bottom text-center">
            <img onClick={() => navigate('/md/home')} src={logo} className="mx-auto brand-logo-white"/>
          </div>

          <div className="list-group list-group-flush my-3 me-4">

            <span onClick={()=>{navigate("/md/home")}} className="list-group-item list-group-item-action  text-white active" role="button">
              <span className="me-2 sidebar-item">🏠</span>Home
            </span>

            <span onClick={()=>{navigate("/md/bookings")}}  className="list-group-item list-group-item-action  text-white active" role="button">
              <span className="me-2 sidebar-item">🧾</span>Bookings
            </span>

            <span onClick={()=>{navigate("/md/customers")}}  className="list-group-item list-group-item-action  text-white active" role="button">
              <span className="me-2 sidebar-item">🧑</span>Patients
            </span>

            <span onClick={()=>{navigate("/md/lab_results")}}  className="list-group-item list-group-item-action  text-white active" role="button">
              <span className="me-2 sidebar-item">🧪</span>Lab Results
            </span>

            <span onClick={()=>{navigate("/md/packages")}}  className="list-group-item list-group-item-action  text-white active" role="button">
              <span className="me-2 sidebar-item">🎁</span>Packages
            </span>

            <span onClick={()=>{ navigate('/md/book_out_of_pocket')}}  className="list-group-item list-group-item-action text-white pe-1 active" role="button">
              <span className="me-2 sidebar-item">📝</span>Order Out-Of-Pocket
            </span>

            <span onClick={()=>{navigate('/md/book_with_insurance')}}  className="list-group-item list-group-item-action  text-white pe-1 active" role="button">
              <span className="me-2 sidebar-item">📝</span>Upload Lab Order Form
            </span>

            <span onClick={()=>{navigate("/md/contact_us")}}  className="d-none list-group-item list-group-item-action  text-white active" role="button">
              <span className="me-2 sidebar-item"> ☎️ </span>Contact Us
            </span>
          </div>
        </div>
        <Footer />
      </div> 
    </>
  )
}

export default Sidebar