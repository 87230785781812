import React, { useState, useEffect, useContext, useReducer } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios'
import { Layout } from '../../components/Layout/Layout';
import TableContainer from '../../components/TableContainer';
import { AuthContext } from '../../context/AuthContext';
import { BookingContext } from '../../context/BookingContext';
import { getBookingsData, getTestsData, getPackagesData, getMedtechsData } from '../../utils/API';
import { getTimetime, handleSort , formattedDate , getrange, removezDate, handleCloseModal } from '../../utils/helpers';
import ShowMainBookingModal from '../../components/Modals/ShowMainBookingModal';
import ShowUploadBookingModal from '../../components/Modals/ShowUploadBookingModal';
import Spinner from '../../components/Layout/Spinner';
import { toast } from 'react-toastify';
import no_data from '../../assets/nodata.svg';
import ReturnPrev from '../../components/ReturnPrev';
import ClinicFilter from '../../components/Filters/ClinicFilter';
import Select from 'react-select'

 

    const Booking = () => {
        const { REACT_APP_MD_BASEURL } = process.env;
        const { userState } = useContext(AuthContext)
        const { bookingsState, bookingsDispatch } = useContext(BookingContext)
        const navigate = useNavigate()
        const [mdBookings, setMDBookings] = useState([])
        const [booking, setBooking] = useState(null)
        const [tests, setTests] = useState([])
        const [packages, setPackages] = useState([])
        const [loading, setLoading] = useState(false)
        const [update, setUpdate] = useState(false);
        const [showToggle,setShowToggle] = useState(false);
        const requestHeaders = { 'Authenticate': `Bearer ${localStorage.token}`, 'content-type': 'application/json' }
        const md_id = localStorage.admin_id
        const [totalQuantity,setTotalQuantity]=useState(0)
        const [cartItems, setCartItems] = useState([]);
        const [searchTerm, setSearchTerm] = useState("")
        const [clinicSelected, setClinicSelected]= useState()
        const [clinicOptions, setClinicOptions] = useState([
            { value: '1', label: 'Test Clinic1'},
            { value: '2', label: 'Test Clinic2' },
            { value: '3', label: 'Test Clinic3' }
        ])

        useEffect(()=>{
            if(userState?.role !== "Doctor"){
              toast.error("Unauthorized")
              return navigate("/")
            }
        },[])
        
         // reload data when updater is changed from Add/Edit Components
         useEffect(()=>{ 
            // console.log("update: fetching bookings...")
            getMDBookings();
            // fetchData();
        },[update])

        // useEffect(() => {
        //     console.log("Bookings: ", mdBookings)
        // }, [mdBookings]);

         
        const getMDBookings =()=>{
            setLoading(true)
            axios.get(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/bookings`, { headers: requestHeaders })
            .then((data)=> {
                setLoading(false)
                // console.log(data?.data?.data);
                // const all_bookings = data?.data?.data?.bookings
                // const website_bookings = all_bookings.website_bookings;
                // const upload_bookings = all_bookings.upload_bookings;
                // setMDBookings([...website_bookings, ...upload_bookings]);
                bookingsDispatch({ type: 'GET_LIST', payload: data })
            })
            .catch((error)=> { 
                setLoading(false)
                toast.error(error)
            })    
        }  
       
    
        // GET tests, packages 
         async function fetchData(){
            if (userState?.user) { 
                const packagesData = await getPackagesData()
                const testsData = await getTestsData()
                // const clinicsData = await getClinicsData()
                // const options = clinicsData?.map(clinic => ({
                //     "value" : clinic.id,
                //     "label" : clinic.email
                // }))
                setLoading(false)
                // setClinicOptions(options)
                setPackages(packagesData)
                setTests(testsData)
            }
        }

        // load data initially
        useEffect(()=>{ 
            setLoading(true)
            getMDBookings();
            fetchData();
        },[])

      
        const getusercart=(d)=> {
            let carttype,testid,testqty,packageid,packageqty
            let totqty=0
            // setCartItems([])
            if(d){
                Object.keys(d).forEach(t => {
                    carttype = t?.slice(0,4)
        
                    if (carttype === 'Test'){
                    testid = Math.floor(t?.slice(5))
                    testqty = d[t]
        
                    totqty = totqty + testqty
                    // console.log(totqty)
        
                    const gettest = tests.find((x) => x.id === testid)
                    
                    cartItems.push({ ...gettest, qty: testqty })
        
        
                    }else{
                        packageqty = d[t]
                        totqty = totqty + packageqty
                        packageid = Math.floor(t?.slice(8))
                        const getpackage = packages?.find((x) => x?.id === packageid)
                        cartItems.push({ ...getpackage, qty: packageqty })
                        // const getpackage = packages?.find((x) => x?.name === t)      
                        // cartItems.push({ ...getpackage, qty: packageqty })
                    }
                }) 
            } 
            setTotalQuantity(totqty)
            return cartItems,totalQuantity
        }

        // reload data when updater is changed from Add/Edit Components
        useEffect(()=>{ 
            // console.log("update: fetching bookings...")
            fetchData();
        },[update])

        const bookingToggle = (data) => {
            setBooking(data)
            setShowToggle(true)
            bookingStep(data?.status)
        }

        const generateArray =(obj)=> {
            let usertests,testid,carttype,testqty,packageid,packageqty
            let arr=[]
            if (obj) {
                // console.log(obj)
                Object.keys(obj).forEach(t => {
                    carttype = t?.slice(0,4)
                    // let count =+1
                    
                    if (carttype === 'Test'){
                        testid = Math.floor(t?.slice(5))
                        testqty = obj[t]
                        const gettest = tests?.find((x) => x?.id === testid)
                        usertests = `${gettest?.localization?.find(x => x.locale === "en").name}[${testqty}]`
                        arr.push(usertests)
                    }else{
                        
                        packageqty = obj[t]
                        // const getpackage = packages?.find((x) => x?.name === t)
                        packageid = Math.floor(t?.slice(8))
                        // console.log("packageid: ", packageid)
                        // if (isNaN(packageid)) {
                        //     console.log("PACKAGE ID NaN: ", t)
                        // } else {
                        //     console.log("PACKAGE: ", t)
                            const getpackage = packages?.find((x) => x?.id === packageid)
                            if (getpackage !== undefined) {
                                usertests = getpackage?.localization == undefined ? `${getpackage.name}[${packageqty}]` : `${getpackage?.localization?.find(x => x.locale === "en").name}[${packageqty}]`
                                arr.push(usertests)
                            }
                        // }
                        // const getpackage = packages?.find((x) => x?.id === packageid)
                        // usertests = `${getpackage?.name}[${packageqty}]`
                        // arr.push(usertests)
                    }
                })
            }
            return (
                arr.join(", ")
            )
        }

        const customStyles = {
            control: base => ({
                ...base,
                minHeight: 30
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 4
            }),
            clearIndicator: base => ({
                ...base,
                padding: 4
            }),
            multiValue: base => ({
                ...base
            }),
            valueContainer: base => ({
                ...base,
                padding: '0px 5px'
            }),
            input: base => ({
                ...base,
                margin: 0,
                padding: 0
            })
        };


        const labelArray = ['Payment Pending', 'Booking Confirmed', 'Phlebotomist En Route','Phlebotomist Arrived', 'Appointment Started', 'Appointment Completed', 'Specimen Delivered', 'Results Received']
        
        const [currentStep, updateCurrentStep] = useState(0)

        function updateStep(step) {
          updateCurrentStep(step);
        }

        function bookingStep(status) {

            switch (status) {
                case 'Pending':
                    updateCurrentStep(1);
                    break;
    
                case 'Confirmed':
                    updateCurrentStep(2);
                    break;
    
                case 'En Route':
                    updateCurrentStep(3);
                    break;
                    
                case 'Arrived':
                    updateCurrentStep(4);
                    break;           
    
                case 'Started':
                    updateCurrentStep(5);
                    break;   
                    
                case 'Done':
                    updateCurrentStep(6);
                    break;       
    
                case 'Specimen Turned over':
                    updateCurrentStep(7);
                    break;   
                    
                case 'Partial results received':
                    updateCurrentStep(8);
                    break;   
                    
                case 'Results received':
                    updateCurrentStep(8);
                    break;               
                
                default:
                    updateCurrentStep(0);
                    break;
            }
    
        }
    
   

    return (
        <Layout>
            <main className='container-fluid main-parent-container'>
                <div className='d-flex justify-content-end w-100 px-5 my-2'>  
                    <ReturnPrev />
                </div>
           
                <div className="d-none select-group row d-flex justify-content-center align-items-center mb-2">
                    <div className="col-sm-6 p-3">
                        <label className='px-2 filter-title'>Filter bookings by clinic</label>
                        <div className="form-group">
                            <Select 
                                options={clinicOptions} 
                                onChange={ (e)=>setSearchTerm(e) } 
                                noOptionsMessage={ () => loading ? 
                                    <>
                                        <span>Loading clinics...</span>
                                    </>
                                    : <span>"No Clinics Found"</span> 
                                    }
                                loading={loading}
                                styles={customStyles}
                            />
                        </div> 
                    </div> 
                </div>

                  
                { loading 
                ?
                <Spinner /> 
                : 
                <>
                {/* { mdBookings?.length !== 0 */}
                { bookingsState.bookings?.length !== 0
                    ? 
                    <div id="bookings-table" className='card border rounded-3 table-card-container scrollbar-div'>  
                        <div className="table-responsive scrollbar-div">
                            <table className="bg-white table table-sm header-fixed">
                                <thead className="sticky-top bg-white" >
                                    <tr className='text-center'> 
                                        <th>Booking Type</th>
                                        <th>Booking Id</th>
                                        <th>Patient Name</th>
                                        <th>Appointment Date</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Booking Status</th>
                                        <th>Action</th>
                                    </tr>

                                </thead>
                                <tbody>    
                                    { handleSort(bookingsState.bookings)?.map((booking, index)=> {
        
                                        return (
                                            <tr key={`md-booking-${booking.code}-${index}`} className="text-center">
                                                { (booking?.type == "md-website" || booking?.type == "website") ? <td>Out-of-Pocket</td> :  <td>With Requisition Form</td> }
                                                <td>{ booking?.code}</td>
                                                { booking?.patient_last_name ? <td>{ `${booking?.patient_last_name}, ${booking?.patient_first_name}`}</td> : <td>N/A</td>}
                                                { booking?.date ? <td>{ booking?.date }</td> : <td>N/A</td>}
                                                { (booking?.type == "md-website" || booking?.type == "website") ? <td> { getTimetime(booking?.time_start) || "N/A" } </td> : (booking?.type == "md-upload" || booking?.type == "upload") ? <td> { getTimetime(booking?.start_time) || "N/A"} </td> : <td></td>}
                                                { (booking?.type == "md-website" || booking?.type == "website") ? <td> { getTimetime(booking?.time_end) || "N/A" } </td> : (booking?.type == "md-upload" || booking?.type == "upload") ? <td> { getTimetime(booking?.end_time) || "N/A"} </td> : <td></td>}
                                                {   
                                                <td>
                                                    <span className = {
                                                        booking.status === "Pending" 
                                                        ? "badge bg-pending w-75" 
                                                        : booking.status === "Confirmed" 
                                                        ? "badge bg-confirmed w-75" 
                                                        :  booking.status === "Cancelled" 
                                                        ? "badge bg-cancelled w-75" 
                                                        : booking?.status == "En Route" 
                                                        ? "badge bg-enroute w-75" 
                                                        : booking?.status == "Arrived" 
                                                        ? "badge bg-arrived w-75"
                                                        : booking?.status == "Started" 
                                                        ? "badge bg-started w-75"
                                                        : booking?.status == "Done" 
                                                        ? "badge bg-done w-75"
                                                        : booking?.status == "Specimen Turned over" 
                                                        ? "badge bg-specimen-to w-75"
                                                        : booking?.status == "Partial results received" 
                                                        ? "badge bg-partial-res w-75"
                                                        : booking?.status == "Results received" 
                                                        ? "badge bg-full-res w-75"
                                                        : "badge bg-secondary w-75"
                                                    }>
                                                    {
                                                        booking?.status == "Pending" 
                                                        ? <>Payment Pending</>
                                                        :booking?.status == "Confirmed"
                                                        ? <>Booking Confirmed</>
                                                        : booking?.status == "Cancelled"
                                                        ? <>Booking Cancelled</>
                                                        : booking?.status == "En Route"
                                                        ? <>Phlebotomist En Route</>
                                                        : booking?.status == "Arrived"
                                                        ? <>Phlebotomist Arrived</>
                                                        : booking?.status == "Started"
                                                        ? <>Appointment Started</>
                                                        : booking?.status == "Done"
                                                        ? <>Appointment Completed</>
                                                        : booking?.status == "Specimen Turned over"
                                                        ? <>Specimen Delivered</>
                                                        : booking?.status == "Partial results received"
                                                        ? <>Partial Results Received</>
                                                        : booking?.status == "Results received"
                                                        ? <>Full Results Received</>
                                                        :<></>
                                                    }
                                                    </span>
                                                </td>

                                                }
                                                { (booking?.type == "md-website" || booking?.type == "website")? 
                                                    <td>
                                                        <button className="btn btn-info text-white border-1 w-100 btn-view" type="button"  data-bs-toggle="modal" data-bs-target="#showMainBookingModal" onClick={()=> {bookingToggle(booking)}}>
                                                            <i className="far fa-eye"></i> <span className='btn-text'>View </span>
                                                        </button>
                                                    </td>
                                                    
                                                :   
                                                    <td>
                                                        <button className="btn btn-info text-white border-1 w-100 btn-view" type="button"  data-bs-toggle="modal" data-bs-target="#showUploadBookingModal" onClick={()=> {bookingToggle(booking)}}>
                                                            <i className="far fa-eye"></i> <span className='btn-text'>View </span>
                                                        </button>
                                                    </td>
                                                }
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :   
                    <div id="main-md-table">
                        <div className="std-table-container">
                            <div className='m-auto d-flex flex-column justify-content-center align-items-center'><img src={no_data} className="no-data-img mb-4"/><span>No data available.</span></div>
                        </div>
                    </div> 
                }
                </>
                }
            

            <ShowMainBookingModal setUpdate={setUpdate} update={update} setCartItems={setCartItems} totalQuantity={totalQuantity} setTotalQuantity={setTotalQuantity} cartItems={cartItems} tests={tests} packages={packages} generateArray={generateArray} getusercart={getusercart}  booking={booking} bookings={mdBookings} showToggle={showToggle} setShowToggle={setShowToggle} bookingStep={bookingStep} labelArray={labelArray} currentStep={currentStep} updateStep={updateStep} /> 
            <ShowUploadBookingModal setUpdate={setUpdate} update={update}  booking={booking}  showToggle={showToggle} setShowToggle={setShowToggle} bookingStep={bookingStep} labelArray={labelArray} currentStep={currentStep} updateStep={updateStep}  />
           
            </main>
        </Layout>
    )

    
    }



export default Booking
