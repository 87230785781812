import React from 'react';
import Labtests from './Labtests'
import Product from './Product';

export default function Favorites(props){

  const { setUpdateFave, updateFav, products, onAdd, searchTerm, favoriteIds } = props;
  
  return (
    <>
      { products?.filter((val) => {
        if(searchTerm === ""){
          return val;
        }else if(
          val.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        ){
          return val 
        }
          
        }).map((product) => (
          product?.type === "Package" 
          ? 
          <Product 
            key={`package-${product?.id}`} 
            setUpdateFave={setUpdateFave} 
            updateFav={updateFav} 
            product={product} 
            onAdd={onAdd} 
            searchTerm={searchTerm} 
            favoriteIds={favoriteIds}>
          </Product> 
          : 
          <Labtests 
            key={`product-${product?.id}`} 
            setUpdateFave={setUpdateFave} 
            updateFav={updateFav} 
            product={product} 
            onAdd={onAdd} 
            searchTerm={searchTerm} 
            favoriteIds={favoriteIds}>
          </Labtests>
      ))}
    </>
  );
}
