import React from 'react'

export default function BackButton({onClick, children}) {
  
  return (
    <>
      <span onClick={onClick} className="btn lah_gradient text-white border-0 px-3 px-sm-2 mx-2 mx-sm-1">
          <div className='d-flex justify-content-center align-items-center p-2 p-sm-1'>
            <i className='bx bxs-chevron-left back-icon'></i>
            <span>{children}</span>
          </div>
      </span>
    </> 
  )
}
