import React, { useState, useEffect, useContext, useReducer } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios'
import { Layout } from '../../components/Layout/Layout';
import TableContainer from '../../components/TableContainer';
import { AuthContext } from '../../context/AuthContext';
import { getBookingsData, getTestsData, getPackagesData, getMedtechsData } from '../../utils/API';
import { getTimetime, handleSort , formattedDate , getrange, removezDate, handleCloseModal } from '../../utils/helpers';
import ShowMainBookingModal from '../../components/Modals/ShowMainBookingModal';
import ShowUploadBookingModal from '../../components/Modals/ShowUploadBookingModal';
import Spinner from '../../components/Layout/Spinner';
import { toast } from 'react-toastify';
import no_data from '../../assets/nodata.svg';
import ReturnPrev from '../../components/ReturnPrev';
import ClinicFilter from '../../components/Filters/ClinicFilter';
import Select from 'react-select'


const ClinicLabResults = () => {
  
    return (
    <Layout>
        <>
        <main className='container-fluid main-parent-container'>
            <div className='d-flex justify-content-end w-100 px-5 my-2'>  
                <ReturnPrev />
            </div>
        
            <div className='card border rounded-3 table-card-container scrollbar-div'>  
                <div className="table-responsive scrollbar-div">
                    <table className="bg-white table table-sm header-fixed">
                        <thead className="sticky-top bg-white" >
                            <tr> 
                                <th>Booking ID</th>
                                <th>Attending Physician</th>
                                <th>Patient Name</th>
                                <th>Age</th>
                                <th>Email</th>
                                <th>Contact</th>
                                <th>Booking Status</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody> 
                        
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>      
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                        <tr>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                            <td>Tests</td>
                        </tr>   
                    
                        </tbody>
                    </table>
                </div>
                
            </div>
            
            {/* <div id="main-md-table">
            <div className="std-table-container">
                <div className='m-auto d-flex flex-column justify-content-center align-items-center'><img src={no_data} className="no-data-img mb-4"/><span>No data available.</span></div>
            </div>
            </div> */}
        </main>
        </>
    </Layout>
  )
}

export default ClinicLabResults