import React, { useContext, useEffect }  from 'react';
import {useNavigate} from 'react-router-dom';
/* ----- Components ----- */
import { Layout } from '../../components/Layout/Layout';
import doctors from '../../assets/doctors.svg'
import { AuthContext } from '../../context/AuthContext';
import book_a_service from '../../assets/home-appointment.png'
import view_bookings from '../../assets/View-Bookings.png'
import view_lab_results from '../../assets/ViewLabResults.png'
import { toast } from 'react-toastify';



const ClinicHomePage = () => {
  const { userState } = useContext(AuthContext)
  const navigate = useNavigate()
  // console.log(userState)

  useEffect(()=>{
    if(userState?.role !== "Clinic"){
      toast.error("Unauthorized")
      return navigate("/")
    }
  },[])

  const handleNavigate = type => {
      type === "view doctors"
      ? navigate('/md/clinic/doctors')
      : type === "view_bookings"
      ? navigate('/md/clinic/bookings')
      : type === "view_lab_results"
      ? navigate('/md/clinic/lab_results')
      : navigate('/md/clinic/home')
  }


  return (
   <>
    <Layout>
      <div id="main-container" className="container-fluid overflow-auto mb-5 md-home">
        <div className="row">
          <div className="col d-flex flex-column justify-content-center align-items-center text-center my-5">
            <img src={ doctors } className="w-25 mb-3 md-img mb-8"/>
            <span className="h4 primary-text fw-bold text-secondary greeting">Welcome to MD Portal <strong className='text-greetings'> { userState?.user?.email }!</strong></span>  
            <span className="primary-text fw-bold text-secondary greeting">What would you like to do today?</span> 
          </div>
         
          <div className="row justify-content-center m-0">
              <div className="col-sm mb-3">
                  <div className="card card-hover">
                  <img className="card-img-top img-fluid" src={book_a_service} alt="book a service"/>
                  <div className="card-body">
                    <button onClick={ ()=> handleNavigate("view doctors") } className="cta mb-3">View Doctors</button>
                    <p className="card-text">View and a Doctor to your clinic.</p>
                  </div>
                </div>
              </div>

              <div className="col-sm mb-3">
                  <div className="card card-hover">
                  <img className="card-img-top img-fluid" src={view_bookings} alt="view bookings"/>
                  <div className="card-body">
                    <button onClick={ ()=> handleNavigate("view_bookings") } className="cta mb-3">View Bookings</button>
                    <p className="card-text">Track, manage, and view the status of your patient's appointments.</p>
                  </div>
                </div>
              </div>

              <div className="col-sm mb-5">
                  <div className="card card-hover">
                  <img className="card-img-top img-fluid" src={view_lab_results} alt="lab results"/>
                  <div className="card-body">
                    <button onClick={ ()=> handleNavigate("view_lab_results") } className="cta mb-3">View Lab Results</button>
                    <p className="card-text">View and manage the lab results of your patients.</p>
                  </div>
                </div>
              </div>
          </div>      
        </div>
      </div>

    </Layout>
  )
   </>
  )
}

export default ClinicHomePage