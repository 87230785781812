import React, { useState, useEffect, useContext, useReducer } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios'
import { Layout } from '../../components/Layout/Layout';
import TableContainer from '../../components/TableContainer';
import { AuthContext } from '../../context/AuthContext';
import { getBookingsData, getTestsData, getPackagesData, getMedtechsData } from '../../utils/API';
import { getTimetime, handleSort , formattedDate , getrange, removezDate, handleCloseModal, truncate } from '../../utils/helpers';
import Spinner from '../../components/Layout/Spinner';
import { toast } from 'react-toastify';
import no_data from '../../assets/nodata.svg';
import ReturnPrev from '../../components/ReturnPrev';


const LabResults = () => {

    const { REACT_APP_MD_BASEURL } = process.env;
    const { userState } = useContext(AuthContext)
    const navigate = useNavigate()
    const [labResults, setLabResults] = useState([])
    const [booking, setBooking] = useState(null)
    const [loading, setLoading] = useState(false)
    const [update, setUpdate] = useState(false);
    const [showToggle,setShowToggle] = useState(false);
    /*** request headers */ 
    const requestHeaders = { 'Authenticate': `Bearer ${localStorage.token}`, 'content-type': 'application/json' }
    const md_id = localStorage.admin_id
    

    // useEffect(() => {
    //     console.log("Results: ", labResults)  
    // }, [labResults]);

    useEffect(() => {
        setLoading(true)
        // api/v2/doctors/doctor/:doctor_id/labresults (upload)
        axios.get(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/labresults`, { headers: requestHeaders })
        .then((response)=> {
            setLoading(false)
            // console.log(response?.data?.data?.bookings);
            const all_lab_results = response?.data?.data?.bookings
            setLabResults(all_lab_results)
        })
        .catch((error)=> { 
            setLoading(false)
            toast.error(error)
        })      
    }, []); 
      

  return (
    <Layout>
        <main className='container-fluid main-parent-container'>
                <div className='d-flex justify-content-end w-100 px-5 my-2'>  
                    <ReturnPrev />
                </div>
                
                { loading 
                ?
                <Spinner /> 
                :  
                <>
                { labResults.length !== 0
                ?     
                <div id="labresult-table" className='card border rounded-3 table-card-container scrollbar-div'>  
                    <div className="table-responsive scrollbar-div">
                        <table className="bg-white table table-sm header-fixed">
                            <thead className="sticky-top bg-white" >
                                <tr className='text-center'> 
                                    <th>Booking Type</th>
                                    <th>Booking ID</th>
                                    <th>Last Name</th>
                                    <th>First Name</th>
                                    <th>Age</th>
                                    <th>Email</th>
                                    <th>Contact No.</th>
                                    <th>Address</th>
                                    <th>Action</th>
                                </tr>

                            </thead>
                            <tbody> 
                            { handleSort(labResults)?.map((results, index)=> {
                            // console.log(results?.result?.file?.url)
                            return (
                                <tr key={`md-results-${results.code}-${index}`} class="text-center">
                                    <td>{results?.type}</td>
                                    <td>{results?.code}</td>
                                    <td>{results?.last_name || "N/A" }</td>
                                    <td>{results?.first_name || "N/A"}</td>
                                    <td>{results?.age || "N/A"}</td>
                                    <td>{results?.email || "N/A"}</td>
                                    <td>{results?.mobile_number || "N/A"}</td>
                                    <td className='truncate'>{results?.location || "N/A"}</td>
                                    { results?.result ?
                                        <td><button className="btn btn-info text-white border-1 w-75 btn-view" type="button">
                                            { results?.status == "Partial results received" 
                                            ? 
                                                <a className="link-text" href={results?.result?.file?.url} target="_blank">View Partial Results</a> 
                                            : results?.status == "Results received" 
                                            ? 
                                                <a className="link-text" href={results?.result?.file?.url} target="_blank">View Full Results</a>
                                            :   <a className="link-text" href={results?.result?.file?.url} target="_blank">View Results</a>
                                            }
                                        </button></td>
                                    :
                                        <td>
                                            <button className="btn btn-secondary text-white border-1 w-75 btn-view disabled" type="button">
                                                <span className="btn-text">No Results</span>
                                            </button>
                                        </td>
                                    }
                                </tr>
                            );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                    
                :   
                <div id="main-md-table">
                    <div className="std-table-container">
                        <div className='m-auto d-flex flex-column justify-content-center align-items-center'><img src={no_data} className="no-data-img mb-4"/><span>No data available.</span></div>
                    </div>
                </div> 
                }
                </>
            }
            
        </main>
  </Layout> 
  )
}

export default LabResults