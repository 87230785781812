import React from 'react';
import axios from 'axios'
import { toast } from 'react-toastify';

const requestHeaders = {Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json"}
const md_id = localStorage.admin_id


const { 
    REACT_APP_MD_BASEURL, 
    REACT_APP_TESTS,
    REACT_APP_PACKAGES,
    REACT_APP_MEDTECHS
   } = process.env;


 
// fetch bookings
export const getBookingsData = async () => {
    let bookingResp
    await axios
        .get(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/bookings`, { headers: requestHeaders })
        .then(response => {
            // console.log(response?.data)
            const all_bookings = response?.data?.data?.bookings   
            const website_bookings = all_bookings.website_bookings;
            const upload_bookings = all_bookings.upload_bookings;
            // const website_bookings = bookings_list.website_bookings;
            // const upload_bookings = bookings_list.upload_bookings;
            bookingResp = [...website_bookings, ...upload_bookings];
            // console.log(`API Bookings- ${bookingResp}`)
        })
        // .catch(error => console.log('get bookings error', error));
        .catch(function (error) {
            if (error.response) {
            //   setLoading(false)
              toast.error(error.response.data.message[0])
            }
        })
    return bookingResp
};

// fetch customers
export const getCustomersData = async ()  => { 
    let customersResp
    await axios
        .get(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/patients`, { headers: requestHeaders })
        .then(data => {
            customersResp = data?.data?.data?.patients;
            // console.log(data?.data?.data?.patients);
        })
        // .catch(error => console.log('get customers error', error));
        .catch(function (error) {
            if (error.response) {
            //   setLoading(false)
              toast.error(error.response.data.message[0])
            }
        })
    return customersResp
};

// // fetch bookings
// export const getUploadCustomers = async () => {
//     let uploadCustomersResp
//     await axios
//         .get(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/bookings`, { headers: requestHeaders })
//         .then(response => {
//             console.log(response?.data)
//             uploadCustomersResp = response?.data?.data?.bookings.upload_bookings;
//             // console.log(`Upload Customers- ${ uploadCustomersResp}`)
//         })
//         .catch(error => console.log('get upload customers error', error));
            // .catch(function (error) {
            //     if (error.response) {
            //     toast.error(error.response.data.message[0])
            //     }
            // })
//     return uploadCustomersResp
// };


// fetch tests
export const getTestsData = async ()  => {
    
    let testsResp
    await axios
        .get(REACT_APP_TESTS, { headers: requestHeaders })
        .then(response => {
            testsResp = response?.data?.data?.tests;
            // console.log(testsResp);
        })
        // .catch(error => console.log('get tests error', error));
        .catch(function (error) {
            if (error.response) {
            //   setLoading(false)
              toast.error(error.response.data.message[0])
            }
        })
    return testsResp
};

// fetch packages
export const getPackagesData = async ()  => {
    
    let packagesResp
    await axios
        .get(REACT_APP_PACKAGES, { headers: requestHeaders })
        .then(response => {
            packagesResp = response?.data?.data?.packages;
            // console.log(packagesResp);
        })
        // .catch(error => console.log('get tests error', error));
        .catch(function (error) {
            if (error.response) {
            //   setLoading(false)
              toast.error(error.response.data.message[0])
            }
        })
    return packagesResp
};


// fetch medtechs
export const getMedtechsData = async ()  => {
    let medtechsResp
    await axios
        .get(`${REACT_APP_MD_BASEURL}/medtechs`, { headers: requestHeaders })
        .then(response => {
            medtechsResp = response?.data?.data?.medtechs;
            // console.log(medtechsResp);
        })
        // .catch(error => console.log('get medtechs error', error));
        .catch(function (error) {
            if (error.response) {
            //   setLoading(false)
              toast.error(error.response.data.message[0])
            }
        })
    return medtechsResp
};