import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import axios from 'axios'
/* ----- Context ----- */
// import CurrentUser from '../../context/CurrentUser'
import { UserContext } from '../../context/AuthContext'
import { toast } from 'react-toastify'
/* ----- Asset ----- */
import logo from '../../assets/logo.png'
// import { AuthContext } from '../../App'
import { AuthContext } from '../../context/AuthContext';
import PasswordStrengthBar from 'react-password-strength-bar';


const PasswordUpdate = ({ forClinic }) => {

  const { REACT_APP_MD_AUTH_BASEURL, REACT_APP_CLINIC_AUTH_BASEURL } = process.env;
  const { userState, userDispatch } = React.useContext(AuthContext)

  // console.log(userState.user)

  const navigate = useNavigate()
  const passwordRef = useRef(null)
  const new_passwordRef = useRef(null)
  const passwordConfirmationRef = useRef(null)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)


  useEffect(() => {

    if ((userState?.role !== "Doctor" && !forClinic) || (forClinic && userState?.role !== "Clinic")) {
      toast.error("Unauthorized")
      return navigate("/")
    }
  }, [])

  const [password, setState] = useState({
    value:'',
  });

  const handlePasswordChange = (e) => {
    
  }

  const handleUpdatePassword = (e, id) => {
    e.preventDefault()

    if (new_passwordRef.current.value === passwordConfirmationRef.current.value) {

      const adminData = forClinic ? {
        clinic: {
          current_password: passwordRef?.current?.value,
          new_password: new_passwordRef?.current?.value,
          confirm_password: passwordConfirmationRef?.current?.value,
          id: userState.user.id,
        }
      } : {
        doctor: {
          current_password: passwordRef?.current?.value,
          new_password: new_passwordRef?.current?.value,
          confirm_password: passwordConfirmationRef?.current?.value,
          id: userState.user.id,
        }

      };

      setLoading(true)
      axios({
        method: "POST",
        url: `${forClinic ? REACT_APP_CLINIC_AUTH_BASEURL : REACT_APP_MD_AUTH_BASEURL}/update_password`,
        data: adminData,
        headers: { Authenticate: `Bearer ${localStorage.token}`, 'content-type': 'application/json' }
      })
        .then((data) => {
          setLoading(false)
          if (data.status === 200) {
            setError(null)
            toast.success("Successfully updated password!")
            navigate(`/md/${forClinic ? 'clinic/' : ''}login`)
          }
        })
        .catch((error) => {
          setError(error)
          toast.error("Current password is incorrect.")
          setLoading(false)
        })
    } else {
      setError("Password confirmation doesn't match the new password.");
      toast.error(error)

    }
  }

  return (
    <div className="container">
      <div className="row h-100 py-4">
        <div className="col-12 col-sm-8 col-md-6 col-lg-4 m-auto p-3">
          <div className="card border-0 py-2">
            <div className="card-body mt-5 ">
              <div className="w-100 mx-auto d-flex flex-column justify-content-center align-items-center text-center">
                <img src={logo} className="w-75 mb-5" />
                <p className="text-secondary fw-light font-workSans">Hi {userState?.user?.email}!</p>
                <h6 className="h6 text-secondary">{userState?.sign_in_count !== 1 ? "Update your password regularly for better security." : "Please set your password."}</h6>
              </div>

              <form onSubmit={handleUpdatePassword} className="p-2 mt-3 text-center">

                <div className="input-group mb-3">
                  <span className="input-group-text secondary-color text-light">
                    <i className="bi bi-person"></i>
                  </span>
                  <input type="password" ref={passwordRef} className="form-control" placeholder="Current Password" required />
                </div>

                <div className="input-group mb-3">
                  <span className="input-group-text secondary-color text-light">
                    <i className="bi bi-person"></i>
                  </span>
                  <input type="password" ref={new_passwordRef}  onChange={(e) => setState({value: e.target.value})} className="form-control" placeholder="New Password" required />
                </div>
                <PasswordStrengthBar password={password.value} scoreWords={['Very Weak', 'Weak', 'Okay', 'Good', 'Strong']} shortScoreWord={'Too Short'}/>
                <br />

                <div className="input-group mb-3">
                  <span className="input-group-text secondary-color text-light">
                    <i className="bi bi-person"></i>
                  </span>
                  <input type="password" ref={passwordConfirmationRef} className="form-control" placeholder="Confirm New Password" required />
                </div>


                <div className="pt-1 mb-4">
                  <button className="cta" type="submit">Update Password</button>
                </div>

              </form>
              {userState?.sign_in_count !== 1 &&

                <p onClick={() => navigate('/md/home')} role="button" className="text-center return-link"><i className='bx bxs-chevrons-left' ></i> Return to homepage</p>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordUpdate