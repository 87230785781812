import React, { useContext, useState, useEffect, useRef} from 'react';

import { useNavigate, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Spinner from '../Layout/Spinner';
import MapContainer from '../Map/GoogleMaps';
import { getMedtechsData } from '../../utils/API';
import { handleCloseModal, getNewTime, getCurrentTime, disablePastDate, createdatetoISO, getDateAfterTomorrow  } from '../../utils/helpers';
import StepNavigation from "./stepNavigation";
import {useDropzone} from 'react-dropzone';


const ShowUploadBookingModal = ({ booking, update, setUpdate, bookingStep, labelArray, currentStep, updateStep, setShowToggle  }) => {
    const location = useLocation()
    const { REACT_APP_MD_BASEURL } = process.env
    const [selectedFile, setSelectedFile] = useState([]);
    // const selectedFile=useRef(null)
    const [getForms, setGetForms] = useState([])
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [count, setCount] = useState(0)
    const { userState } = useContext(AuthContext);
    const navigate =useNavigate()
    //attributes
    const email = useRef()
    const first_name = useRef()
    const last_name = useRef()
    const gender = useRef()
    const remarks = useRef()
    const mobile_number=useRef()
    const date = useRef()
    const additional_tests = useRef()
    // const [additionalTests, setAdditionalTests] = useState()
    const [medtechs, setMedtechs] = useState([])
    const [medtechSelected, setMedtechSelected] = useState()
    const [bookingStatus, setBookingStatus] = useState({value:""})
    const bookingStatusOptions =  [
        { value:"Confirmed", label:"Booking Confirmed"}, 
        { value:"Pending",  label:"Payment Pending"},
        { value:"Cancelled", label:"Booking Cancelled"}, 
        { value:"En Route",  label:"Phlebotomist En Route"},
        { value:"Arrived", label:"Phlebotomist Arrived"},
        { value:"Started", label:"Appointment Started"}, 
        { value:"Done",  label:"Appointment Completed"},
        { value:"Specimen Turned over", label: "Specimen Delivered"},
        { value:"Results received", label: "Full Results Received"},
        { value:"Partial results received", label:"Partial Results Received"}
    ]
    // const [paymentStatus, setPaymentStatus] = useState({value: booking?.payment?.status })
    const [paymentStatus, setPaymentStatus] = useState()
    const paymentStatusOptions = [
        { value: "Unpaid", label: "Unpaid"}, 
        { value: "Paid", label:"Paid"}
    ]
    const [disabled, setDisabled] = useState(true)
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [currentPosition, setCurrentPosition] = useState({
        address: "",
        lat: 0,
        lng: 0
    })
    const [error, setError] = useState("")
    const [schedule, setSchedule]= useState()
    const scheduleOptions = [
            {slot:10, available:'', label: "-- Select Schedule -- ", startTime:'N/A'},
            {slot:0, available:'', label: "05:00am - 07:00am ", startTime:'05:00', endTime:'07:00'},
            {slot:1, available:'', label: "07:00am - 09:00am ", startTime:'07:00', endTime:'09:00'},
            {slot:2, available:'', label: "09:00am - 11:00am ", startTime:'09:00', endTime:'11:00'},
            {slot:3, available:'', label: "11:00am - 01:00pm ", startTime:'11:00', endTime:'13:00'},
            {slot:4, available:'', label: "01:00pm - 03:00pm ", startTime:'13:00', endTime:'15:00'}
          ]
    const [dates, setDates] = useState() 
    let day
    const [preftime, setPreftime] = useState()
    let pref
    const [availmedtech,setAvailmedtech] = useState([])
    const current = new Date();
    const curentdate = `${current.getFullYear()}-0${current.getMonth()+1}-0${current.getDate()}`
    const requestHeaders = {Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json"}
    const md_id = localStorage.admin_id
    const [newSched,setNewSched] = useState([])


    const fileValidator = (file) => {
        if (file?.size > MAX_SIZE) {
          toast.warning("file is larger than 50MB")
          return {
            code: "file-size-too-large",
            message: `file is larger than 50MB`,
          };
        }
        return null;
    }
    const MAX_SIZE = 5242880;
    const MAX_FILES = 5;
    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
      maxFiles: MAX_FILES,
      multiple: true,
      accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf']
      },
      // accept: 'image/*',
      onDrop: acceptedFiles => {
      console.log(acceptedFiles);
      setSelectedFile(selectedFile?.concat(...acceptedFiles))
      // setFiles(acceptedFiles.map(file => Object.assign(file, {
      //     preview: URL.createObjectURL(file)
      // })));
      },
      validator: fileValidator
    });

    // let files = acceptedFiles?.map(file => (
    //     <span key={file.path}>
    //       {file.path}
    //     </span>
    // ));
      
    const acceptedFileItems = acceptedFiles?.map((file,i) => (
        <>
          <li key={file.path}>
            {file.path} 
          </li>
          {/* <i className='bx bx-trash' onClick={removeFile}></i> */}
        </>
      ));
      
    useEffect(()=>{  
        setCurrentPosition({
            address: booking?.location,
            lat: parseFloat(booking?.latitude),
            lng: parseFloat(booking?.longitude)
        })
        
        setStartTime(getNewTime(booking?.start_time))
        setEndTime(getNewTime(booking?.end_time))
        const sched = scheduleOptions?.find((x) => x?.startTime === getNewTime(booking?.start_time));

        day = booking?.date
        setDates(day)
        setSchedule({value:getNewTime(booking?.start_time)})
        setBookingStatus({value: booking?.status})
        setPaymentStatus({value: booking?.payment?.status})
        setMedtechSelected({name: booking?.medtech?.name,id: booking?.medtech_id})
        getavailmedtech()
        medtechdetails(booking?.medtech?.name)
        // files = acceptedFiles?.map(file => (
        //     <span >
        //       {file.path}
        //     </span>
        //   ));
    },[booking])

    // console.log(booking)
    // Fetching medtechs

    // fetch customers, medtechs, packages and tests from database
    useEffect(()=>{  
        setLoading(true)
        async function fetchData(){
            const medtechsData = await getMedtechsData()
            if (medtechsData) {
                setLoading(false)
                setMedtechs(medtechsData)
            }
                //  console.log(medtechsData)    
        }
        fetchData()
        day=dates
        getavailmedtech()
    },[])
   
  /********** check medtech availability **********/ 
  const getavailmedtech = () => {
    setAvailmedtech()
    let av = []
    let ns=[]
    ns.push(scheduleOptions[0])
    if (day || pref) {
      medtechs.map((x) => {
        if (x?.availability_slot) {
          let avail = x?.availability_slot
        //   console.log(avail)

          Object.entries(avail).map((datekey) => {
            // console.log(datekey)
            let d = datekey[0]
            let s = datekey[1]
            // console.log(d)
            if (d == day) {
                //   console.log(x)
                // console.log(x.name,s)
                //   console.log(s)
           
              Object.keys(s).map((slots)=>{
               if(s[slots]){
                let index = scheduleOptions.find(item => item.slot == slots)
                let nodup = ns.find(item => item.slot == slots)
                av.push(x)
                if(!nodup){
                  ns.push(index)
                }
               }
                // console.log(slots,s[slots])

                  // if(slots == pref?.slot){
                  //   if(s[slots]){
                  //     av.push(x)
                  //     console.log('still avail',slots,s[slots])
                  //   }
                  // }
              
              })

            }
           
          })
        }
      })
    }
    setNewSched(ns)
    // console.log(ns)
    if(av[0]){
        setAvailmedtech(av[0])
    }else{
        // console.log("null")
        setAvailmedtech(null)
    }
    // setFormData(prevState => ({
    //   ...prevState,
    //   medtech_id: availmedtech?.id}))
  }
          

    const handleClick =()=> {
        if(disabled){
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    const medtechdetails =(x)=> {
        const medtech = medtechs.find(obj => {
            if(obj.name==x){
                // console.log(obj)
                return obj
            }else{
                // console.log("test")
                return null
            }
        })
        // console.log(medtech)
        if(medtech){
        setMedtechSelected(medtech)
        }
        else{
            setMedtechSelected(null)
        }
    }   

    const cartClear = () => {
        setDisabled(true)
    }
    // console.log(medtechSelected)
    // console.log(startTime,endTime)

    const handleEdit = (e) => {
        e.preventDefault()

        let  bookingData = {
            booking:{
                id: booking?.id,
                patient_email: email?.current.value,
                // date: booking?.status === 'Pending' ? dates : booking?.date,
                date: dates,
                start_time: checktime('s'),
                end_time: checktime('e'),
                location: currentPosition?.address,
                // remarks: remarks?.current.value,
                status: bookingStatus?.value,
                patient_first_name: first_name?.current.value,
                patient_last_name: last_name?.current.value,
                // patient_gender: gender?.current.value,
                medtech_id:availmedtech?.id,
                latitude:currentPosition?.lat,
                longitude:currentPosition?.lng,
                patient_mobile_number: mobile_number?.current.value,
                // additional_tests: additional_tests?.current.value,
                medtech: medtechSelected
            }
        }
        
        // let paymentData = {
        //     payment:{
        //         id: booking?.payment?.id,
        //         status: paymentStatus?.value
        //     }
        // }
      
            let EDIT_URL = `${REACT_APP_MD_BASEURL}/doctor/${md_id}/upload/bookings/${booking?.id}`;
            // let PAYMENT_URL = `${REACT_APP_UPLOAD_BASEURL}/payments/${booking?.payment?.id}`;
            const requestHeaders = { Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json" }
    
            // const editPayment = axios.put(PAYMENT_URL, paymentData, booking?.payment?.id, { headers: requestHeaders });
            const editBooking = axios.put(EDIT_URL, bookingData, { headers: requestHeaders });
            
                // await Promise.all([editPayment, editBooking])
                setLoading(true)

                axios.put(EDIT_URL, bookingData, { headers: requestHeaders })
                .then((data) => {
                    if(data.status === 200){
                        fileUploadHandler(booking?.id)
                        toast.success("Successfully updated booking data details!")
                        handleCloseModal("showUploadBookingModal")
                        navigate('/md/bookings')
                        setUpdate(!update);  
                        setDisabled(true)
                        setSelectedFile([])
                        setShowToggle(false)
                    }

                })
                .catch(function (error) {
                    if (error.response) {
                        toast.error(error.response.data.message[0])
                    }
                    setShowToggle(false)
                })
    }


    
 

    const fileUploadHandler = (id) => {
        // debugger
        // e.preventDefault()
        
        const reqFormData = new FormData();
        if(selectedFile){
        // selectedFile?.map((file, i) => {
            // reqFormData.append('file', file)
            Promise.all(selectedFile?.map((file, i) => {
                reqFormData.append('file', file)
                fetch(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/upload/bookings/${id}/requisition_forms`, { method: "POST", headers: { Authenticate: `Bearer ${localStorage.token}` }, body: reqFormData })
            }))
            // setLoading(true)
            // fetch(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/upload/bookings/${booking?.id}/requisition_forms`, { method: "POST", headers: { Authenticate: `Bearer ${localStorage.token}` }, body: reqFormData })
            .then(res => {
                res.json()
                // setLoading(false)
                // handleCloseModal("showUploadBookingModal")
                // setUpdate(!update) 
            // })})
            })
            .catch(function (error) {
                setLoading(false)
                if (error.response) {
                    toast.error(error.response.data.message[0])
                }
            })
        }else{
            setLoading(false)
            handleCloseModal("showUploadBookingModal")
            setUpdate(!update);
        }
        setSelectedFile(null)
    }

    // recheck this
    useEffect(() => {
        if(booking) {
            const validForms = booking?.requisition_form?.filter((x) => x.file.url !== null )
            setGetForms( validForms )
            setCount(booking?.requisition_form?.filter((x) => x.file.url !== null ).length)
            // console.log("REQ FORM:", booking?.requisition_form)
        }
    }, [booking])
    
   
    // get location
    const getLocation = coords => {
        // console.log(coords);
        setCurrentPosition(coords);
    }


    //set time to payload and check available medtech
    const makehour = (start) => {
        // console.log(start)
        if(start !== 'N/A' || start !== null){
            day = dates
            pref = scheduleOptions.find(obj => {
                return obj.startTime == start
            })
            setSchedule({value:start})
            setPreftime(pref)
            setStartTime(pref.startTime)
            setEndTime(pref.endTime)
        }else{
            setStartTime(pref.startTime)
            setEndTime(pref.endTime)
        }
        
        // getavailmedtech()
        return start
    }



    //set date to payload and check available medtech
    const getDate = (datet) => {
        if(datet){
            day = datet
            pref = preftime
            setDates(datet)
            getavailmedtech()
        }else{
            setStartTime(null)
            setEndTime(null)
            setDates(datet)
            getavailmedtech()
        }
        
    }

//check time if with value or N/A returns null or converted time
const checktime = (t)=>{
    let val
    if(t === 's'){
        if (startTime == 'N/A' || startTime == null){
            val = null
            setMedtechSelected(null)
        }else{
            val = createdatetoISO(startTime)
        }
    }else{
        if (startTime == 'N/A' || startTime == null){
            val = null
            setMedtechSelected(null)
        }else{
            val = createdatetoISO(endTime)
        }
    }

    return val
}
      

    return (
          <div className="modal fade" id="showUploadBookingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content p-3">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Booking Information (with-HMO) <span onClick={handleClick}><i className={booking?.status === "Cancelled" ? "d-none" : "bx bxs-edit-alt"}></i></span></h5>
                        {/* <span onClick={handleClick}><i className={booking?.status==="Cancelled"?"d-none":"bx bxs-edit-alt"}></i></span> */}
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <br/>
                    <br/>
                    <StepNavigation bookingStep={bookingStep} labelArray={labelArray} currentStep={currentStep} updateStep={updateStep}></StepNavigation>
                    <div className="modal-body p-5">
                        <form onSubmit={ handleEdit }>
                            <fieldset disabled={disabled}>
                                        
                                <div className="col-12 d-flex w-100 d-flex justify-content-end align-items-center w-50 px-2">

                                    <div class="dropdown">
                                        <button className="btn btn-secondary border-1 px-2 dropdown-toggle" type="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            View {count} Requisition form
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        { getForms?.map((form, index) => {
                                            return (
                                                // <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <li key={form.id}><a className="dropdown-item" href={form?.file?.url} target="_blank">Form {index + 1}</a></li>
                                                // </ul>
                                            )
                                        })}
                                        </ul>
                                    </div>
                                </div> 
                            
                                <div className='mb-3'>
                                    {/* <label for="formFileMultiple" className="form-label">Upload Requisition Form</label><br/>
                                    <input
                                        type="file"
                                        onChange={(e) => setSelectedFile(e.target.files[0])}
                                        multiple
                                    /> */}
                                    {/* <button className="cta" type="submit" onClick={fileUploadHandler}>UPLOAD</button> */}

                                    <div className="container">
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} 
                                                max={MAX_SIZE} 
                                                min={0}
                                            />
                                             </div>
                                            <button type="button" onClick={open}>
                                                Choose file/s to upload
                                            </button>
                                            { selectedFile?.length !== 0 ?
                                                <>
                                                
                                                <span className='mx-2'>
                                                    {acceptedFileItems.length} files chosen
                                                </span>
                                                </>
                                                : <></>
                                            }
                                        {/* {acceptedFiles && 
                                        <aside>
                                            <ul>{acceptedFiles}</ul>
                                        </aside>
                                        } */}
                                    </div>
                                </div>

                            
                                <div className="row g-3">
                                        {/* <div className="col-md-4">
                                            <label>Booking Id</label>
                                            <input defaultValue={booking?.id} className="form-control"  ></input>
                                        </div> */}
                                        <div className="col-md-6">
                                            <label>Booking Code</label>
                                            <input defaultValue={booking?.code} className="form-control rounded-2"  ></input>
                                        </div>
                                        
                                        {/* <div className="col-md-4">
                                            <label>Medtech Name</label>
                                            <select
                                                className="form-control form-select"
                                                // defaultValue={medtechSelected?.name}
                                                value={medtechSelected?.name}
                                                onChange={e => medtechdetails(e.target.value)}
                                            >
                                                {availmedtech?.map((option) => (
                                                <option value={option.name}>{option.name}</option>
                                                ))}
                                            </select>   
                                        </div> */}

                                        <div className="col-md-6">
                                            <label>Booking Status</label>
                                            <select 
                                                value={bookingStatus?.value}
                                                onChange={(e) => setBookingStatus({value: e.target.value})}
                                                className="form-control form-select rounded-2" 
                                                id="floatingSelect" 
                                                aria-label="Floating label select example"
                                        >
                                                {bookingStatusOptions.map((option) => (
                                                <option value={option.value}>{option.label}</option>
                                                ))} 
                                            </select>
                                        </div>

                                        <div className="col-12">
                                            <label>First Name</label>
                                            <input ref={first_name} defaultValue={booking?.patient_first_name} className="form-control rounded-2" ></input>
                                        </div>
                                        <div className="col-12">
                                            <label>Last Name</label>
                                            <input ref={last_name} defaultValue={booking?.patient_last_name} className="form-control rounded-2" ></input>
                                        </div>
                                        <div className="col-12">
                                            <label>Email</label>
                                            <input ref={email} defaultValue={booking?.patient_email} className="form-control rounded-2" ></input>
                                        </div>
                                        
                                        <div className="col-12">
                                            <label>Mobile Number</label>
                                            <input ref={mobile_number} defaultValue={booking?.patient_mobile_number} className="form-control rounded-2" ></input>
                                        </div>
                                        
                                        <div className="form-group col-md-6">
                                            <label>Date</label>
                                            <input 
                                                type="date" 
                                                value={dates}
                                                onChange={e =>  getDate(e.target.value) }
                                                className="form-control rounded-2" 
                                                // min={booking?.status === 'Pending' ? '' : disablePastDate()}
                                                // min={disablePastDate()}
                                                // disabled={booking?.status === 'Cancelled' ? true : false}
                                                min={booking?.date == null ? getDateAfterTomorrow() : ''}
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                        <label>Preferred Schedule</label>
                                            <select
                                                // value={booking?.start_time}
                                                // value={schedule?.startTime}
                                                value={schedule?.value}
                                                className="form-control form-select rounded-2"
                                                onChange={e => makehour(e.target.value)}>
                                                {newSched?.map((option) =>{
                                                    return <option value={option.startTime}>{option.label}</option>
                                                })}
                                            </select> 
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label>Address</label>
                                            <input 
                                                id="address" 
                                                className="form-control rounded-2 mb-2" 
                                                disabled
                                                value={currentPosition?.address} 
                                                onChange={(event) =>setCurrentPosition({ ...currentPosition, address: event.target.value})}
                                            />

                                            <label>Change Address</label>
                                            <MapContainer google={this?.google} getLocation={getLocation} currentPosition={currentPosition} setCurrentPosition={setCurrentPosition} />
                                        </div>

                                        <button type="submit" className="cta">Save changes</button>
                                    </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default ShowUploadBookingModal