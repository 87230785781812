import React from 'react'
import { useNavigate } from 'react-router-dom'
import success_img from '../../assets/check-circle.svg'
import admin_logo from '../../assets/logo_md.png'
import ParticlesConfig from '../../utils/ParticlesConfig';

const EmailSent = () => {
  const navigate = useNavigate()

  return (
    <>
      <div style={{ position: 'absolute' }}>

        <ParticlesConfig />
      </div>
      <div className="vh-100 bg-cyan">
        <div className="container h-100 py-5">
          <div className="row d-flex justify-content-center mt-5 h-100">
            <div className="col-sm col-md-8 col-lg-6 col-xl-5">
              <div className="card-login border-0 bg-cyan">
                <div className="card-body login p-5 text-center">
                  <div className="d-flex justify-content-center align-items-center py-5">
                    <img src={admin_logo} className="w-75 " />
                  </div>
                  {/* <div id="main-container"> */}
                    <div id="notice" className='container-fluid d-flex flex-column h-100 justify-content-center justify-content-center align-items-center text-center'>
                        <img src={success_img}/>
                        <br />
                        <h2>Email sent!</h2>
                        <p className="h6">Please check the reset password instructions sent to your email. The link is valid for 1 hour only.</p>
                        <br />
                        <button className="cta" onClick={() => navigate('/md/home')}>Return to homepage</button>
                        {/* <p onClick={() => navigate('/md/home')} className="text-primary fw-bold" role="button"><u>Return to homepage</u></p> */}
                    </div>
                {/* </div>      */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailSent