import React from 'react'
import { Layout } from '../../components/Layout/Layout'


const ContactUs = () => {
  return (
    <Layout>
        <div className='container d-flex flex-column justify-content-center align-ites-center w-75'>
            <div className="row">
                <div className="col mb-5">
                    <div className="card">
                        <div className="card-body">
                            <form className="p-4">
                                <div className="row mb-5">
                                    <div className="col h3">Fill in your details</div>
                                    <hr/>
                                </div>

                                <div className="row mb-4">
                                    <div className="form-group col">
                                        <label htmlFor="floatingInput">Name</label>
                                        <input type="text" className="form-control rounded-0" required/>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="form-group col">
                                        <label htmlFor="floatingInput">Email</label>
                                        <input type="text" className="form-control rounded-0" required/>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="form-group col">
                                        <label htmlFor="floatingInput">Subject</label>
                                        <input type="text" className="form-control rounded-0" required/>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="form-group mb-4">
                                        <label htmlFor="floatingInput">Message</label>
                                        <textarea  type="text" className="form-control rounded-0 h-100" rows="5" required/>
                                    </div>
                                </div>

                                
                                <div className="row d-flex justify-content-center align-items-center">
                                    <button className="col-4 mx-1 cta" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </Layout>
  )
}

export default ContactUs