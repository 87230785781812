import React, { useState, useRef, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Layout } from '../../components/Layout/Layout';
import { createdatetoISO, getNewTime, getCurrentTime, disablePastDate, getFormattedPrice, handleCloseModal} from '../../utils/helpers';
import { getCustomersData, getTestsData, getPackagesData, getMedtechsData } from '../../utils/API';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import MainPage from './AddToCart/MainPage';
import TestsPage from './AddToCart/TestsPage';
import Basket from './AddToCart/Basket';
import MapContainer from '../../components/Map/GoogleMaps';
import { toast } from 'react-toastify';
import { getRandomData } from '../../utils/helpers';
import { AuthContext } from '../../context/AuthContext';
import Favorites from './AddToCart/Favorites';

// GET	/api/v2/doctors/doctor/:doctor_id/favorites
// POST	/api/v2/doctors/doctor/:doctor_idfavorites
// DELETE	/api/v2/doctors/doctor/:doctor_idfavorites/:id

const BookOutOfPocket = () => {
  const { REACT_APP_MD_BASEURL, REACT_APP_MD_MAINUS_BASEURL, REACT_APP_TESTS, REACT_APP_PACKAGES } = process.env;
  const { userState } = useContext(AuthContext)
  const navigate=useNavigate()
  /*** initial state for form data ***/
  const [formData, setFormData] = useState({
    customer_id:null,
    medtech_id:null,
    email:null,
    first_name:null,
    last_name:null,
    mobile_number:null,
    location:null,
    latitude:null,
    longitude:null,
    date:null,
    time_start:null,
    time_end:null,
    // tag_list: null,
    covid_tag: null,
    service_area:null,
    status: "Pending",
    remarks: "",
    admin_remarks: "",
    medical_condition: "",
    routing_status: "Pending",
    cart:null
  })
  /*** initial state for customer data ***/
  const [userData, setUserData] = useState({
    email: null,
    first_name: null,
    last_name:null,
    mobile_number: null
  })

  const bookingStatusOptions =  [
    { value:"Confirmed", label:"Booking Confirmed"}, 
    { value:"Pending",  label:"Payment Pending"},
    { value:"Cancelled", label:"Booking Cancelled"}, 
    { value:"En Route",  label:"Phlebotomist En Route"},
    { value:"Arrived", label:"Phlebotomist Arrived"},
    { value:"Started", label:"Appointment Started"}, 
    { value:"Done",  label:"Appointment Completed"},
    { value:"Specimen Turned over", label: "Specimen Delivered"},
    { value:"Results received", label: "Full Results Received"},
    { value:"Partial results received", label:"Partial Results Received"}
  ]
  const covidTagOptions = [
    {value:"No Known Exposure", label: "No Known Exposure"},
    {value:"Confirmed Negative", label: "Confirmed Negative"},
    {value:"Exposed but asymptomatic", label: "Exposed but asymptomatic"},
    {value:"Experiencing symptoms but not tested for COVID-19 yet",label: "Experiencing symptoms but not tested for COVID-19 yet"},
    {value:"Tested for COVID-19 but results are inconclusive",label: "Tested for COVID-19 but results are inconclusive"},
    {value:"Confirmed Positive",label: "Confirmed Positive"}
  ]
  const tagListOptions = [
    {value: "tag1", label: "tag1"},
    {value: "tag2", label: "tag2"},
    {value: "tag3", label: "tag3"},
  ]
  const billingOptions = [
    {value: "Patient", label: "Patient"},
    {value: "Clinic", label: "Clinic"}
  ]
  const [favorites, setFavorites] = useState([])
  const [favoriteIds, setFavoriteIds] = useState([])
  const [packages, setPackages] = useState([]);
  const [tests, setTests] = useState([]);
  const [showPackages, setShowPackages] = useState(true);
  const [isRtl, setIsRtl] = useState(false);
  const [isClearable, setIsClearable] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const [count, setCount] = useState(0);
  const [totalQuantity,setTotalQuantity]=useState(0);
  const [totalPrice,setTotalPrice] = useState(0);
  const [optionPackages, setOptionPackages] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("Pending");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [update, setUpdate] = useState(false)

  /*** watches the state in favorites ***/
  const [updateFav, setUpdateFave] = useState(false)

  const [checkedPatientTest, setCheckedPatientTest] = useState(false)

  const [emailSelected, setEmailSelected] = useState({
    email: '',
    id: null,
    first_name: '',
    last_name: '',
    mobile_number:''
  });
  const [selectOptions, setSelectOptions] = useState([])
  const [medtechs, setMedtechs] = useState([])
  const [currentPosition, setCurrentPosition] = useState({
    address:"",
    lat:0,
    lng:0
  });
  const schedule= [
    {slot:10, available:'', label: "-- Select Schedule -- ", startTime:'N/A'},
    {slot:0, available:'', label: "05:00am - 07:00am ", startTime:'05:00', endTime:'07:00'},
    {slot:1, available:'', label: "07:00am - 09:00am ", startTime:'07:00', endTime:'09:00'},
    {slot:2, available:'', label: "09:00am - 11:00am ", startTime:'09:00', endTime:'11:00'},
    {slot:3, available:'', label: "11:00am - 01:00pm ", startTime:'11:00', endTime:'13:00'},
    {slot:4, available:'', label: "01:00pm - 03:00pm ", startTime:'13:00', endTime:'15:00'}
  ]
  const [preftime, setPreftime] = useState()
  const [availmedtech,setAvailmedtech] = useState([])
  const current = new Date();
  const curentdate = `${current.getFullYear()}-0${current.getMonth()+1}-0${current.getDate()}`
  const [medtechSelected, setMedtechSelected] = useState()
  const [paymentData, setPaymentData] = useState({
    customer_id: null,
    booking_id: null,
    method: "Stripe",
    amount: 0,
    status: "Unpaid",
    billed_to: "Patient"
  })
  /*** request headers ***/
  const requestHeaders = {Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json"}
  const md_id = localStorage.admin_id

  const [page, setPage] = useState("");
  let day;
  let pref;
  let cust_id;

  const [billingOption, setBillingOption] = useState('Patient')
  const [billingSFOption, setBillingSFOption] = useState('Patient')
  const [newSched,setNewSched] = useState([])

  const [billingServiceFeeOption, setBillingServiceFeeOption] = useState(localStorage.getItem("default_service_fee_payment_option"))
  const [billingTestsFeeOption, setBillingTestsFeeOption] = useState(localStorage.getItem("default_tests_payment_option"))

  // const [billingServiceFeeOption, setBillingServiceFeeOption] = useState('Patient')
  // const [billingTestsFeeOption, setBillingTestsFeeOption] = useState('Patient')

  const [billingServiceFeePatient, setBillingServiceFeePatient] = useState(() => billingServiceFeeOption === 'Patient' ? 99 : 0)
  const [billingTestsFeePatient, setBillingTestsFeePatient] = useState(0)

  const [billingServiceFeeClinic, setBillingServiceFeeClinic] = useState(() => billingServiceFeeOption === 'Clinic' ? 99 : 0)
  const [billingTestsFeeClinic, setBillingTestsFeeClinic] = useState(0)


  /********** toggle test billing option handler **********/
  function handleToggleBilling() {
    if (billingOption == 'Patient') {
      setBillingOption('Clinic');
      setBillingTestsFeeOption('Clinic'); 
      setBillingTestsFeeClinic(gettotaltestsprice(cartItems)); 
      setBillingTestsFeePatient(0);
      console.log("Tests Fee billed to Clinic")
    } else {
      setBillingOption('Patient');
      setBillingTestsFeeOption('Patient'); 
      setBillingTestsFeeClinic(0); 
      setBillingTestsFeePatient(gettotaltestsprice(cartItems));
      console.log("Tests Fee billed to Patient")
    }
    // localStorage.setItem('default_tests_payment_option', billingTestsFeeOption)
  }

  /********** toggle service fee billing option handler **********/
  function handleToggleSFBilling() {
    if (billingSFOption == 'Patient') {
      setBillingSFOption('Clinic');
      setBillingServiceFeeOption('Clinic')
      setBillingServiceFeeClinic(99)
      setBillingServiceFeePatient(0)
      console.log("Service Fee billed to Clinic")
    } else {
      setBillingSFOption('Patient');
      setBillingServiceFeeOption('Patient');
      setBillingServiceFeeClinic(0);
      setBillingServiceFeePatient(99); 
      console.log("Service Fee billed to Patient")
    }
    // localStorage.setItem('default_service_fee_payment_option', billingServiceFeeOption)
  }
  

  /********** fetch customers, medtechs, packages and tests from database **********/
  useEffect(() => {
    getData()
    getProductsData()
  }, []);

  const getData =()=>{
    const patientsAPI = `${REACT_APP_MD_BASEURL}/doctor/${md_id}/patients`
    const medtechsAPI = `${REACT_APP_MD_BASEURL}/medtechs`
    const getPatients = axios.get(patientsAPI, { headers: requestHeaders })
    const getMedtechs = axios.get(medtechsAPI, { headers: requestHeaders })
  
    axios.all([getPatients, getMedtechs])
    .then(axios.spread((...allData)=> {
      const allPatients = allData[0].data?.data?.patients
      const allMedtechs = allData[1].data?.data?.medtechs;
      setCustomers(allPatients)
      let options = allPatients?.map(function(d) {
        return { 
          value: d.id,
          label: d.last_name + `, ` + d.first_name + ` (` + d.email + `)`,
          first_name: d.first_name,
          last_name: d.last_name,
          mobile_number: d.mobile_number,
          email: d.email
        };
      })
      setSelectOptions(options)
      setMedtechs(allMedtechs)
    }))
    .catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message[0])
      }
    })
  }

  const getProductsData =()=>{
   
    const testsAPI = REACT_APP_TESTS
    const packagesAPI = `${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/packages/all`
    const favoritesAPI = `${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/favorites`
   
    const getTests = axios.get(testsAPI, { headers: requestHeaders })
    const getPackages = axios.get(packagesAPI, { headers: requestHeaders })
    const getFavorites = axios.get(favoritesAPI, { headers: requestHeaders })

    axios.all([getTests, getPackages, getFavorites])
    .then(axios.spread((...allData)=> {
      const allTests = allData[0].data?.data?.tests;
      const allPackages = allData[1].data?.data?.packages;
      const allFavorites = allData[2].data?.data?.favorites;
      const allFavoriteIds = allFavorites.map(favorite => [favorite.type, favorite.id]);
      
      setTests(allTests)
      setPackages(allPackages)
      setFavorites(allFavorites)
      setFavoriteIds(allFavoriteIds)
    }))
    .catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message[0])
      }
    })
  }

  // re-render/auto-update list when an item is removed or added to faves
  useEffect(() => {
    getProductsData();
    console.log("update fav is working")
  }, [updateFav]);


  /********** check medtech availability **********/ 
  const getavailmedtech = () => {
    setAvailmedtech()
    let av = []
    let ns=[]
    ns.push(schedule[0])
    if (day || pref) {
      medtechs.map((x) => {

        if (x?.availability_slot) {
          let avail = x?.availability_slot

          Object.entries(avail).map((datekey) => {
            // console.log(datekey)
            let d = datekey[0]
            let s = datekey[1]
            if (d == day) {
              Object.keys(s).map((slots) => {
                if(s[slots]) {
                  let index = schedule.find(item => item.slot == slots)
                  let nodup = ns.find(item => item.slot == slots)
                  av.push(x)
                  
                  if(!nodup){
                    ns.push(index)
                  }
                }
              })
            }
          })
        }
      })
    }
    setNewSched(ns)
    setAvailmedtech(av[0])
    setFormData(prevState => ({
      ...prevState,
      medtech_id: availmedtech?.id}))
  }


  /********** Time preference setter **********/ 
  const makehour = (start) => {
    day = formData?.date
    pref = schedule.find(obj => {
      return obj.startTime == start
    })
    setPreftime(pref)
    setFormData(prevState => ({
      ...prevState,
      time_start: pref.startTime, time_end: pref.endTime}))
    getavailmedtech()
  }
  
  /********** Date preference setter to payload and check available medtech **********/ 
  const getDate = (datet) => {
    day = datet
    pref = preftime
   
    setFormData(prevState => ({
      ...prevState,
      date: day}))
    getavailmedtech()
  }

  /********** Find details of default medtech **********/
  const medtechdetails =()=> {
    const medtech = medtechs?.find(obj => { //or getAvailMedtechs?
      if(obj.name === "admin_medtech"){
        return obj
      }
  })

  setMedtechSelected(medtech)
  // console.log(medtech)
  return medtech
  }
  // console.log(medtechSelected)

  /********** Setter for selected customer on email search **********/ 
  const selectCustomer =(e)=>{
    if (!e) {
       setEmailSelected({id:' ',email:' ',first_name:' ',last_name:' ', mobile_number: ' ' });
    }else {
      setEmailSelected({id:e.value,label:e.label,first_name:e.first_name,last_name:e.last_name, mobile_number: e.mobile_number,email: e.email})
    }
  }

  useEffect(() => {
    if(emailSelected.email==' '){ 
      setEmailSelected({id:'',email:'',first_name:'',last_name:'', mobile_number: '' });
      setUserData()
    }
  }, [emailSelected])


  /********** LOCATION SETTER **********/ 
  const getLocation = coords => {
    // console.log(coords);
    setCurrentPosition(coords);
  };

  /********** toast when test is added **********/ 
  useEffect(() => {
    const abortCont = new AbortController()
    abortCont.abort()
  }, [isClearable])

  /********** Add to cart functionality **********/ 
  const onAdd = (product) => {
    // console.log("Added product: ", product)
    setTotalQuantity(totalQuantity + 1)
    const exist = cartItems?.find((x) => x.name === product.name);
    if(exist) {
      setCartItems(
        cartItems?.map((x) => 
          x.name === product.name ? { ...exist, qty: exist.qty + 1 } : x
        )
      )
      
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }]);
    }
    // setBillingTestsFeePatient(gettotaltestsprice(cartItems))

    console.log(gettotaltestsprice(cartItems))
    toast.info("Item has been added to your cart")
    // console.log(cartItems)
  return cartItems
  }
  useEffect(() => {
    if (billingTestsFeeOption === "Clinic") {
      setBillingTestsFeeClinic(gettotaltestsprice(cartItems))
    } else {
      setBillingTestsFeePatient(gettotaltestsprice(cartItems))
    }    
  }, [cartItems, billingTestsFeeOption])
  
  /********** Remove to cart functionality **********/ 
  const onRemove = (product) => {
    if(totalQuantity !== 0) {
      setTotalQuantity(totalQuantity - 1)
    }

  const exist = cartItems.find((x) => x.name === product.name)
    if(exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.name !== product.name));
    } else {
      setCartItems(
        cartItems?.map((x) =>
          x.name === product.name ? { ...exist, qty: exist.qty - 1 } : x
        )
      );
    }

  return cartItems
  }

  /********** convert back to Test#ID:QTY payload format **********/ 
  const formatcart=(c)=> {
    let list1={}
    let list2={}

    if (c){
        c.forEach(function (item) {
          if(item.type){
            let a = item?.type+'#'+item?.id
            var b = item?.qty;
            list1={[a]:b}

            list2 = {
                ...list2,
                ...list1
            }
          }else{
            let a = "Package#"+item?.id
            var b = item?.qty
            list1={[a]:b}

            list2 = {
                ...list2,
                ...list1
            }

          }
        })
  }

  return list2
  }

  /********** Get cart total price **********/ 
  const gettotalprice =(cartItems)=>{
    const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);
    // const taxPrice = itemsPrice * 0.14;
    // const shippingPrice = itemsPrice > 2000 ? 0 : 20;
    const discountFee = itemsPrice * 0
    const serviceFee = 99
    // const serviceFee = 500
    // const totalPrice = itemsPrice + taxPrice + shippingPrice;
    const totalPrice = (itemsPrice + serviceFee)-discountFee;

    return totalPrice
  }

  const gettotaltestsprice =(cartItems)=>{
    const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);

    return itemsPrice
  }

  /********** handling form input data by taking onchange value and updating our previous form data state **********/
  const handleInputData = input => e => {
    // input value from the form
    const { value } = e.target;

    //update data state taking previous state and then adding new value to create new object
    setUserData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }

  /********** React-Select Styles **********/
  const style = {
    control: (base) => ({
      ...base,
      borderRadius: "15px"
    })
  }

  const customStyles = {
    control: base => ({
        ...base,
        minHeight: 30
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 4
    }),
    clearIndicator: base => ({
        ...base,
        padding: 4
    }),
    multiValue: base => ({
        ...base
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 5px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
  };

  /********** CREATE/POST CUSTOMER API REQUEST **********/
  const handleRegisterPx = (e) => {
    e.preventDefault()
    // console.log(formData)
    const customerData = {
      customer: {   
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        mobile_number: userData.mobile_number,
        password: `${userData?.first_name?.split(' ').join('').toLowerCase().slice(0, 3)}LAH#2022`
      }
    };

      // console.log(customerData.customer)   
      setLoading(true)
      axios({
        method: "POST",
        url:`${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/customers`,
        data: customerData,
        headers: requestHeaders 
      })
      .then((data) => {
        // setLoading(false)
        if (data.status === 200) {
          toast.success("Successfully created a user")
          // console.log(data?.data?.data?.patient?.id)
          // setEmailSelected({id:data?.data?.data?.patient?.id})
          handleAddBooking(e,data?.data?.data?.patient?.id)
          navigate("/md/bookings")
        }
      })
      .catch(function (error) {
        // setLoading(false)
        if (error.response) {
          toast.error(error.response.data.message[0])
        }
      })
      .finally(() => setLoading(false));
  }

  /********** ADD/POST BOOKING **********/ 
  const handleAddBooking = (e,id) =>{
    e.preventDefault()

    // let cust_id;
    
    if(emailSelected?.id){
      cust_id = emailSelected.id
    }
    else {
      cust_id = id
    }

    const bookingData = {
      booking: {
        customer_id: cust_id,
        medtech_id: formData?.medtech_id ? formData?.medtech_id : getRandomData(medtechs)?.id,
        location: currentPosition ? currentPosition?.address : null,
        date: formData?.date ? formData?.date : null,
        time_start: formData?.time_start ? createdatetoISO(formData?.time_start) : null,   
        time_end: formData?.time_end ? createdatetoISO(formData?.time_end) : null,
        longitude: currentPosition?.lng,
        latitude: currentPosition?.lat,  
        cart: cartItems ? formatcart(cartItems) : null,
        routing_status: formData?.routing_status,
        status: formData?.status
      }
    }
    console.log(cust_id)
    if(cartItems?.length !== 0 && cust_id){
      setLoading(true)
      axios({
        method: "POST",
        url: `${REACT_APP_MD_BASEURL}/doctor/${md_id}/bookings`,
        data: bookingData,
        // data: formData,
        headers: requestHeaders,
      })
      .then((data) => {
        // setLoading(false)
        // console.log(data)
        if (data.status === 200) {
          let newBooking = data?.data?.data?.booking
          let newbooking_id = newBooking?.id
          let customer_id = newBooking?.customer_id 
          // console.log(newBooking?.id)
          postPayment(customer_id,newbooking_id, newBooking)
          navigate("/md/bookings")
        }else {
          toast.error("Please check required fields")
        }
      })
      .catch(function (error) {
        if (error.response) {
          // setLoading(false)
          toast.error(error?.response?.data?.message[0])
        }
      })
      .finally(() => setLoading(false));
    }else{
      if(cartItems?.length == 0){
        toast.warning("Cart should not be empty!")
      }else{
        toast.warning("Check user details")
      }
    }
      // console.log(formData)
  }

  /********** CREATE/POST PAYMENT API REQUEST **********/
  const postPayment =(customer_id, booking_id, newBooking)=> { 

    
    // ##################

    // Service Fee Payment
    const serviceFeePaymentData = {
      payment: {
        customer_id: customer_id,
        booking_id: booking_id,
        method: "Stripe", 
        amount: 99,
        status: "Unpaid",
        billed_to: billingServiceFeeOption,
      }
    }

      axios({
        method: "POST",
        url: `${ REACT_APP_MD_MAINUS_BASEURL }/service_fee_payments`,
        data: serviceFeePaymentData,
        headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },  
      })
      .then((data) => {
        if(data.status === 200) {
          setLoading(false)
          setUpdate(!update)
          if (billingServiceFeeOption === "Clinic" && billingTestsFeeOption === "Patient") window.open(`https://www.labathome.us/payment-link?id=${data?.data?.data?.payment?.id}`)
        }
      })
      // .catch(error => toast.error(error));
      .catch(function (error) {
        if (error.response) {
          setLoading(false)
          toast.error(error.response.data.message[0])
        }
      })
    
    // Tests Fee Payment
    const testsFeePaymentData = {
      payment: {
        customer_id: customer_id,
        booking_id: booking_id,
        method: "Stripe", 
        amount: gettotaltestsprice(cartItems),
        status: "Unpaid",
        billed_to: billingTestsFeeOption,
      }
    }


    axios({
      method: "POST",
      url: `${ REACT_APP_MD_MAINUS_BASEURL }/tests_payments`,
      data: testsFeePaymentData,
      headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },  
    })
    .then((data) => {
      if(data.status === 200) {
        setLoading(false)
        setUpdate(!update)
        if (billingServiceFeeOption === "Patient" && billingTestsFeeOption === "Clinic") window.open(`https://www.labathome.us/payment-link?id=${data?.data?.data?.payment?.id}`)
      }
    })
    // .catch(error => toast.error(error));
    .catch(function (error) {
      if (error.response) {
        setLoading(false)
        toast.error(error.response.data.message[0])
      }
    })

    const paymentData = {
      payment: {
        customer_id: customer_id,
        booking_id: booking_id,
        method: "Stripe", 
        amount: gettotalprice(cartItems),
        status: "Unpaid",
        billed_to: billingOption,
      }
    }


    setLoading(true)


    axios({
        method: "POST",
        url: `${ REACT_APP_MD_MAINUS_BASEURL }/payments`,
        data: paymentData,
        headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },  
    })
    .then((data) => {
      if(data.status === 200) {
        setLoading(false)
        toast.success("Successfully created booking!")
        setUpdate(!update)
        if (billingServiceFeeOption === "Clinic" && billingTestsFeeOption === "Clinic") window.open(`https://www.labathome.us/payment-link?id=${data?.data?.data?.payment?.id}`)
      }
    })
    // .catch(error => toast.error(error));
    .catch(function (error) {
      if (error.response) {
        setLoading(false)
        toast.error(error.response.data.message[0])
      }
    })

    // Change default payment settings
    const paymentSettingsData = {
      doctor: {
        doctor_id: md_id,
        default_service_fee_payment_option: billingServiceFeeOption,
        default_tests_payment_option: billingTestsFeeOption, 
      }
    }

    axios({
      method: "POST",
      url: `${ REACT_APP_MD_MAINUS_BASEURL }/doctor/${md_id}/doctors/update_payment_setting`,
      data: paymentSettingsData,
      headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },  
    })
    .then((data) => {
      if(data.status === 200) {
        // setLoading(false)
        // toast.success("Successfully created booking!")
        // setUpdate(!update)
        console.log("success update payment settings")
      }
    })
    // .catch(error => toast.error(error));
    .catch(function (error) {
      if (error.response) {
        setLoading(false)
        toast.error(error.response.data.message[0])
      }
    })

  }

  
  const handleSubmit =(e)=>{
    e.preventDefault()

    if( emailSelected?.id  ){
      handleAddBooking(e)
    }
    else{
      if(cartItems?.length !== 0){
        handleRegisterPx(e)
      }else{
        toast.warning("Cart should not be empty!")
      }
    }
  }

  const clearr=()=>{
    // console.log("cleared")
    setEmailSelected({
      email: '',
      id: null,
      first_name: '',
      last_name: '',
      mobile_number:''})
  }


  // Update local storage when payment option changes
  useEffect(() => {
    localStorage.setItem('default_service_fee_payment_option', billingServiceFeeOption)
    localStorage.setItem('default_tests_payment_option', billingTestsFeeOption)
    if (billingServiceFeeOption === "Clinic" && billingTestsFeeOption === "Clinic") {
      setBillingOption("Clinic")
    } else {
      setBillingOption("Patient")
    }
    
  }, [billingServiceFeeOption, billingTestsFeeOption])


  return ( 
    <>

    <Layout>
    <form className="p-2">
      
      <div className="mx-3 my-5">
        <div className="accordion" id="accordionPanelsStayOpenExample">



          {/*********** PATIENT INFORMATIO
           * ]  N ***********/}         
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                Patient Information
              </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
              <div className="accordion-body">

              <div className="row d-flex align-items-center mb-3">
                  <div className="col">
                    <label>Search Patient</label>
                    <div className="form-group">
                        <Select
                          key={selectOptions?.value}
                          name={selectOptions?.label} 
                          options={selectOptions} 
                          onChange={(e)=>selectCustomer(e)} 
                          isClearable={isClearable}
                          isRtl={isRtl}
                          noOptionsMessage={ ()=> loading ? 
                              <>
                                <span>Loading patients...</span>
                              </>
                              : <span>"No Patients Found"</span> 
                            }
                          loading={loading}
                          styles={customStyles}
                          placeholder={<div><small>Select Existing Patient</small></div>}
                        />
                    </div> 
                  </div>
                </div>
                

                <div className="row mb-3">

                  <div className="col-sm-12 mb-2">
                    <label>or Add a New Patient</label>
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="floatingInput" className='required'>Last Name</label>
                    <input type="text" value={ emailSelected?.last_name || userData?.last_name } onChange={ handleInputData("last_name") }  className="form-control rounded-1" id="floatingInput" required/>
                  </div>
                  <div className="form-group col-sm-6"> 
                    <label htmlFor="floatingInput" className='required'>First Name</label>
                    {/* <input type="text" value={ emailSelected?.first_name || formData?.first_name } onChange={ (e)=> setFormData({ first_name: e.target.value }) } className="form-control rounded-1" id="floatingInput" required/> */}
                    <input type="text" value={ emailSelected?.first_name || userData?.first_name } onChange={ handleInputData("first_name") } className="form-control rounded-1" id="floatingInput" required/>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="form-group col-sm-6 mb-3">
                    <label htmlFor="floatingInput" className='required'>Email</label>
                    <input type="email" value={ emailSelected?.email || userData?.email } onChange={ handleInputData("email") } className="form-control rounded-1" id="floatingInput" required/>
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="floatingInput" className='required'>Mobile Number (10-digit) </label> <br/>
                    <input type="text" pattern="[1-9]{1}[0-9]{9}" maxlength="10" value={ emailSelected?.mobile_number || userData?.mobile_number } 
                      onChange = {
                        (e)=> {
                        const regEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
                        
                        if (regEx.test(e.target.value)) {
                          // setUserData(prevState => ({
                          //   ...prevState,
                          //   mobile_number: e?.target?.value
                          // }));
                          handleInputData("mobile_number")
                        }
                        // handleInputData("mobile_number")
                      }
                      } 
                      className="form-control rounded-1" 
                      id="floatingInput" 
                      required
                    />
                  </div> 
                </div>

              
                <div className="row d-flex align-items-center mb-3">
                  <div className="form-group col">
                    <input value={emailSelected?.id}  type="hidden" className="form-control rounded-1" id="floatingInput" required/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br/>
          {/*********** PRODUCT SELECTION ***********/}
          <div className="accordion-item">
            <h2 className="accordion-header collapsed" id="panelsStayOpen-headingTwo">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                Select Tests/Packages
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
              <div className="accordion-body">
                <div className="row"> 
              
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    {/* <p className="h6">AVAILABLE TESTS/PACKAGES:</p> */}
                    {/* <button type="button" onClick={()=>setShowPackages((s)=>!s) } className="btn lah_gradient shadow-none border-0 text-white cta-action px-3"> {totalQuantity} tests/packages selected</button>    */}
                    <button title='Click to view available tests and/or packages' type="button" onClick={()=>setShowPackages((s)=>!s) } className="btn lah_gradient shadow-none border-0 text-white cta-action px-3"> {totalQuantity} tests/packages selected</button> 
                  </div>
                  
                  <div className="col-sm-12 scroll">
                    <div style={{ display:showPackages ? "none" : "block" }} className="card"> 
                      {/* <div className="row px-5 my-3 d-flex justify-content-center align-items-center w-100">  
                        <input className="form-control rounded-5 mb-2 w-25" id="tableSearch" type="text" placeholder="Search tests/packages..." onChange={(e) => setSearchTerm(e.target.value)}/>
                      </div> */}

                      <div className="p-5">
                        <nav>
                          <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                            <button className="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-favorites" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><i class='bx bxs-hot h6'></i> Favorites</button>
                            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-packages" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><i class='bx bxs-package h6' ></i> Packages</button>
                            <button className="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-tests" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><i class='bx bx-test-tube h6' ></i> Tests</button>
                          </div>
                          
                          <div className='d-flex w-100 justify-content-between align-items-center align-content-center bg-red'>
                            <span onClick={() => navigate('/md/package/add')}  className="mx-2 btn lah_gradient text-white border-0  fw-bold">
                              <div className='d-flex justify-content-center align-items-center cta-action'>
                                <i className='bx bx-plus-medical'></i>
                                <span className='m-1'>Create Custom Package</span>
                              </div>
                            </span>
                            <input className="form-control w-25 m-2 rounded-2" id="tableSearch" type="text" placeholder="Search tests/packages..." onChange={(e) => setSearchTerm(e.target.value)} />
                          </div>
                        </nav>
                        <div className="tab-content p-3 border bg-light" id="nav-tabContent">
                          <div className="tab-pane fade" id="nav-favorites" role="tabpanel" aria-labelledby="nav-home-tab">
                            <Favorites setUpdateFave={setUpdateFave} updateFav={updateFav} products={favorites} onAdd={onAdd} searchTerm={searchTerm} favorites={favorites} setFavorites={setFavorites} favoriteIds={favoriteIds} setFavoriteIds={setFavoriteIds} />
                          </div>
                          <div className="tab-pane fade " id="nav-packages" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <MainPage setUpdateFave={setUpdateFave} updateFav={updateFav} products={packages} onAdd={onAdd} searchTerm={searchTerm} favorites={favorites} setFavorites={setFavorites} favoriteIds={favoriteIds} setFavoriteIds={setFavoriteIds} />
                          </div>
                          <div class="tab-pane fade active show" id="nav-tests" role="tabpanel" aria-labelledby="nav-contact-tab">
                            <TestsPage setUpdateFave={setUpdateFave} updateFav={updateFav} products={tests} onAdd={onAdd} searchTerm={searchTerm} favorites={favorites} setFavorites={setFavorites} favoriteIds={favoriteIds} setFavoriteIds={setFavoriteIds} /> 
                          </div>
                      </div>
                      </div>
                    </div>
                    <Basket
                      totalQuantity={totalQuantity}
                      setTotalQuantity={setTotalQuantity}
                      cartItems={cartItems}
                      onAdd={onAdd}
                      onRemove={onRemove}
                    />
                  </div>
                 </div>
              </div>
            </div>
          </div>

          <br/>
          {/*********** APPOINTMENT SCHEDULE ***********/}
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                Appointment Scheduling (<strong>Optional</strong>)
              </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
              <div className="accordion-body">
              <div className="row">
                <div className="col h6">Select Schedule</div>
                <hr/>
              </div>

              <label htmlFor="floatingInput">Input Patient Appointment Location</label>
                <div className="col bg-white rounded-1 d-flex flex-column justify-content-center align-items-center" >
                    <MapContainer google={this?.google} getLocation={getLocation} currentPosition={currentPosition} setCurrentPosition={setCurrentPosition} />
                </div>

                <div className="row mt-4">
                  <div className="form-floating">
                      <input disabled type="hidden" className="form-control rounded-1" id="floatingInput" 
                          value={currentPosition?.address} 
                          // onChange={(e)=>setLocation(e.target.value)}
                          onChange={(event) =>setCurrentPosition({ ...currentPosition, address: event.target.value})}
                          // onChange={(event) =>setCurrentPosition({ ...currentPosition, address: event.target.value})}
                          />
                      {/* <label className="px-4 required" htmlFor="floatingInput">Address</label> */}
                  </div>
                </div>

                <div className="row">
                    <div className="form-floating col-6">
                        <input 
                            disabled
                            type="hidden" 
                            className="form-control rounded-1" 
                            id="floatingInput" 
                            // value={currentPosition?.lat} 
                            // onChange={(e)=>setlat(e.target.value)}
                            value={currentPosition?.lat} 
                            onChange={(event) =>setCurrentPosition({ ...currentPosition, lat: Number(event.target.value)})}
                            // onChange={(event) =>setCurrentPosition({ ...currentPosition, lat: Number(event.target.value)})}
                        />
                        {/* <label className="px-4" htmlFor="floatingInput">Latitude</label> */}
                    </div>
                    <div className="form-floating col-6">
                        <input disabled
                            type="hidden" 
                            className="form-control rounded-1" 
                            id="floatingInput"
                            value={currentPosition?.lng} 
                            onChange={(event) =>setCurrentPosition({ ...currentPosition, lng: Number(event.target.value)})}/>
                            {/* // value={currentPosition?.lng} 
                            // onChange={(e)=>setlng(e.target.value)}
                            // onChange={(event) =>setCurrentPosition({ ...currentPosition, lng: Number(event.target.value)})} */}
                        {/* /> */}
                        {/* <label className="px-4" htmlFor="floatingInput">Longitude</label> */}
                    </div>
                </div>

                <div className="row mb-4">
                  <div className="form-group col">
                    <label htmlFor="floatingInput">Date</label>
                    <input
                      value={formData?.date}
                      onChange={e=> getDate(e.target.value)}
                      type="date"
                      className="form-control rounded-1"
                      id="floatingInput"
                      min={disablePastDate()}
                    /> 
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="floatingInput">Preferred Schedule</label>
                    <select
                        // value={formData?.time_start}
                        value={formData?.start_time}
                        className="form-control form-select rounded-1"
                        onChange={e => makehour(e.target.value)}>
                        {newSched?.map((option) =>{
                          if(getCurrentTime()< option.startTime){
                            return <option value={option.startTime}>{option.label}</option>
                          }else if (formData?.date != curentdate){
                            return <option value={option.startTime}>{option.label}</option>
                          }
                          // else if (date != curentdate) {
                          //   return <option value={option.startTime}>{option.label}</option>
                          // }
                          })}
                    </select>  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>

          {/*********** BILLING INFORMATION ***********/}         
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingFour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                Billing Option
              </button>
            </h2>

            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour">
              <div className="accordion-body">
              <h4 className="pt-2">Tests (<span className="fw-bold">${gettotaltestsprice(cartItems)}</span>)</h4>
                {/* <div className="row px-3 justify-content-start">
                  <div class="form-check">
                    <input type="radio" class="form-check-input" id="send-bill-1" name="optradiotests" checked={billingTestsFeeOption === 'Patient'} onClick={() => {setBillingTestsFeeOption('Patient'); setBillingTestsFeeClinic(0); setBillingTestsFeePatient(gettotaltestsprice(cartItems));}} />
                      Bill to Patient
                    <label class="form-check-label" for="radio1"></label>
                  </div>
                  <div class="form-check">
                    <input type="radio" class="form-check-input" id="send-bill-2" name="optradiotests" checked={billingTestsFeeOption === 'Clinic'}  onClick={() => {setBillingTestsFeeOption('Clinic'); setBillingTestsFeeClinic(gettotaltestsprice(cartItems)); setBillingTestsFeePatient(0);}} />Bill to MD/Clinic
                    <label class="form-check-label" for="radio2"></label>
                  </div>
                </div> */}
                  {/* <p className='text-muted'>billed to: { billingOption }</p> */}
                  <div className="col-sm-12 col-md-8 col-lg-4 col-xl-4">
                    <div id="bill-toggle-switch" className="form-check form-switch d-flex justify-content-between align-items-center w-75 m-0 p-0">
                      <label className="form-check-label h5" htmlFor="flexSwitchCheckChecked">Bill to Patient</label>
                      <input 
                        className="form-check-input mx-2 custom-switch-xl m-0" 
                        type="checkbox" 
                        id={ billingOption === "Patient" ? "send-bill-1" : "send-bill-2" } 
                        checked={ billingTestsFeeOption === 'Clinic' }
                        onChange={ handleToggleBilling } 
                        style={ {transform: "scale(1.25)"} }
                      />
                      <label className="form-check-label h5" htmlFor="flexSwitchCheckChecked">Bill to MD/Clinic</label>
                    </div>
                  </div>
                <br/>

                <h4 className="pt-2">Service Fee (<span className="fw-bold">${99}</span>)</h4>
                {/* <div className="row px-3">
                  <div class="form-check">
                    <input type="radio" class="form-check-input" id="send-bill-3" name="optradioservice" checked={billingServiceFeeOption === 'Patient'}  onClick={() => {setBillingServiceFeeOption('Patient'); setBillingServiceFeeClinic(0); setBillingServiceFeePatient(99); }} />Bill to Patient
                    <label class="form-check-label" for="radio3"></label>
                  </div>
                  <div class="form-check">
                    <input type="radio" class="form-check-input" id="send-bill-4" name="optradioservice" checked={billingServiceFeeOption === 'Clinic'}  onClick={() => {setBillingServiceFeeOption('Clinic'); setBillingServiceFeeClinic(99); setBillingServiceFeePatient(0);}} />Bill to MD/Clinic
                    <label class="form-check-label" for="radio4"></label>
                  </div>
                </div> */}

                {/* <p className='text-muted'>billed to: { billingSFOption }</p> */}
                <div className="col-sm-12 col-md-8 col-lg-4 col-xl-4">
                  <div id="bill-toggle-switch" className="form-check form-switch d-flex justify-content-between align-items-center w-75 m-0 p-0">
                    <label className="form-check-label h5" htmlFor="flexSwitchCheckChecked">Bill to Patient  </label>
                    <input 
                      className="form-check-input mx-2 custom-switch-xl m-0" 
                      type="checkbox" 
                      id={ billingSFOption === "Patient" ? "send-bill-1" : "send-bill-2" } 
                      checked={ billingServiceFeeOption === 'Clinic' }
                      onChange={ handleToggleSFBilling } 
                      style={ {transform: "scale(1.25)"} }
                    />
                    <label className="form-check-label h5" htmlFor="flexSwitchCheckChecked">  Bill to MD/Clinic</label>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <br/>

          {/*********** BILLING SUMMARY ***********/}         
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingFive">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                Billing Summary
              </button>
            </h2>
            <br/>
            <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFive">
              <h4 className='px-3 pb-1'>Total For Patient: <span className='fw-bold'>${billingServiceFeePatient + billingTestsFeePatient}</span></h4>
              <h4 className='px-3 pb-4'>Total For Clinic: <span className="fw-bold">${billingServiceFeeClinic + billingTestsFeeClinic}</span></h4>
            </div>
            
          </div>

          

          <div className="d-flex justify-content-center align-items-center w-100 mt-4">
            <button onClick={ (e)=> handleSubmit(e) } className="btn lah_gradient text-white border-0 px-3 px-sm-2 mx-2 mx-sm-1 w-25">
              <div className='d-flex justify-content-center align-items-center p-2 p-sm-1'>
                  <span>Submit</span>
              </div>
            </button>
          </div>
        </div>
      </div>



      </form>
    </Layout>


    

    </>
      
  );
};


export default BookOutOfPocket