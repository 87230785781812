import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { getTime, getNewTime, createdatetoISO, getTimetime, handleCloseModal, getCurrentTime, disablePastDate} from '../../utils/helpers';
import { toast } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import avatar from '../../assets/avatar.png'
import personal_info from '../../assets/personal_info.svg'



const ShowPatientModal = ( { patient, showToggle, setShowToggle, update, setUpdate } ) => {
    const { id, email, first_name, last_name, age, birthday, mobile_number, address, gender } = patient;
    const [toggle, setToggle] = useState(false)
    const [pxBookings, setPxBookings] = useState([])
    
    useEffect(()=>{
        setPxBookings(patient?.bookings)
    },[patient])
    
    // const togglePx = (data)=>{
    //     setShowToggle(true)
    // }

    return (
        <div class="modal fade" id="ShowPatientModal"  role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content border-0">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle"></h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                        <div class="container py-2 h-100 w-100">
                            <div class="row d-flex justify-content-center align-items-center h-100">
                            <div class="col">
                                <div class="card mb-3">
                                <div class="row g-0">
                                    <div class="col-md-4 gradient-custom text-center text-white">
                                    <img src={personal_info} alt="Avatar" class="img-fluid px-2 my-5" style={{ "width": "250px" }}/>
                                 
                                    </div>
                                    <div class="col-md-8">
                                    <div class="card-body p-4">
                                        <h4>Patient Information</h4>
                                        <hr class="mb-2"/>
                                        <div class="row pt-1">
                                        <div class="col-6">
                                            <h6>Name</h6>
                                            <p class="text-muted">{first_name} {last_name}</p>
                                        </div>
                                        <div class="col-6">
                                            <h6>Email</h6>
                                            <p class="text-muted">{ email }</p>
                                        </div>
                                        <div class="col-6">
                                            <h6>Phone</h6>
                                            <p class="text-muted">{ mobile_number || "N/A" }</p>
                                        </div>
                                        <div class="col-6">
                                            <h6>Address</h6>
                                            <p class="text-muted">{ address || "N/A" }</p>
                                        </div>
                                        </div>
                                        
                                        <div class="row pt-1">
                                        
                                        <div class="col-6">
                                            <h6>Birthday</h6>
                                            <p class="text-muted">{birthday || "N/A" }</p>
                                        </div>
                                        <div class="col-6">
                                            <h6>Age</h6>
                                            <p class="text-muted">{age || "N/A" }</p>
                                        </div>
                                        <div class="col-6">
                                            <h6>Gender</h6>
                                            <p class="text-muted">{ gender || "N/A" }</p>
                                        </div>
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        
                        { pxBookings?.length !== 0 
                        
                        ?  
                        <>
                        <span className="row p-4">
                            Recent Bookings
                        </span>  
                        <div className="row px-3">     
                        <div>
                                { pxBookings?.map((b, index) => (
                                <>
                                    <div key={`px-booking-${b?.code}-${index}`} className='card card-px-bookings p-3 mb-2'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex flex-column justify-content-start align-items-start'>
                                                <div><small className='fw-light text-secondary'>{getTimetime(b?.time_start)} - {getTimetime(b?.time_end)}</small></div> 
                                                <div>Booking #{b?.code}</div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-end align-items-end'>
                                                <div><small className='fw-light text-secondary'>{b.date}</small></div> 
                                                <div>{b.status}</div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                { b?.result ?

                                                <button className="bg-info text-white border-1" type="button">
                                                    <a href={b?.result?.file?.url}> View Results</a>
                                                </button>
                                                
                                                :
                                                <>
                                                
                                                <button className="bg-info text-white border-0" type="button">
                                                    No results to view
                                                </button>
                                                </>
                                                }
                                            </div>     
                                        </div>
                                    </div>
                                    </>
                                    ))}
                            </div>
                        </div>
                        </>
                       :
                        <span className='px-3'>
                            This patient has no bookings yet
                        </span>
                        }
                </div>
               
                </div>
            </div>
        </div>
        
      )
}

export default ShowPatientModal