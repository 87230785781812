import React from 'react'
import radioactive from '../../assets/radioactive.svg'
import logo from '../../assets/logo.png'
import trex from '../../assets/trex.png'
import { Navigate, useNavigate } from 'react-router-dom'


const PageNotFound = () => {
  const navigate = useNavigate()
  
  return (
    <div className='container d-flex flex-column justify-content-center align-items-center text-center'>
      <img src={logo} className="w-25 mb-2" />
        <h1 className='display-4 mb-4 oops'>Oops!</h1>
        <h5 className='h5'>404 — Page Not Found <img src={trex} className="trex"/></h5>
        <img src={radioactive} className="w-25"/>
        <p>The page you were looking for no longer exists. </p>
        <p onClick={() => navigate('md/home')} className="return-link" role="button">Return to the site's homepage.</p>
    </div>
  )
}

export default PageNotFound