import React from 'react';
import Product from './Product';

export default function MainPage(props){

  const {setUpdateFave,updateFav, products, onAdd, searchTerm, favorites, setFavorites, favoriteIds } = props;

  return (
    <>
      { products?.filter((val) => {
        if(searchTerm === ""){
          return val;
        }else if(
          val.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        ){
          return val 
        }
          
        }).map((product) => (
        <>
          <Product 
            key={`package-${product?.id}`} 
            setUpdateFave={setUpdateFave} 
            updateFav={updateFav} 
            product={product} 
            onAdd={onAdd} 
            searchTerm={searchTerm} 
            favorites={favorites} 
            setFavorites={setFavorites} 
            favoriteIds={favoriteIds}>
          </Product> 
      </>
      ))}
    </>
  );
}
