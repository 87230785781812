import React, { useContext } from 'react'
import { useNavigate } from 'react-router';
/* ----- Components ----- */
import PageTitle from './PageTitle';
import PageTitleClinic from './PageTitleClinic';
// import { AuthContext } from '../../App';
import { AuthContext } from '../../context/AuthContext';

const Header = ({ handleToggle, isActive, currentUser }) => {

 
  const { userState, userDispatch } = React.useContext(AuthContext) 
  const navigate= useNavigate()
  
  const handleLogout = () => {
    userDispatch({ type: "LOGOUT" });
    localStorage.clear()
    navigate('/')
  };

  return (
    <>
      <nav id="md-navbar" className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <i className={isActive ? 'bx bxs-chevrons-left primary-text fs-5 me-3 bx-flashing-hover text-secondary toggle-arrow' : 'bx bxs-chevrons-left primary-text fs-5 me-3 bx-flashing-hover text-secondary'} id="menu-toggle" onClick={handleToggle}></i>
            {userState?.role == "Doctor" ? <PageTitle /> : <PageTitleClinic /> }
          </div>
          <a className="nav-link dropdown-toggle nav-right-mobile" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-person-fill me-2"></i></a>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href="/md/update_password">Update Password</a></li>
            <li><a className="dropdown-item" onClick={handleLogout}>Logout</a></li>
          </ul> 
          
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-md-auto gap-2">
              <li className="nav-item dropdown rounded">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-person-fill me-2"></i><span className='gradient-text'>{userState?.user?.email}</span></a>
        
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li><a className="dropdown-item" href="/md/update_password">Update Password</a></li>
                  <li><a className="dropdown-item" onClick={handleLogout}>Logout</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header