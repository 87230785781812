import React, { useState, useEffect } from 'react';
import FavoriteButton from '../../../components/FavoriteButton';

export default function Labtests(props) {
  const { setUpdateFave,updateFav, product, onAdd, favoriteIds } = props;
  const checkIfFaveTest = JSON.stringify(favoriteIds)?.includes(JSON.stringify([product?.type, product?.id]))
  
  return (
    <>
    <div className="card my-2" key={`product - ${product.id}`}>
      <div className="card-body d-flex align-content-center align-items-center justify-content-between gap-2"> 
        <span className="form-check-label">{ product?.localization?.find(x => x.locale === "en").name || product.name} [${product.price}][{product.type}]</span>
        <div className='d-flex align-content-center align-items-center gap-2'>
          <FavoriteButton 
            setUpdateFave={setUpdateFave} 
            updateFav={updateFav} 
            favoritableProductId={product?.id} 
            checkIfFave={checkIfFaveTest} 
            favoriteIds={favoriteIds}
          />
          <span className="text-dark shadow-none border-0" onClick={(e) => onAdd(product,e)}><i className='bx bxs-cart-add cart-btn'></i></span>
        </div>
      </div>
    </div>
    </>
  );
}