/* ------------------- Hooks -------------------- */
import React, { useContext, useState, useRef, useEffect, useCallback } from 'react'
import { useNavigate, Navigate } from 'react-router'
import axios from 'axios'
import { toast } from 'react-toastify';
import admin_logo from '../../assets/logo_md.png'
import { AuthContext } from '../../context/AuthContext';
import ParticlesConfig from '../../utils/ParticlesConfig';


const LoginClinic = () => {
  const { userDispatch } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const userEmail = useRef(null);
  const userPassword = useRef(null);
  const { REACT_APP_MD_AUTH_BASEURL, REACT_APP_CLINIC_AUTH_BASEURL } = process.env;
  const [loading, setLoading] = useState(false)


  const handleLoginClinic = (e) => {
    e.preventDefault()

    const clinicData = {
      clinic: {
        email: userEmail?.current?.value,
        password: userPassword?.current?.value
      },
    }

    setLoading(true)
    axios({
      method: "POST",
      url: `${REACT_APP_CLINIC_AUTH_BASEURL}/login`,
      headers: { 'content-type': 'application/json' },
      data: clinicData
    })
      .then((data) => {
        setLoading(false)
        // console.log(data)
        toast.success("Successfully logged in");
        localStorage.setItem('token', data?.data?.token)
        localStorage.setItem('admin', data?.data?.data?.clinic?.email)
        localStorage.setItem('admin_id', data?.data?.data?.clinic?.id)
        localStorage.setItem('role', data?.data?.data?.clinic?.role)
        userDispatch({ type: 'LOGIN', payload: data })
        if (data?.data?.data?.clinic?.sign_in_count === 1) {
          navigate("/md/clinic/update_password")
          toast.info("Please update your password.")
          return
        }
        navigate("/md/clinic/home")
      })
      .catch((error) => {
        setLoading(false)
        if (error.response) {
          toast.error(error.response.data.error)
        }
      })
  };

  return (
    <>
      <div style={{ position: 'absolute' }}>
        <ParticlesConfig />
      </div>
      <div className="vh-100 bg-cyan">
        <div className="container h-100 py-5">
          <div className="row d-flex justify-content-center mt-5 h-100">
            <div className="col-sm col-md-8 col-lg-6 col-xl-5">
              <div className="card-login border-0 bg-cyan">
                <div className="card-body login p-5 text-center">
                  <div className="d-flex justify-content-center align-items-center py-5">
                    <img src={admin_logo} className="w-75 " />
                  </div>
                  {/* <h2 className='text-secondary font-lato'>Welcome to <br/> Lab at Home MD Portal</h2><br/> */}
                  <form onSubmit={handleLoginClinic} className="p-2 mt-3 text-center">
                    <h6 className="login-heading mb-4 fw-normal text-secondary text-greeting">Login your Clinic credentials to access your account.</h6>

                    <div className="input-group mb-3">
                      <span className="input-group-text secondary-color text-light">
                        <i className="bi bi-person"></i>
                      </span>
                      <input type="email" ref={userEmail} className="form-control" placeholder="Email" required />
                    </div>

                    <div className="input-group mb-3">
                      <span className="input-group-text secondary-color text-light">
                        <i className="bi bi-lock"></i>
                      </span>
                      <input type="password" ref={userPassword} className="form-control" placeholder="Password" required />
                    </div>

                    <div className="pt-1 mb-4">
                      <button className="cta" type="submit">Log In</button>
                    </div>

                  </form>
                  {/* <p className="fw-normal text-secondary">Don't have an account? <a href="/signup">Sign Up</a></p>      */}
                  <p className="fw-normal text-secondary"><a href="/md/clinic/forgot_password">Forgot your password?</a></p>     
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginClinic