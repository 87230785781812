import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "react-datepicker/dist/react-datepicker.css";


import App from './App';
import './index.css';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>  
        <App />  
    </BrowserRouter>
 </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
