import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ReturnPrev() {
  const navigate=useNavigate()
  
  return (
    <>
      <button onClick={() => navigate(-1)} className="mx-2 btn lah_gradient text-white border-0 align-self-end fw-bold">
        <div className='d-flex justify-content-center align-items-center cta-action'>
            <i className='bx bxs-chevrons-left mr-2'></i>
            <span className='m-1'>Back</span>
        </div>
      </button>
    </>
  )
}
