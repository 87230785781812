import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import MapContainer from '../../components/Map/GoogleMaps';
import { toast } from 'react-toastify';
import { getCustomersData, getMedtechsData } from '../../utils/API';
import { useDropzone } from 'react-dropzone'
import { useNavigate } from 'react-router-dom';
import { getCurrentTime, disablePastDate, createdatetoISO } from '../../utils/helpers';
import { Layout } from '../../components/Layout/Layout';
import Select from 'react-select'
import { getRandomData } from '../../utils/helpers';


const BookWithInsurance = () => {
  const navigate = useNavigate()
  const { REACT_APP_MD_BASEURL, REACT_APP_MD_MAINUS_BASEURL } = process.env;
  //state for steps
  const [step, setstep] = useState(1);
  

  //state for form data
  const [formData, setFormData] = useState({
    patient_email: null,
    date: null,
    start_time: null,
    end_time: null,
    location: null,
    remarks: null,
    status: "Pending",
    patient_first_name: null,
    patient_last_name: null,
    patient_gender: null,
    medtech_id: null,
    longitude:null,
    latitude:null,  
    patient_mobile_number: null,
    birthday: null,
    gender: null
  })

  const [userData, setUserData] = useState({
    email: null,
    first_name: null,
    last_name:null,
    mobile_number: null,
    birthday: null,
    gender: null
  })

  const [currentPosition, setCurrentPosition] = useState({
    address:"",
    lat:0,
    lng:0
  })

  const statusOptions =  [
    { value:"Confirmed", label:"Booking Confirmed"}, 
    { value:"Pending",  label:"Payment Pending"},
    { value:"Cancelled", label:"Booking Cancelled"}, 
    { value:"En Route",  label:"Phlebotomist En Route"},
    { value:"Arrived", label:"Phlebotomist Arrived"},
    { value:"Started", label:"Appointment Started"}, 
    { value:"Done",  label:"Appointment Completed"},
    { value:"Specimen Turned over", label: "Specimen Delivered"},
    { value:"Results received", label: "Full Results Received"},
    { value:"Partial results received", label:"Partial Results Received"}
  ]

  
  const [selectedGender, setSelectedGender] = useState("")
  const [medtechs, setMedtechs] = useState([])
  const [customers, setCustomers]= useState([])
  const [uploadCustomers, setUploadCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  // requisition forms
  const [selectedFile, setSelectedFile] = useState();
  const [responseArray, setResponseArray] = useState()
  const [getForms, setGetForms] = useState([])
  const [count, setCount] = useState(0)
  const [filesUploaded, setFilesUploaded] = useState()
  const [preview, setPreview] = useState()
  const [files, setFiles] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [nameFile, setNameFile] = useState('File format: *.pdf, *.jpeg and *.png , Max upload size: 50MB, Max files: 5');
  const [page, setPage] = useState("");


  const schedule = [
    {slot:10, available:'', label: "-- Select Schedule -- ", startTime:'N/A'},
    {slot:0, available:'', label: "05:00am - 07:00am ", startTime:'05:00', endTime:'07:00'},
    {slot:1, available:'', label: "07:00am - 09:00am ", startTime:'07:00', endTime:'09:00'},
    {slot:2, available:'', label: "09:00am - 11:00am ", startTime:'09:00', endTime:'11:00'},
    {slot:3, available:'', label: "11:00am - 01:00pm ", startTime:'11:00', endTime:'13:00'},
    {slot:4, available:'', label: "01:00pm - 03:00pm ", startTime:'13:00', endTime:'15:00'}
  ]
  const [newSched,setNewSched] = useState([])

  const [emailSelected, setEmailSelected] = useState({
    email: '',
    id: null,
    first_name: '',
    last_name: '',
    mobile_number:'',
    birthday: null,
    gender: ''
  });
  const [selectOptions, setSelectOptions] = useState([])
  const [isRtl, setIsRtl] = useState(false);
  const [isClearable, setIsClearable] = useState(true);

  let day;
  let pref;
  const [preftime, setPreftime] = useState()
  const [availmedtech,setAvailmedtech] = useState([])
  const current = new Date();
  const curentdate = `${current.getFullYear()}-0${current.getMonth()+1}-0${current.getDate()}`
  const [medtechSelected, setMedtechSelected] = useState()
  const requestHeaders = {Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json"}
  const md_id = localStorage.admin_id

  // const [billingOption, setBillingOption] = useState([
  //   { id: 1, name: 'Send Bill to Patient', isChecked: true },
  //   { id: 2, name: 'Send Bill to MD/Clinic', isChecked: false}
  // ])

  const [billingOption, setBillingOption] = useState('Patient')
  const [sendBillTo, setSendBillTo] = useState(localStorage.getItem("default_service_fee_payment_option_upload"))
  const [serviceFeePatientAmount, setServiceFeePatientAmount] = useState(() => sendBillTo === 'Patient' ? 99 : 0)
  const [serviceFeeClinicAmount, setServiceFeeClinicAmount] = useState(() => sendBillTo === 'Clinic' ? 99 : 0)


    const validateFields =()=>{
      if(formData?.patient_email && formData?.location && formData?.date && formData?.start_time && formData?.end_time) {
        return true
      } else {
        return false
      }
  }



   // fetch medtechs from database
   useEffect(()=>{  
    const abortCont = new AbortController();
    setLoading(true)
    async function fetchData(){
      const medtechsData = await getMedtechsData()
      // const customersData = await getCustomersData()
     
      // if(medtechsData || customersData) {
        setLoading(false)
        setMedtechs(medtechsData)
        // setCustomers(customersData)
        // const options = customersData?.map(d => ({
        //   "value" : d.id,
        //   "label" : d.last_name + `, ` + d.first_name + ` (` + d.email + `)`,
        //   "first_name": d.first_name,
        //   "last_name": d.last_name,
        //   "mobile_number": d.mobile_number,
        //   "email": d.email
        // }))
        // setSelectOptions(options)
      // } 
    }
    fetchData()
    return ()=> {
      abortCont.abort();
    }
  },[])

    
  useEffect(()=>{  
    const abortCont = new AbortController();

    axios
      .get(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/patients`, { headers: requestHeaders })
      .then(data => {
        let customersData = data?.data?.data?.patients
        setCustomers(customersData);
        let options = customersData?.map(function(d) {
          return { 
            value: d.id, 
            label: d.last_name + `, ` + d.first_name + ` (` + d.email + `)`,
            first_name: d.first_name,
            last_name: d.last_name,
            mobile_number: d.mobile_number,
            email: d.email,
            birthday: d.birthday,
            gender: d.gender
          };
        })
        setSelectOptions(options)
      })
      .catch(function (error) {
        if (error.response) {
          setLoading(false)
          toast.error(error.response.data.message[0])
        }
      })
      return ()=> {
        abortCont.abort();
      }
  },[])


  const getLocation = coords => {
    setCurrentPosition(coords);
  };
  
  const selectGender = e =>{  
    setSelectedGender(e.target.value)
  }

    //********** Setter for selected customer on email search **********/ 
  const selectCustomer =(e)=>{
    if (!e) {
      // console.log('clear')
      // setEmailSelected({id:null,email:null,first_name:null,last_name:null, mobile_number:null, email:null})
       setEmailSelected({id:' ',email:' ',first_name:' ',last_name:' ', mobile_number: ' ', birthday: ' ', gender: ' ' });
      // setEmailSelected({id:'',email:'',first_name:'',last_name:'', mobile_number: '', email: '' });
      // return;
    }else{
      // console.log(e)
      setEmailSelected({id:e.value,label:e.label,first_name:e.first_name,last_name:e.last_name, mobile_number: e.mobile_number,email: e.email, birthday: e.birthday, gender: e.gender})
    }
    // setFormData({customer_id:e.value,email:e.label,first_name:e.first_name,last_name:e.last_name , mobile_number: e.mobile_number, email: e.email})
    // console.log(emailSelected)
  }


  useEffect(() => {
    
    if(emailSelected.email==' '){
      
      setEmailSelected({id:'',email:'',first_name:'',last_name:'', mobile_number: '', birthday: '', gender: '' });
      setUserData()
    }
  }, [emailSelected])

   /********** check medtech availability **********/ 
   const getavailmedtech = () => {
    setAvailmedtech()
    let av = []
    let ns=[]
    ns.push(schedule[0])
    if (day || pref) {
      medtechs.map((x) => {

        if (x?.availability_slot) {
          let avail = x?.availability_slot
          // console.log(avail)

          Object.entries(avail).map((datekey) => {
            // console.log(datekey)
            let d = datekey[0]
            let s = datekey[1]
            if (d == day) {
              // console.log(x)
             
              // console.log(x.name,s)
              // console.log(s)
              // console.log(d)
              Object.keys(s).map((slots)=>{
               if(s[slots]){
                let index = schedule.find(item => item.slot == slots)
                let nodup = ns.find(item => item.slot == slots)
                av.push(x)
                if(!nodup){
                  ns.push(index)
                }
                
                
               }
               
                // console.log(slots,s[slots])

                  // if(slots == pref?.slot){
                  //   if(s[slots]){
                  //     av.push(x)
                  //     console.log('still avail',slots,s[slots])
                  //   }
                  // }
              
              })
            }
          })
        }
      })
    }

    // console.log(ns)
    // console.log(av[0])
    setNewSched(ns)
    setAvailmedtech(av[0])
    setFormData(prevState => ({
      ...prevState,
      medtech_id: availmedtech?.id}))
  }
  
  //set time to payload and check available medtech
  const makehour = (start) => {
    day = formData.date
    // console.log(start)
    pref = schedule.find(obj => {
      return obj.startTime == start
    })
    setPreftime(pref)
    // console.log(pref)
    // setFormData({ start_time: pref.startTime, end_time: pref.endTime })
    getavailmedtech()
    return pref
  }

  //set date to payload and check available medtech
  const getDate = (datet) => {
    day = datet
    pref = preftime
    // setFormData({ date: day })
    getavailmedtech()
    return day
  }

  // test
  const timee=(e)=>{
    let pref=makehour(e)
    // console.log(pref)
    setFormData(prevState => ({
      ...prevState,
      start_time: pref?.startTime, end_time: pref?.endTime }))
  }

  const datee=(e)=>{
    let pref = getDate(e)
    // console.log(pref)
    setFormData(prevState => ({
      ...prevState,
      date: pref }))
  }

  const medtechdetails =()=> {
    const medtech = medtechs.find(obj => {
      if(obj.name === "admin_medtech"){
        return obj
      }
    })
    setMedtechSelected(medtech)
    // console.log(medtech)
  }

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = input => e => {
    // input value from the form
    const {value } = e.target;

    //update data state taking previous state and then adding new value to create new object
    setUserData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }

  /********** dropzone styles **********/
   const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    borderWidth: 4,
    borderRadius: 2,
    minWidth: '50%',
    borderColor:  '#00cac2',
    borderStyle: 'dashed',
    backgroundColor: '#a4e2e0',
    color: '#4b4c4d',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    position: 'relative',
    cursor: 'pointer'
  };

  
  const fileValidator = (file) => {
    if (file?.size > MAX_SIZE) {
      toast.warning("file is larger than 50MB")
      return {
        code: "file-size-too-large",
        message: `file is larger than 50MB`,
      };
    }
    return null;
  }

  const MAX_SIZE = 5242880;
  const MAX_FILES = 5;
  const {getRootProps, getInputProps, acceptedFiles, isDragReject, rejectedFiles, fileRejections} = useDropzone({
    maxFiles: MAX_FILES,
    multiple: true,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf']
    },
    // accept: 'image/*',
    onDrop: acceptedFiles => {
      // console.log(acceptedFiles);
      setMyFiles(myFiles?.concat(...acceptedFiles))
      setFiles(acceptedFiles?.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    },
    validator: fileValidator
  });

  const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0]?.size > MAX_SIZE;

  const removeFile = file => () => {
    // const newFiles = [...myFiles]
    const newFiles = [...files]
    console.log("u twerkin")
   
    newFiles.splice(newFiles?.indexOf(file), 1)
    setFiles(newFiles?.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));

     console.log(myFiles)
  }
  
  const acceptedFileItems = acceptedFiles?.map((file,i) => (
    <>
      <li key={file.path}>
        {file.path} 
      </li>
      {/* <i className='bx bx-trash' onClick={removeFile}></i> */}
    </>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  
 

  const style = useMemo(() => ({
    ...baseStyle
  }), [
    
  ]);

  /********** React-Select Styles **********/
  const customStyles = {
    control: base => ({
        ...base,
        minHeight: 30
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 4
    }),
    clearIndicator: base => ({
        ...base,
        padding: 4
    }),
    multiValue: base => ({
        ...base
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 5px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
  };


  /********** CREATE/POST CUSTOMER API REQUEST **********/
  const handleRegisterPx = (e) => {
    e.preventDefault()
    // console.log(formData)

    const customerData = {
      customer: {   
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        mobile_number: userData.mobile_number,
        birthday: userData?.birthday,
        password: `${userData?.first_name?.split(' ').join('').toLowerCase().slice(0, 3)}LAH#2022`,
        gender: userData?.gender,
      }
    };

      // console.log(customerData.customer)     
      setLoading(true)
      axios({
        method: "POST",
        url:`${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/customers`,
        data: customerData,
        headers: requestHeaders 
      })
      .then((data) => {
        setLoading(false)
        if (data.status === 200) {
          toast.success("Successfully created a user")
          // console.log(data?.data?.data?.patient?.id)
          // setEmailSelected({id:data?.data?.data?.patient?.id})
          handleAddBooking(e,data?.data?.data?.patient?.id)
          navigate("/md/bookings")
        }
      })
      .catch(function (error) {
        // setLoading(false)
        if (error.response) {
          toast.error(error?.response?.data?.message[0])
        }
      })
      .finally(() => setLoading(false));
  }

  /********** ADD/POST BOOKING **********/ 
  const handleAddBooking = (e) => {
    e.preventDefault()

    let cust_email, cust_firstname, cust_lastname, cust_mobilenum, cust_birthdate, cust_gender;
    
    if(emailSelected?.id){
      cust_firstname = emailSelected?.first_name
      cust_lastname = emailSelected?.last_name
      cust_email = emailSelected?.email
      cust_mobilenum = emailSelected?.mobile_number
      cust_birthdate = emailSelected?.birthday
      cust_gender = emailSelected?.gender
    }
    else {
      cust_firstname = userData?.first_name
      cust_lastname = userData?.last_name
      cust_email = userData?.email
      cust_mobilenum = userData?.mobile_number
      cust_birthdate = userData?.birthday
      cust_gender = userData?.gender
    }

    const bookingData = {
      booking: {
        patient_email: cust_email,
        date: formData?.date ? formData?.date : null,
        start_time: formData?.start_time ? createdatetoISO(formData?.start_time) : null,
        end_time: formData?.end_time ? createdatetoISO(formData?.end_time) : null,
        location: currentPosition ? currentPosition?.address : null,
        remarks: formData?.remarks,
        status: formData?.status,
        patient_first_name: cust_firstname,
        patient_last_name: cust_lastname,
        patient_gender: formData?.patient_gender,
        medtech_id: formData?.medtech_id ? formData?.medtech_id : getRandomData(medtechs)?.id,
        longitude:currentPosition?.lng,
        latitude:currentPosition?.lat,  
        patient_mobile_number: cust_mobilenum,
        patient_birthdate: cust_birthdate,
        patient_gender: cust_gender
      }
    }
    
    if(files?.length !== 0){
      setLoading(true)
      axios({
        method: "POST",
        url: `${REACT_APP_MD_BASEURL}/doctor/${md_id}/upload/bookings`,
        data: bookingData,
        headers: requestHeaders
      })
      .then((data) => {
        if (data.status === 200) {
          console.log(data)
          fileUploadHandler(data?.data?.data?.booking?.id)
          postPayment(data?.data?.data?.booking)
          toast.success("Successfully created upload booking!")
          navigate("/md/bookings")
        }
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(error.response.data.message[0])
        }
      })
      .finally(() => setLoading(false));
    }
    else{
      toast.warning("Upload should not be empty!")
    }
  }


    // Generate Payment Link
  const postPayment = (booking_data) => {
    const paymentData = {
      payment: {
        booking_id: booking_data?.id,
        patient_email: booking_data?.patient_email,
        billed_to: sendBillTo,
        service_fee: 0,
        discount: 0,
        amount: 99,
        status: "Unpaid"
      }
    }

    axios({
      method: "POST",
      url: `${ REACT_APP_MD_BASEURL }/doctor/${md_id}/upload/payments`,
      data: paymentData,
      headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },  
    })
    .then((data) => {
      if(data.status === 200) {
        if (sendBillTo === "Clinic") window.open(`https://upload.labathome.us/payment-link?id=${data?.data?.data?.payment?.id}`)
      }
    })
    .catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message[0])
      }
    })


    const paymentSettingsData = {
      doctor: {
        doctor_id: md_id,
        default_service_fee_payment_option_upload: sendBillTo
      }
    }

    axios({
      method: "POST",
      url: `${ REACT_APP_MD_MAINUS_BASEURL }/doctor/${md_id}/doctors/update_payment_setting`,
      data: paymentSettingsData,
      headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },  
    })
    .then((data) => {
      if(data.status === 200) {
        console.log("Updated payment settings!")
      }
    })
    .catch(function (error) {
      if (error.response) {
        setLoading(false)
        toast.error(error.response.data.message[0])
      }
      
    })
  }

  // Update local storage when payment option changes
  useEffect(() => {
    localStorage.setItem('default_service_fee_payment_option_upload', sendBillTo)
    
  }, [sendBillTo])
  
  const handleSubmit =(e)=>{
    e.preventDefault()
  
    if( emailSelected?.id  ){
      handleAddBooking(e)
    }
    else{
      handleRegisterPx(e)
    }
  }
      
  const thumbs = files?.map((file, index) => (
    <>
      <div>
        <div style={thumb} key={index + 1}>
          <div style={thumbInner}>
            <img
              src={file.preview}
              style={img}
              // Revoke data uri after image is loaded
              onLoad={() => { URL.revokeObjectURL(file.preview) }}
            /> 
          </div>
        </div>
        <div><small>{file.path}</small><i className='bx bx-trash' onClick={removeFile(file)}></i></div>
      </div>
    </>
  ));


  // revoke the data uris to avoid memory leaks
  useEffect(() => () => { 
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

 // post requisition form
  const fileUploadHandler = (id) => {
    const reqFormData = new FormData();  

    Promise.all(myFiles.map((file) => {
      reqFormData.append('file', file)
      fetch(`${REACT_APP_MD_BASEURL}/doctor/${md_id}/upload/bookings/${id}/requisition_forms`, { method: "POST", headers: { Authenticate: `Bearer ${localStorage.token}` }, body: reqFormData })
    }))
    .then((res) => { 
      // console.log(res) 
      res.json()
    })
    .catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message[0])
      }
    })
  }

    /********** toggle test billing option handler **********/
    function handleToggleBilling() {
      if (billingOption == 'Patient') {
        setSendBillTo('Clinic');
        setServiceFeePatientAmount(0);
        setServiceFeeClinicAmount(99)
        setBillingOption('Clinic');
        console.log("Tests Fee billed to Clinic")
      } else {
        setSendBillTo('Patient');
        setServiceFeePatientAmount(99);
        setServiceFeeClinicAmount(0)
        setBillingOption('Patient');
        console.log("Tests Fee billed to Patient")
      }
    }


  return ( 
    <>
      <Layout>
        <form className="p-2">
          <div className="mx-3 my-5">
            <div className="accordion" id="accordionPanelsStayOpenExample">

            {/*********** PATIENT INFORMATION ***********/}         
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                    Patient Information
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                  <div className="accordion-body">

                    <div className="row d-flex align-items-center mb-3">
                      <div className="col">
                        <label>Search Patient</label>
                        <div className="form-group">
                            <Select
                              key={selectOptions?.value}
                              name={selectOptions?.label} 
                              options={selectOptions} 
                              onChange={(e)=>selectCustomer(e)} 
                              isClearable={isClearable}
                              isRtl={isRtl}
                              noOptionsMessage={ ()=> loading ? 
                                  <>
                                    <span>Loading patients...</span>
                                  </>
                                  : <span>"No Patients Found"</span> 
                                }
                              loading={loading}
                              styles={customStyles}
                              placeholder={<div><small>Select Existing Patient</small></div>}
                            />
                        </div> 
                      </div>
                    </div>

                  <div className="row mb-3">
                    <div className="col-sm-12 mb-2">
                      <label>or Add a New Patient</label>
                    </div>

                    <div className="form-group col-sm-6 mb-3">
                      <label htmlFor="floatingInput required">Last Name</label>
                      <input type="text" value={ emailSelected?.last_name || userData?.last_name } onChange={ handleInputData("last_name") }  className="form-control rounded-1" id="floatingInput" required/>
                    </div>
                    <div className="form-group col-sm-6"> 
                      <label htmlFor="floatingInput required">First Name</label>
                      {/* <input type="text" value={ emailSelected?.first_name || formData?.first_name } onChange={ (e)=> setFormData({ first_name: e.target.value }) } className="form-control rounded-1" id="floatingInput" required/> */}
                      <input type="text" value={ emailSelected?.first_name || userData?.first_name } onChange={ handleInputData("first_name") } className="form-control rounded-1" id="floatingInput" required/>
                    </div>
                  </div>

                <div className="row mb-3">
                  <div className="form-group col-sm-6 mb-3">
                    <label htmlFor="floatingInput required">Email</label>
                    <input type="email" value={ emailSelected?.email || userData?.email } onChange={ handleInputData("email") } className="form-control rounded-1" id="floatingInput" required/>
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="floatingInput required">Mobile Number</label> 
                    <input type="number" maxlength="10" value={ emailSelected?.mobile_number || userData?.mobile_number } onChange={ handleInputData("mobile_number") } className="form-control rounded-1" id="floatingInput" required/>
                  </div> 
                </div>

                <div className="row mb-3">
                  <div className="form-group col-sm-6 mb-3">
                    <label htmlFor="floatingInput required">Birthdate</label>
                    <input type="date" value={ emailSelected?.birthday || userData?.birthday } onChange={ handleInputData("birthday") } className="form-control rounded-1" id="floatingInput" required/>
                  </div>

                  <div className="form-group col-sm-6 mb-3">
                      <label htmlFor="floatingInput required">Gender</label>
                      <input type="text" value={ emailSelected?.gender || userData?.gender } onChange={ handleInputData("gender") }  className="form-control rounded-1" id="floatingInput" required/>
                    </div>
                </div>
                <div className="row d-flex align-items-center mb-3">
                  <div className="form-group col">
                    <input value={emailSelected?.id}  type="hidden" className="form-control rounded-1" id="floatingInput" required/>
                  </div>
                  </div>
                  </div>
                </div>
              </div>

              <br/>
            {/*********** UPLOAD LAB ORDER FORM ***********/}
              <div className="accordion-item">
                <h2 className="accordion-header collapsed" id="panelsStayOpen-headingTwo">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                    Upload Lab Requisition Form
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                  <div className="accordion-body">
                  
                    {/* <div className='card border-0'>
                      <div className='file-upload-field'> */}
                        <div className='container w-25' {...getRootProps(({style}))}>
                          <input {...getInputProps()} 
                            className="w-25" 
                            max={MAX_SIZE} 
                            min={0}
                          />
                          
                          <p className='text-center m-auto'><i className='bx bx-cloud-upload upload-icon' ></i><br/><strong>Drag & drop <br/>or click to browse file</strong></p>
                          
                          <small>{ nameFile }</small>
                          { isDragReject && (<div>File type not accepted</div>) }
                          { isFileTooLarge && (
                            <div className="text-danger mt-2">
                              File is too large.
                            </div>
                          )}
                            <ul>{fileRejectionItems}</ul>
                        </div>
                        <aside style={thumbsContainer}>
                          {thumbs}
                        </aside>
                        {/* { acceptedFileItems.length !== 0 &&
                        <aside>
                          <ul>{acceptedFileItems}</ul>
                        </aside>
                        } */}
                      </div> 
                    </div>    
                      
                  {/* </div>
                </div> */}
              </div>

              <br/>
            {/*********** APPOINTMENT SCHEDULE ***********/}
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                    Appointment Scheduling (<strong>Optional</strong>)
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                  <div className="accordion-body">

                  <label htmlFor="floatingInput">Input Patient Appointment Location</label>
                    <div className="col bg-white rounded-1 d-flex flex-column justify-content-center align-items-center" >
                        <MapContainer google={this?.google} getLocation={getLocation} currentPosition={currentPosition} setCurrentPosition={setCurrentPosition} />
                    </div>

                    <div className="row mt-4">
                        <div className="form-floating">
                            <input disabled type="hidden" className="form-control rounded-1" id="floatingInput" 
                                value={currentPosition?.address} 
                                onChange={(event) =>setCurrentPosition({ ...currentPosition, address: event.target.value})}
                              />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-floating col-6">
                            <input 
                                disabled
                                type="hidden" 
                                className="form-control rounded-1" 
                                id="floatingInput" 
                                value={currentPosition?.lat} 
                                onChange={(event) =>setCurrentPosition({ ...currentPosition, lat: Number(event.target.value)})}
                                
                            />
                        </div>
                        <div className="form-floating col-6">
                            <input disabled
                                type="hidden" 
                                className="form-control rounded-1" 
                                id="floatingInput"
                                value={currentPosition?.lng} 
                                onChange={(event) =>setCurrentPosition({ ...currentPosition, lng: Number(event.target.value)})}
                            />
                        </div>
                    </div>
                    
                    <div className="row mb-4">
                      <div className="form-group col">
                        <label htmlFor="floatingInput">Date</label>
                        <input
                          value={formData?.date}
                          onChange={e=> datee(e.target.value)}
                          type="date"
                          className="form-control rounded-1"
                          id="floatingInput"
                          min={disablePastDate()}
                        /> 
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col">
                        <label htmlFor="floatingInput">Preferred Schedule</label>
                        <select
                            value={formData?.start_time}
                            // value={schedule?.label}
                            className="form-control form-select rounded-1"
                            // onChange={e => makehour(e.target.value)}>
                            onChange={e => timee(e.target.value)}>
                    
                            {newSched?.map((option) =>{
                              if(getCurrentTime()< option?.startTime)
                              {
                                return <option value={option.startTime}>{option.label}</option>
                              }else if (formData?.date != curentdate){
                                return <option value={option.startTime}>{option.label}</option>
                              }
                              })}
                        </select>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>

              {/*********** BILLING INFORMATION ***********/}         
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                    Billing Option 
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour">
                  <div className="accordion-body">
                    {/* <div className="row">
                      <div class="form-check">
                        <input type="radio" class="form-check-input" id="send-bill-1" name="optradio" value="option1" checked={sendBillTo === "Patient"} onClick={() => {setSendBillTo("Patient");setServiceFeePatientAmount(99);setServiceFeeClinicAmount(0)}} />Send Bill to Patient
                        <label class="form-check-label" for="radio1"></label>
                      </div>
                      <div class="form-check">
                        <input type="radio" class="form-check-input" id="send-bill-2" name="optradio" value="option2" checked={sendBillTo === "Clinic"} onClick={() => {setSendBillTo("Clinic");setServiceFeePatientAmount(0);setServiceFeeClinicAmount(99)}} />Send Bill to MD/Clinic
                        <label class="form-check-label" for="radio2"></label>
                      </div>
                    </div> */}
                    {/* <p className='text-muted px-2'>billed to: { billingOption }</p> */}
                    <div className="col-sm-12 col-md-8 col-lg-4 col-xl-4">
                      <div id="bill-toggle-switch" className="form-check form-switch d-flex justify-content-between align-items-center w-75 m-0 p-0">
                        <label className="form-check-label h5 m-0 p-0" htmlFor="flexSwitchCheckChecked">Bill to Patient</label>
                        <input 
                          className="form-check-input mx-2 custom-switch-xl m-0" 
                          type="checkbox" 
                          id={ billingOption === "Patient" ? "send-bill-1" : "send-bill-2" } 
                          checked={sendBillTo === "Clinic"}
                          onChange={ handleToggleBilling } 
                          style={ {transform: "scale(1.25)"} }
                        />
                        <label className="form-check-label h5 m-0 p-0" htmlFor="flexSwitchCheckChecked">Bill to MD/Clinic</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>

              {/*********** BILLING SUMMARY ***********/}         
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                    Billing Summary
                  </button>
                </h2>
                <br/>
                <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFive">

                  <h4 className='px-3 pb-1'>Total For Patient: <span className='fw-bold'>${serviceFeePatientAmount}</span></h4>
                  
                  <h4 className='px-3 pb-4'>Total For Clinic: <span className="fw-bold">${serviceFeeClinicAmount}</span></h4>
                </div>

              </div>


              
              

              <div className="d-flex justify-content-center align-items-center w-100 mt-5">
                <button onClick={  (e)=> handleSubmit(e) } className="btn lah_gradient text-white border-0 px-3 px-sm-2 mx-2 mx-sm-1 w-25">
                  <div className='d-flex justify-content-center align-items-center p-2 p-sm-1'>
                      <span>Submit</span>
                  </div>
                </button>
              </div>

            </div>
          </div>
        </form>
      </Layout>
    </>
  );
};


export default BookWithInsurance