import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { Layout } from '../../components/Layout/Layout'
import Spinner from '../../components/Layout/Spinner'
import { handleSort } from '../../utils/helpers'
import ShowPackage from './ShowPackage'
import AddPackageLocale from './AddPackageLocale'
import no_data from '../../assets/nodata.svg';
import ReturnPrev from '../../components/ReturnPrev';

const PackageIndex = () => {

    const navigate = useNavigate()
    const { REACT_APP_MD_MAINUS_BASEURL } = process.env;
    const [customPackages, setCustomPackages] = useState([])
    const [loading, setLoading] = useState(false)
    const [packageData, setPackageData] = useState()
    const [showToggle, setShowToggle] = useState(false);
    const [update, setUpdate] = useState(false);
    const requestHeaders = {Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json"}
    const md_id = localStorage.admin_id



    useEffect(() => {
        setLoading(true)
        axios.get(`${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/packages`, { headers: requestHeaders }, {doctor_id: md_id}) 

        .then(response => {
            setLoading(false)
            console.log(response?.data?.data?.packages);
            setCustomPackages(response?.data?.data?.packages)
        })
        .catch(error => {
            setLoading(false)
            console.log(error);
        });
    }, [update, setUpdate])

    const packageToggle = (data) => {
        setPackageData(data)
        setShowToggle(true)
    }
  

  return (
    <Layout>
        <main className='container-fluid main-parent-container px-5 my-2'>
            <div className='d-flex flex-col w-100 px-5 my-2'>  
                <div className='d-flex justify-content-end px-2 w-100'>
                    <ReturnPrev />

                    <button onClick={() => navigate('/md/package/add')}  className="mx-2 btn lah_gradient text-white border-0 align-self-end fw-bold">
                      <div className='d-flex justify-content-center align-items-center cta-action'>
                        <i className='bx bx-plus-medical'></i>
                          <span className='m-1'>Create Package</span>
                      </div>
                    </button>
                </div>
              </div>
            { loading 
            ?
            <Spinner /> 
            :  
            <>
           
            <div id="customers-table" className='card border rounded-3 table-card-container scrollbar-div'>  
              <div className="table-responsive scrollbar-div">
              <table className="bg-white table table-responsive">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Retail Price</th>
                    <th>Active</th>
                    <th>Add Locale</th>
                  </tr>
                </thead>

                <tbody className="scrollbar-div overflow-auto">

                { customPackages?.map((p)=>{
                    const enName = p?.localizations?.find(x => x.locale === "en");
                return(
                  <tr key={`package-${p?.id}`}>
                    <td>{p?.id}</td>
                    <td data-bs-toggle="modal" role="button" data-bs-target="#showPackageModal"  onClick={() => { packageToggle(p) }}><u>{ p?.is_featured ? <>{ enName?.name || p?.name } <sup><i className='bx bxs-star text-warning'></i></sup></> : enName?.name || p?.name }</u> </td>
                    <td>${Number(p?.price).toFixed(2)}</td>
                    <td>${ p?.retail_price ? `${Number(p?.retail_price).toFixed(2)}` : `${Number(0).toFixed(2)}` }</td>
                    <td>{ p?.active ? 'Active' : 'Inactive' }</td>
                    <td data-bs-toggle="modal" role="button" data-bs-target="#AddPackageLocale" onClick={() => { packageToggle(p) }}><u>Edit Name, Description, Reminders</u></td>
                  </tr>
                )
                })} 
                </tbody>
              </table>
              </div>
            </div>
           
            </>
            }

        </main>

        <ShowPackage packageData={packageData} setUpdate={setUpdate} update={update} />
        <AddPackageLocale packageData={packageData}  setUpdate={setUpdate} update={update} />
    </Layout>
  )
}

export default PackageIndex