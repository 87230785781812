import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { getTime, getNewTime, createdatetoISO, getTimetime, handleCloseModal, getCurrentTime, disablePastDate, getDateAfterTomorrow} from '../../utils/helpers';
import { toast } from 'react-toastify';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Spinner from '../Layout/Spinner';
import MapContainer from '../Map/GoogleMaps';
import MainPage from '../../pages/booking/AddToCart/MainPage';
import TestsPage from '../../pages/booking/AddToCart/TestsPage';
import Basket from '../../pages/booking/AddToCart/Basket';
import { getMedtechsData } from '../../utils/API';
import  '../../styles/BookingStatusBar.css'
import StepNavigation from "./stepNavigation";


const ShowMainBookingModal = ({  booking, update, setUpdate, showToggle, bookingStep, labelArray, currentStep, updateStep, setShowToggle  }) => {
  const { REACT_APP_MD_BASEURL, REACT_APP_PACKAGES,REACT_APP_TESTS } = process.env;
    const { userState } = useContext(AuthContext);
    const navigate = useNavigate()
    //attributes
    const first_name = useRef()
    const last_name = useRef()
    const date = useRef()
    // const covid_tag = useRef()
    const remarks = useRef()
    const admin_remarks = useRef()
    const medical_condition = useRef()
    const [medtechs, setMedtechs] = useState([])
    const [medtechSelected, setMedtechSelected] = useState({
        availability: null,
        availability_slot:{},
        created_at: "",
        email: "",
        id: '',
        name: "",
        rating: null,
        updated_at: ""
        })
    const [bookingStatus, setBookingStatus] = useState()
    const bookingStatusOptions =  [
        { value:"Confirmed", label:"Booking Confirmed"}, 
        { value:"Pending",  label:"Payment Pending"},
        { value:"Cancelled", label:"Booking Cancelled"}, 
        { value:"En Route",  label:"Phlebotomist En Route"},
        { value:"Arrived", label:"Phlebotomist Arrived"},
        { value:"Started", label:"Appointment Started"}, 
        { value:"Done",  label:"Appointment Completed"},
        { value:"Specimen Turned over", label: "Specimen Delivered"},
        { value:"Results received", label: "Full Results Received"},
        { value:"Partial results received", label:"Partial Results Received"}
    ]
    // const [paymentStatus, setPaymentStatus] = useState({value: booking?.payment?.status })
    const [paymentStatus, setPaymentStatus] = useState()
    const paymentStatusOptions = [
        { value: "Unpaid", label: "Unpaid"}, 
        { value: "Paid - Credit Card", label:"Paid - Credit Card"},
        { value: "Refunded", label:"Refunded"} ,
        { value: "Partially refunded", label:"Partially refunded"}
    ]
    const [routingStatus, setRoutingStatus] = useState()
    const routingStatusOptions = [ 
        {value: "Pending", label: "Pending"},
        {value: "Done", label:"Done" }
    ]
    const [covidTag, setCovidTag]= useState()
    const covidTagOptions = [
        {value:"No Known Exposure", label: "No Known Exposure"},
        {value:"Confirmed Negative", label: "Confirmed Negative"},
        {value:"Exposed but asymptomatic", label: "Exposed but asymptomatic"},
        {value:"Experiencing symptoms but not tested for COVID-19 yet",label: "Experiencing symptoms but not tested for COVID-19 yet"},
        {value:"Tested for COVID-19 but results are inconclusive",label: "Tested for COVID-19 but results are inconclusive"},
        {value:"Confirmed Positive",label: "Confirmed Positive"}
    ]
    const [disabled, setDisabled] = useState(true)
    const [showPackages, setShowPackages] = useState(true)
    const [totalQuantity,setTotalQuantity]=useState(0)
    const [searchTerm, setSearchTerm] = useState("");
    const [packages, setPackages] = useState([])
    const [cartItems, setCartItems] = useState([]);
    const [tests, setTests] = useState([])
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [loading, setLoading] = useState(false)
    const [currentPosition, setCurrentPosition] = useState({
        address: "",
        lat: 0,
        lng: 0
    })
    const [error, setError] = useState("")
    const [medtechSelect, setMedtechSelect] = useState({
        availability: null,
        availability_slot:{},
        created_at: "",
        email: "",
        id: '',
        name: "",
        rating: null,
        updated_at: ""
        })
    const [schedule, setSchedule]= useState()
    const scheduleOptions = [
            {slot:10, available:'', label: "-- Select Schedule -- ", startTime:'N/A'},
            {slot:0, available:'', label: "05:00am - 07:00am ", startTime:'05:00', endTime:'07:00'},
            {slot:1, available:'', label: "07:00am - 09:00am ", startTime:'07:00', endTime:'09:00'},
            {slot:2, available:'', label: "09:00am - 11:00am ", startTime:'09:00', endTime:'11:00'},
            {slot:3, available:'', label: "11:00am - 01:00pm ", startTime:'11:00', endTime:'13:00'},
            {slot:4, available:'', label: "01:00pm - 03:00pm ", startTime:'13:00', endTime:'15:00'}
          ]
    const [dates, setDates] = useState() 
    let day
    const [availmedtech,setAvailmedtech] = useState()
    const [preftime, setPreftime] = useState()
    let pref
    const current = new Date();
    const curentdate = `${current.getFullYear()}-0${current.getMonth()+1}-0${current.getDate()}`
    // For results
    const [selectedFile, setSelectedFile] = useState(null);
    const requestHeaders = {Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json"}
    const md_id = localStorage.admin_id
    const [newSched,setNewSched] = useState([])
    
    // useEffect(()=>{ console.log(`RESULT: ${booking?.result}`) },[booking])
    
    useEffect(() => {  
        setCurrentPosition({
            address: booking?.location,
            lat: parseFloat(booking?.latitude),
            lng: parseFloat(booking?.longitude)
        })
        setStartTime(getNewTime(booking?.time_start))
        setEndTime(getNewTime(booking?.time_end))
        const sched = scheduleOptions?.find((x) => x?.startTime == getNewTime(booking?.time_start));
        
        day = booking?.date
        setDates(day)
        setSchedule({value:getNewTime(booking?.time_start)})
        setCovidTag({value: booking?.covid_tag})
        setBookingStatus({value: booking?.status})
        setPaymentStatus({value: booking?.payment?.status})
        setRoutingStatus({value: booking?.routing_status})
        setMedtechSelected({name: booking?.medtech?.name,id: booking?.medtech_id})
        getusercart(booking?.cart) 
        getavailmedtech()
    },[booking])

    // Fetching medtechs
    useEffect(() => {  
        setLoading(true)
        async function fetchData(){
            const medtechsData = await getMedtechsData()
            if (medtechsData) {
                setLoading(false)
                setMedtechs(medtechsData)
            }   
        }
        fetchData()
        day = dates
        getavailmedtech()
    }, [])


  /********** check medtech availability **********/ 
  const getavailmedtech = () => {
    setAvailmedtech()
    let av = []
    let ns=[]
    ns.push(scheduleOptions[0])
    if (day || pref) {
      medtechs.map((x) => {

        if (x?.availability_slot) {
          let avail = x?.availability_slot
          // console.log(avail)

          Object.entries(avail).map((datekey) => {
            // console.log(datekey)
            let d = datekey[0]
            let s = datekey[1]
            if (d == day) {
              //   console.log(x)
              // console.log(x.name,s)
              // console.log(s)
              // console.log(d)
              Object.keys(s).map((slots)=>{
               if(s[slots]){
                let index = scheduleOptions.find(item => item.slot == slots)
                let nodup = ns.find(item => item.slot == slots)
                av.push(x)
                if(!nodup){
                  ns.push(index)
                }
               }
               
                // console.log(slots,s[slots])

                  // if(slots == pref?.slot){
                  //   if(s[slots]){
                  //     av.push(x)
                  //     console.log('still avail',slots,s[slots])
                  //   }
                  // }
              
              })
            }
          })
        }
      })
    }

    // console.log(ns)
    // console.log(av[0])
    setNewSched(ns)
    setAvailmedtech(av[0])
    // setFormData(prevState => ({
    //   ...prevState,
    //   medtech_id: availmedtech?.id}))
  }

    
    const handleClick = () => {
        if (disabled) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }

    const medtechdetails = (id) => {
        const medtech = medtechs.find(obj => {
            if (obj.name==id) {
                return obj
            }
        })
        setMedtechSelected(medtech)
    }

//check time if with value or N/A returns null or converted time
const checktime = (t)=>{
    let val
    if(t === 's'){
        if (startTime == 'N/A' || startTime == null){
            val = null
        }else{
            val = createdatetoISO(startTime)
        }
    }else{
        if (startTime == 'N/A' || startTime == null){
            val = null
        }else{
            val = createdatetoISO(endTime)
        }
    }

    return val
}


    const handleEdit =  async (e) => {
        e.preventDefault()
        // console.log(checktime('s'))
       let bookingData = {
            booking: {
                id: booking?.id,
                medtech_id:availmedtech?.id,
                routing_status: routingStatus.value,
                patient_first_name: first_name?.current.value,
                patient_last_name: last_name?.current.value,
                date: dates,
                time_start: checktime('s'),
                time_end: checktime('e'),
                status: bookingStatus.value,
                location: currentPosition?.address,
                // covid_tag: covidTag?.value,
                // remarks: remarks?.current.value,
                // admin_remarks: admin_remarks?.current.value,
                // medical_condition: medical_condition?.current.value,
                longitude:currentPosition?.lng,
                latitude:currentPosition?.lat,
                cart: formatcart(cartItems)
                }
        }
   

        // let paymentData = {
        //     payment:{
        //         // id: booking?.payment?.id,
        //         status: paymentStatus?.value
        //     }
        // }

        // if(!duplicatebooking){
            // UPDATE BOOKING
            let EDIT_URL = `${REACT_APP_MD_BASEURL}/doctor/${md_id}/bookings/${booking?.id}`;
            // let PAYMENT_URL = `${REACT_APP_MAINUS_BASEURL}/payments/${booking?.payment?.id}`;
            // const requestHeaders = { Authenticate: `Bearer ${localStorage.token}`,"Content-Type": "application/json" }

            // const editPayment = axios.put(PAYMENT_URL, paymentData, { headers: requestHeaders });
            // const editBooking = axios.put(EDIT_URL, bookingData, { headers: requestHeaders });


            await axios.put(EDIT_URL, bookingData, { headers: requestHeaders })
                .then((data) => {
                    // if(data=== 200){
                        toast.success("Successfully updated booking data details!")
                        handleCloseModal("showMainBookingModal")
                        // fileUploadHandler();
                        navigate('/md/bookings')
                        setUpdate(!update); 
                        cartClear()
                        setShowToggle(false)
                    // } 
                    // else{
                    //     toast.error("Check errors!")
                    // }
                })

                // })
                .catch(function (error) {
                    if (error.response) {
                        toast.error(error.response.data.message[0])
                    }
                    setShowToggle(false)
                })
        // }else{
        //     toast.error("Medtech is already booked on selected Date and Time.")
        // }   
    }

    // clear cart
    const cartClear = () => {
        setTotalQuantity(0)
        setCartItems([])
        setDisabled(true)
    }
    // useEffect(()=>{
    //     setCurrentPosition(coords)
    // },[currentPosition])

    // get location
    const getLocation = coords => {
        // console.log(coords);
        setCurrentPosition(coords);
    }

    // Add to cart functionality
    const onAdd = (product) => {
        setTotalQuantity(totalQuantity + 1)
        const exist = cartItems.find((x) => x.name === product.name);
            if(exist) {
                setCartItems(
                cartItems.map((x) =>
                    x.name === product.name ? { ...exist, qty: exist.qty + 1 } : x
                )
                )
            } else {
                setCartItems([...cartItems, { ...product, qty: 1 }]);
            }
    }

    // Remove to cart functionality
    const onRemove = (product) => {
        if(totalQuantity !== 0) {
            setTotalQuantity(totalQuantity - 1)
        }
        const exist = cartItems.find((x) => x.name === product.name)
        
        if(exist.qty === 1) {
            setCartItems(cartItems.filter((x) => x.name !== product.name));
        } else {
            setCartItems(
                cartItems.map((x) =>
                    x.name === product.name ? { ...exist, qty: exist.qty - 1 } : x
                )
            );
        }
    }
    
   // Get initial cart items from database
   const getusercart=(d)=> {
    let carttype,testid,testqty,packageid,packageqty
    let totqty=0
    // setCartItems([])
    if(d){
        Object.keys(d).forEach(t => {
            carttype = t?.slice(0,4)

            if (carttype === 'Test'){
            testid = Math.floor(t?.slice(5))
            testqty = d[t]

            totqty = totqty + testqty
            // console.log(totqty)

            const gettest = tests.find((x) => x.id === testid)
            
            cartItems.push({ ...gettest, qty: testqty })


            }else{
                packageqty = d[t]
                totqty = totqty + packageqty
                packageid = Math.floor(t?.slice(8))
                const getpackage = packages?.find((x) => x?.id === packageid)
                cartItems.push({ ...getpackage, qty: packageqty })
                // const getpackage = packages?.find((x) => x?.name === t)      
                // cartItems.push({ ...getpackage, qty: packageqty })
            }
        }) 
    } 
    setTotalQuantity(totqty)
    // console.log("EDIT - CART ITEMS: ", cartItems)
    // console.log("Fetch booking cart: ", d)
    return totalQuantity,cartItems
}

// convert back to Test#ID:QTY payload format
  const formatcart=(c)=> {
    let list1={}
    let list2={}
    
    if (c){
        // console.log(c)
        c.forEach(function (item) {
          if(item.type){
            let a = item?.type+'#'+item?.id
            var b = item?.qty;
            list1={[a]:b}
  
            list2 = {
                ...list2,
                ...list1
            }
          }else{
            let a = "Package#"+item?.id
            var b = item?.qty;
            list1={[a]:b}
  
            list2 = {
                ...list2,
                ...list1
            }
          }
        // console.log(list1)
        // console.log(list2)
        })
        // setCart({cart: list2})
    }
    
    return list2
  }

    // Fetching packages
    useEffect(()=>{ 
        // const handlePackages =()=>{
        // setShowPackages(true)
        axios({
            method: "GET",
            url: REACT_APP_PACKAGES,
            headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },
            
        })
            .then((data) => {
                setLoading(false)
                if (data.status === 200) {
                    setPackages(data.data.data.packages)
                }
            })
            .catch((error) => {
                toast.error(error)
                setLoading(false)
            })
        // };
    }, [])

// Fetching labtests
    useEffect(()=>{ 
        setLoading(true)
        axios({
        method: "GET",
        url: REACT_APP_TESTS,
        headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },
        
        })
            .then((data) => {
                setLoading(false)
                if(data.status === 200) {
                // console.log(data.data.data.tests)
                setTests(data.data.data.tests)
                }
            })
            .catch((error) => {
                toast.error(error)
                setLoading(false)
            })
    }, [])

    //set time to payload and check available medtech
    const makehour = (start) => {
        // console.log(start)
        if(start !== 'N/A' || start !== null){
            day = dates
            pref = scheduleOptions.find(obj => {
                return obj.startTime == start
            })
            setSchedule({value:start})
            setPreftime(pref)
            setStartTime(pref.startTime)
            setEndTime(pref.endTime)
        }else{
            setStartTime(pref.startTime)
            setEndTime(pref.endTime)
        }
        
        // getavailmedtech()
        return start
    }

    //set date to payload and check available medtech
    const getDate = (datet) => {
        if(datet){
            day = datet
            pref = preftime
            setDates(datet)
            getavailmedtech()
        }else{
            setStartTime(null)
            setEndTime(null)
            setDates(datet)
            getavailmedtech()
        }
    }
   
    
  return (
        <>
         <div className="modal fade" id="showMainBookingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content p-4">
                        <div className="modal-header">
                            <div className="col d-flex justify-content-between px-5">
                                <h5 className="modal-title" id="exampleModalLabel">Booking Information  <span onClick={handleClick}><i className={booking?.status === "Cancelled" ? "d-none" : "bx bxs-edit-alt"}></i></span></h5>
                                <button onClick={cartClear} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                            </div>
                        </div>
                        <div className="modal-body px-4">
                            <br />
                        <StepNavigation bookingStep={bookingStep} labelArray={labelArray} currentStep={currentStep} updateStep={updateStep}></StepNavigation>

                            {loading
                                ?
                                <Spinner />
                                :
                                <div className="booking-card p-4">
                                    <div className='d-flex justify-content-end align-items-center px-5'>
                                        {/* <div>
                                            <div>
                                                <label>Upload Result</label>
                                            </div>
                                            <input
                                                type="file"
                                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                                disabled={disabled}
                                            />
                                         </div> */}
                                        { booking?.result ?
                                            <button className="btn btn-info text-dark border-1 px-2" type="button">
                                                <a href={booking?.result?.file?.url} target="_blank" className='text-white'> View Results</a>
                                            </button>
                                            :
                                            <>
                                            <button className="btn btn-secondary text-dark border-1 px-2" type="button" >
                                                <a href="#" className='text-white'>No Results</a>
                                            </button>
                                            </>
                                        }
                                            
                                    </div>
                                    <div className="col g-2">
                                        {/* BOOKINGS COUNT SECTION */}
                                        <div className="row mb-4">

                                            <form onSubmit={handleEdit} className="col-12 p-5">
                                                <fieldset disabled={disabled}>

                                                    <div className="row g-3">
                                                        {/* <div className="col-md-4">
                                                            <label>Booking Id</label>
                                                            <input defaultValue={booking?.id} className="form-control"  ></input>
                                                        </div> */}
                                                        <div className="col-md-6">
                                                            <label>Booking Code</label>
                                                            <input defaultValue={booking?.code} className="form-control rounded-2"  ></input>
                                                        </div>
                                                     

                                                        <div className="col-md-6">
                                                            <label>Booking Status</label>
                                                            <select
                                                                value={bookingStatus?.value}
                                                                onChange={(e) => setBookingStatus({ value: e.target.value })}
                                                                className="form-control form-select rounded-2"
                                                                id="floatingSelect"
                                                                aria-label="Floating label select example"
                                                            >
                                                                {bookingStatusOptions.map((option) => (
                                                                    <option value={option.value}>{option.label}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="col-12">
                                                            <label>First Name</label>
                                                            <input ref={first_name} defaultValue={booking?.patient_first_name} className="form-control rounded-2" ></input>
                                                        </div>
                                                        <div className="col-12">
                                                            <label>Last Name</label>
                                                            <input ref={last_name} defaultValue={booking?.patient_last_name} className="form-control rounded-2" ></input>
                                                        </div>


                                                        <div className="form-group col-md-6">
                                                            <label>Date</label>
                                                            <input
                                                                type="date"
                                                                // defaultValue={booking?.date || ""}
                                                                value={dates}
                                                                onChange={e =>  getDate(e.target.value) }
                                                                className="form-control rounded-2"
                                                                // min={booking?.status != 'Done' ? disablePastDate() : '' }
                                                                min={booking?.date == null ? getDateAfterTomorrow() : ''}
                                                            />
                                                        </div>

                                                        <div className="form-group col-md-6">
                                                            <label>Preferred Schedule</label>
                                                            <select
                                                                // value={schedule?.startTime}
                                                                value={schedule?.value}
                                                                className="form-control form-select rounded-2"
                                                                onChange={e => makehour(e.target.value)}>
                                                                {newSched?.map((option) => {

                                                                    return <option value={option.startTime}>{option.label}</option>

                                                                })}
                                                            </select>
                                                        </div>

                                                       
                                                        <div className="col-12 mb-4">
                                                            
                                                            <label htmlFor="floatingInput">Address</label>
                                                            <input id="address" className="form-control rounded-2 mb-2"
                                                                disabled
                                                                value={currentPosition?.address}
                                                                onChange={(event) => setCurrentPosition({ ...currentPosition, address: event.target.value })} 
                                                            />

                                                            <label>Change Address</label>
                                                            <MapContainer google={this?.google} getLocation={getLocation} currentPosition={currentPosition} setCurrentPosition={setCurrentPosition} />
                                                            
                                                        </div>

                                                       
                                                        <div className="mb-3">
                                                            <p>SELECT TESTS & PACKAGES:</p>
                                                            <div>
                                                                <div className="w-100 p-1 d-flex justify-content-between align-content-center align-items-center">
                                                                    <button type="button" onClick={() => setShowPackages((s) => !s)} className="btn lah_gradient text-white border-0 px-2"> {totalQuantity} tests/packages selected</button>
                                                                    <input className="form-control mb-2 w-25 rounded-2" id="tableSearch" type="text" placeholder="Search tests/packages..." onChange={(e) => setSearchTerm(e.target.value)} />
                                                                </div>
                                                            </div>

                                                            <div className="product-container" style={{ display: showPackages ? "none" : "block" }}>

                                                                <div className="row">
                                                                    <span className="hr-text">
                                                                        Packages
                                                                    </span>
                                                                    <MainPage products={packages} onAdd={onAdd} searchTerm={searchTerm} />


                                                                    <span className="hr-text">
                                                                        Tests
                                                                    </span>
                                                                    <TestsPage products={tests} onAdd={onAdd} searchTerm={searchTerm} />
                                                                </div>

                                                            </div>

                                                            <Basket
                                                                totalQuantity={totalQuantity}
                                                                setTotalQuantity={setTotalQuantity}
                                                                cartItems={cartItems}
                                                                onAdd={onAdd}
                                                                onRemove={onRemove}
                                                            ></Basket>
                                                        </div>
                                                        <button type="submit" className="cta">Save changes</button>
                                                    </div>
                                                </fieldset>
                                            </form>
                                            </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ShowMainBookingModal