import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getTestsData } from '../../utils/API'
import { handleCloseModal } from '../../utils/helpers';
import Select from 'react-select'


const ShowPackage = ({ packageData, update, setUpdate }) => {

    const { REACT_APP_TESTS, REACT_APP_MAINUS_BASEURL, REACT_APP_MD_MAINUS_BASEURL } = process.env;
    const [name, setName] = useState("")
    const price = useRef()
    const [retailPrice, setRetailPrice] = useState(0)
    const tagList = useRef()
    const [isFeatured, setIsFeatured] = useState(false)
    const [availableTest, setAvailableTests]= useState([])
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(true)
    let init=[]

    const [selectOptions, setSelectOptions] = useState([])
    const [packageTests, setPackageTests] = useState([])
    const md_id = localStorage.admin_id

     // Fetching labtests
    useEffect(()=>{  
        setLoading(true)
        async function fetchData(){
        const testsData = await getTestsData()
            if(testsData) {
                setLoading(false)
                setAvailableTests(testsData)
                const options = testsData?.map(tests => ({
                    "value" : tests.id, "label" : tests.name
                }))
                setSelectOptions(options)
            }
            // console.log(testsData)
        }
        fetchData()
    },[])

    useEffect(()=>{ 
        if (packageData) {
            setName( packageData?.localizations?.find(obj => obj.locale === "en")?.name  || packageData?.name || "")
            setIsFeatured(packageData?.is_featured)   
            setRetailPrice(packageData?.retail_price); 
        }
        console.log(packageData)
 
     },[packageData])   


    
    packageData?.tests?.map(t => init.push({ value: t.id, label: t.name }));
   
    const handleUpdatePackage =(e)=>{
        e.preventDefault()
        // const id = packageData?.id
      
        const data = {
            package: {
            // name:name?.current?.value,
            price:price?.current?.value,
            retail_price: retailPrice,
            is_featured: isFeatured,
            // description: description?.current?.value,
            tag_list: tagList.current?.value,  
            test_ids: packageTests
            }
        }
    
       axios({
        method: "PUT",
        url: `${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/packages/${packageData?.id}`,
        data: data,
        headers: { 'content-type': 'application/json',  Authenticate: `Bearer ${localStorage.token}` },
        // id: id
        })
        .then((data) => {
            if(data.status === 200) {
                // console.log(data?.data?.data?.package)
                toast.success("Successfully updated package!")  
                handleCloseModal("showPackageModal")
                setUpdate(!update);
            }
        })
        .catch((error) => {toast.error(error.message)})
    }

    const handleClick =()=> {
        if(disabled){
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }
    

    const handleChange= (e)=> {  
        let value = Array.from(e, option => option.value).filter(x => x != null);
        // console.log(value)
        setPackageTests(value)
    }

    const style = {
        control: (base) => ({
          ...base,
          borderRadius: "15px"
        })
    }
   
    return(
        <>
        <div className="modal fade" id="showPackageModal">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content p-4">
                    <div className="modal-header">
                        <div className="col d-flex justify-content-between">
                            <h5 className="modal-title" id="exampleModalLabel">🧪 Package Information <span title="Click to edit pacakage details" onClick={handleClick}><i className="bx bxs-edit-alt"></i></span></h5> 
                                
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                    <div key={packageData?.id} className="modal-body p-5">
                        <form onSubmit={ handleUpdatePackage }>
                            <fieldset disabled={disabled}>
                                <div className="col mb-3">
                                    <label>Name</label>
                                    <input disabled value={name} className="form-control mt-1"  ></input>
                                </div>

                                <div className="col-12 form-check form-switch d-flex justify-content-end">
                                    <input className="form-check-input mx-2" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={isFeatured}
                                        onChange={() => setIsFeatured(!isFeatured)} />
                                    <label className="form-check-label" for="flexSwitchCheckChecked">Featured</label>
                                </div>

                                <div className='row mb-3'>
   
                                    <div className="col">
                                        <label>Price</label>
                                        <input ref={price} defaultValue={packageData?.price} className="form-control"  ></input>
                                    </div>

                                    <div className='col'>
                                        <label>Retail Price</label>
                                        <input value={retailPrice} onChange={(e)=> setRetailPrice(e.target.value)} className="form-control"  ></input>
                                    </div>
                                    
                                </div>
                              
                                <div className="col mb-3">
                                    <label>Tags</label>
                                    <input ref={ tagList } defaultValue={ packageData?.tagList } placeholder="tag1, tag2, tag3" className="form-control mt-1" ></input>
                                </div>
                                
                                <div className="col mb-3">
                                    <p>AVAILABLE TESTS:</p>
                                   
                                        <Select 
                                            noOptionsMessage={() => 'No data matches!'}
                                            defaultValue ={ init.map(ele => ele) }
                                            isMulti ={true}
                                            options = { selectOptions } 
                                            placeholder={<div>Select included tests</div>} 
                                            onChange={ handleChange } 
                                            styles={style} 
                                        />
                                    </div>
                                <button type="submit" className="cta mb-2">Save</button>
                            </fieldset>
                        </form> 
                    </div>

                </div>
            </div>
        </div>

        </>
    )

}

export default ShowPackage