import React from "react";
import moment from "moment";

    /**** toggle state *****/
    export const toggle = (state,setState) => {
        setState(!state)
    }

    export function getDateTime(ISOdate) {
        const d = new Date(ISOdate);
        return d.toLocaleString()
    } 

    export const getDate=(ISOdate)=> {
        const d = new Date(ISOdate);
        return d.toLocaleDateString()
    } 

    export const formattedDate=(ISOdate)=> {
        let d 
        if (ISOdate){
            d=moment(ISOdate).format('YYYY-MM-DD')
        }
        return d
    } 

    export const removezDate=(date)=> {
        let d
        if (date){
            d  = date.slice(0,10)
        }
        return d
    } 

    export const getrange=(startDate, endDate)=> {

        const date = new Date(startDate?.getTime());

        const dates = []
        const newdates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date?.setDate(date?.getDate() + 1);
        }

        dates.map(d=>{

            newdates.push(formattedDate(d))
        })
        // console.log(newdates)
        return newdates;
       
       }

    export const exportDataFileName=(header)=>{
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${header} ${year}${month<10?`0${month}`:`${month}`}${date<10?`0${date}`:`${date}`}`
    }

    export function getDay(ISOdate) {
        const d = new Date(ISOdate);
        return d.toDateString()
    } 

    export const getTime=(date)=> {  
        let d
        
        if(date === null || date === undefined){
            return "N/A"
        } else {
        
            let parseDate = new Date(date);
            // let parseDate = moment()
            // d = `${parseDate.getHours()}:00`;
            d = moment.parseZone(parseDate).format('hh:mm a')
            // d = moment(parseDate).format('hh:mm a')

        return d
        } 
    } 
    /**** convert time to 24H format for input type: time *****/
    export const getTimetime=(date)=> {  
        let d
        if(date === null || date === undefined){
            return "N/A"
        } else {
            let parseDate = new Date(date);
            d = moment.utc(parseDate).format('hh:mm a')
        return d
        } 
    } 

    export const get=(date)=> {  
        // console.log(date)
        let d  = date.slice(0,19)
        // console.log(d)
        if(date === null || date === undefined){
            return "N/A"
        } else {
            // d = new Date(date);
            // d = moment.utc(d).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            d = moment(d).format('YYYY-MM-DDTHH:mm:ssZZ')
        return d
        } 
    }
    
    export function getNewTime(date) {  
        let d
        if(date === null || date === undefined){
            return "N/A"
        } else {
            let parseDate = new Date(date);
            d = moment.utc(parseDate).format('HH:mm')
        return d
        }
    }   
    /**** return time to isoString *****/
    export const createdatetoISO=(time)=> {
        const newdate =`01 January 2010 ${time} UTC`
        // console.log(newdate)
        const d = new Date(newdate)
        // console.log(d)
        // d.setHours(d.getHours()-8)
        let isoDate = d.toISOString()   
        // d = moment(d).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        //  d=d.toISOString
        return isoDate
    }

    // export function createdatetoISO(time) {
    //     const date = "01.01.2000 " + time
    //     const isoDate = moment(date, moment.defaultFormat)
    //     return isoDate
    // }

    /**** price format in dollars *****/
    export function getFormattedPrice(price) {
        return `$${price}`
    }

    /**** date format *****/
    export function formatDate(newDate) {
        const months = {
          0: 'January',
          1: 'February',
          2: 'March',
          3: 'April',
          4: 'May',
          5: 'June',
          6: 'July',
          7: 'August',
          8: 'September',
          9: 'October',
          10: 'November',
          11: 'December',
        }
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthIndex = d.getMonth()
        const monthName = months[d.getMonth()]
        const dayName = days[d.getDay()] // Thu
        const formatted = `${dayName}, ${date} ${monthName} ${year}`
        return formatted.toString()
    }

    /**** truncate details on tables *****/
    export const truncate = (str) => {
        return str?.length > 20 ? str.substr(0, 20 - 1) + "..." : str;
    };

    /**** calendar random color generator *****/
    export const generateColor = () => {
        let trans = '0.25'; //transparency
        let color = 'rgba(';
        for (var i = 0; i < 3; i++) {
            color += Math.floor(Math.random() * 255) + ',';
        }
        color += trans + ')'; 
        return color;
    };
    
    /**** page reload *****/
    export const handleCloseModal=(id)=>{      
        // document.getElementById("staticBackdrop").classList.remove("show", "d-block");
        document.getElementById(id).classList.remove("show", "d-block");
        document.getElementById(id).style.display = "none";
        document.querySelectorAll(".modal-backdrop")
                .forEach(el => {
                    el.classList.remove("modal-backdrop", "show")
                    // el.style.display = "";
                });
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000);
        // setState([])
    } 

    // export const handleReset = (setState) => {
    //     Array.from(document.querySelectorAll('input'));
    //     setState([]);
    // };
    /**** table data sorting *****/
    export const handleSort =(data)=>{  
        let sortedData
        sortedData = data?.sort((a, b) => b.created_at > a.created_at ? 1 : -1,)
        return sortedData 
    }

    export function addhour(time) {
        const newdate =`01 January 2010 ${time} UTC`
        const parseDate = new Date(newdate)
        // console.log(parseDate)

        
        let newhour = moment.utc(parseDate).add(2, 'hours').format('HH:mm')
        // console.log(newhour)
        return newhour
    }


    export const getCurrentTime = () => {
        let today = new Date();
        let hours = (today.getHours() < 10 ? '0' : '') + today.getHours();
        let minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
        return hours + ':' + minutes
      }

    export const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() ).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }

    export const getDateAfterTomorrow = () => {        
        // const today = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
        const today = new Date();
        const dayAfterTomorrow = new Date(today);
        dayAfterTomorrow.setDate(today.getDate() + 2);
        
        const dd = String(dayAfterTomorrow.getDate() ).padStart(2, "0");
        const mm = String(dayAfterTomorrow.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = dayAfterTomorrow.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }

    export const checkValidity =(data)=>{
        const formatYmd = date => date.toISOString().slice(0, 10);
         
        if(data < formatYmd(new Date())) {
          console.log("Invalid Date")
         }else{
           return data
         }
       }

    export const LoadingSpinner=()=> {
        return (
        <div className="spinner-container">
        <div className="loading-spinner">
        </div>
        </div>
    );
    }

    export const getRandomData=(arr)=> {

        // get random index value
        const randomIndex = Math.floor(Math.random() * arr.length);
      
        // get random item
        const item = arr[randomIndex];
      
        return item;
    }