
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router'
import axios from 'axios'
import { toast } from 'react-toastify'
import TestsPage from '../booking/AddToCart/TestsPage';
import { getTestsData } from '../../utils/API';
import { Layout } from "../../components/Layout/Layout";
import { AuthContext } from "../../context/AuthContext";
import NextButton from '../../components/NextButton'
import BackButton from '../../components/BackButton'

const CreateCustomPackage = () => {

    const navigate = useNavigate()
    const { REACT_APP_PACKAGES, REACT_APP_CREATE_PACKAGES, REACT_APP_TESTS, REACT_APP_MD_MAINUS_BASEURL } = process.env;
    // const name = useRef()
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const price = useRef()
    // const description = useRef()
    const tagList = useRef()
    const [retailPrice, setRetailPrice] = useState(0)
    // const [isFeatured, setIsFeatured] = useState(false)
    const [availableTest, setAvailableTests] = useState([])
    const [testList, setTestList] = useState([])
    const [loading, setLoading] = useState(false)
    const [showPackages, setShowPackages] = useState(true)
    const [testQuantity, setTestQuantity] = useState(0)
    const [searchTerm, setSearchTerm] = useState("")
    const [createdPackage, setCreatedPackage] = useState([])
    const md_id = localStorage.admin_id

    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const testsData = await getTestsData()

            if (testsData) {
                setLoading(false)
                setAvailableTests(testsData)
                setTestList(testsData)
            }
            // console.log(testsData)
        }
        fetchData()
    }, [])

    useEffect(() => {
        setTestQuantity(createdPackage.length)
        // console.log(createdPackage)
    }, [createdPackage])


    const handleAddPackage = (e) => {
        e.preventDefault()

        const packageData = {
            package: {
                name: name,
                price: price.current?.value,
                retail_price: retailPrice,
                is_featured: false,
                description: description,
                tag_list: tagList.current?.value,
                test_ids: createdPackage.map((test) => test.id),
                doctor_id: md_id,
            }
        }
        setLoading(true)
        axios({
            method: "POST",
            url: `${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/packages`,
            data: packageData,
            headers: { 'content-type': 'application/json', Authenticate: `Bearer ${localStorage.token}` },
        })
            .then((data) => {
                setLoading(false)
                if (data.status === 200) {
                    console.log(data?.data?.data)
                    createEnDetails(data?.data?.data?.package.id)
                   
                    toast.success("Package successfully created!")
                    navigate('/md/packages')
                } 
            })
            .catch((error) => { 
                setLoading(false)
                toast.error(error.message) 
            })
    }

    const createEnDetails = (package_id) => {
        const data = {
          locale: "en",
          name: name,
          description: description,
        }

    
        axios({
          method: "POST",
          url: `${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/packages/${package_id}/localization`,
          headers: { 'content-type': 'application/json', Authenticate: `Bearer ${localStorage.token}` },
          data: data,
          id: package_id
        })
          .then((data) => {
            console.log(data?.data)
          })
          .catch((error) => {
            console.log(error)
          })
    }
    
    const searchTests = (term) => {
        const tempList = []
        if (term.length === 0) setTestList(availableTest)
        availableTest.forEach((e) => {
            if (e.name.toLowerCase().includes(term.toLowerCase())) tempList.push(e)
        })
        setTestList(tempList)
    }



    return (
        <Layout>
            <main className='container p-5'> 
                <form onSubmit={handleAddPackage} className="mt-3 text-left">
                    <h2 className='text-secondary mt-5'>Add Package</h2>
                    <hr className='mb-5'/>

                    <div className='row jumbotron'>
                        <div className="col-sm-12 form-group mb-2">
                            <label>Name</label>
                            <input 
                            // ref={name} 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control rounded-0"  ></input>
                        </div>
                            
                        <div className="col-sm-6 form-group mb-2">
                            <label>Price</label>
                            <input ref={price} className="form-control rounded-0"  ></input>
                        </div>

                        <div className="col-sm-6 form-group mb-2">
                            <label htmlFor="floatingInput" className="text-secondary px-4">Retail Price</label>
                            <input
                            value={retailPrice}
                            onChange={(e) => setRetailPrice(e.target.value)}
                            className="form-control rounded-0"
                            type="number"
                            placeholder="Retail Price"
                            />
                        </div>

                        <div className="col-sm-12 form-group mb-2">
                            <label>Description</label>
                            <textarea 
                            // ref={description} 
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="form-control rounded-0 textarea"></textarea>
                        </div>
                        <div className="col-sm-12 form-group mb-5">
                            <label>Tags</label>
                            <input ref={tagList} placeholder="tag1, tag2, tag3" className="form-control rounded-0" ></input>
                        </div>

                        <div className="mb-3">
                            <p>AVAILABLE TESTS:</p>

                            <div>
                                <div className="w-100 p-1 d-flex justify-content-between align-content-center align-items-center">
                                    <button type="button" onClick={() => setShowPackages((s) => !s)} className="btn lah_gradient shadow-none border-0 text-white p-2"> {testQuantity} test/s selected</button>
                                </div>
                            </div>

                            <div className="product-container" style={{ display: showPackages ? "none" : "block" }}>
                                <div className='d-flex w-100 justify-content-end bg-red'><input className="form-control w-25 m-2 rounded-2" id="tableSearch" type="text" placeholder="Search tests/packages..." onChange={(e) => searchTests(e.target.value)} /></div>
                                    <div className="row bg-white">

                                        { testList.length === 0 ? "No data matches!" : testList.map((item) => {
                                            return (
                                                <div
                                                    key={`Test-${item.id}`}
                                                    className="form-check m-3"
                                                >
                                                    <input
                                                        className="form-check-input"
                                                        onChange={(e) => {
                                                            // add to list
                                                            if (e.target.checked) {
                                                                setCreatedPackage([
                                                                    ...createdPackage,
                                                                    {
                                                                        id: parseInt(item.id)

                                                                    },
                                                                ]);
                                                            } else {
                                                                // remove from list
                                                                setCreatedPackage(
                                                                    createdPackage.filter((test) => test.id !== item.id)
                                                                );

                                                            }
                                                        }}
                                                        value={createdPackage}
                                                        type="checkbox"
                                                    />
                                                    <label className="form-check-label">{item.name}</label>
                                                </div>
                                            );
                                        })}

                                    </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center">
                            <BackButton onClick={() => navigate(-1) }>Back</BackButton>
                            <NextButton>Submit</NextButton>
                        </div>
                    </div>
                </form>
            </main>
        </Layout> 
    )
}

export default CreateCustomPackage