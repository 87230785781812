import React from 'react'

const Pagetitle = () => {
  
    return (
        <>
            {
            window.location.href.includes('md/home')
            ?<div id="page-title" className='d-flex align-items-center'><span className="fs-5 text-secondary">🏠 Home</span></div>
            :window.location.href.includes('md/bookings')
            ?<span className="fs-5 text-secondary">🧾 Bookings</span>
            :window.location.href.includes('md/customers')
            ?<span className="fs-5 text-secondary">🧑 Patients</span>
            :window.location.href.includes('md/lab_results')
            ?<span className="fs-5 text-secondary">🧪 Lab Results</span>
            :window.location.href.includes('md/book_out_of_pocket')
            ?<span className="fs-5 text-secondary">📝 Order Out-Of-Pocket</span>
            :window.location.href.includes('md/book_with_insurance')
            ?<span className="fs-5 text-secondary">📝 Upload Lab Order Form</span>
            :window.location.href.includes('md/customers')
            ?<span className="fs-5 text-secondary">🧑 Patients</span>
            :window.location.href.includes('/md/packages')
            ?<span className="fs-5 text-secondary">🎁 Packages</span>
            :<></>
            }
        </>
  )
}

export default Pagetitle