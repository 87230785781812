import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { handleCloseModal } from '../../utils/helpers';

const AddPackageLocale = ({ packageData,  update, setUpdate  }) => {

  const { REACT_APP_MAINUS_BASEURL, REACT_APP_MD_MAINUS_BASEURL } = process.env;
  const [locale, setLocale] = useState({ value: "en" })
  const localizationOptions = [
    { name: "en", value: "en", },
    { name: "es", value: "es", }
  ]
  const [description, setDescription] = useState("")
  const [name, setName] = useState("")
  const md_id = localStorage.admin_id
  

  useEffect(() => {
    // console.log(test)
    if (packageData) {
      setName(packageData?.localizations?.filter((localeOptions) => localeOptions.locale === locale.value)[0]?.name || "");
      setDescription(packageData?.localizations?.filter((localeOptions) => localeOptions.locale === locale.value)[0]?.description || "");
    }
    // console.log(packageData?.localizations?.filter((localeOptions) => localeOptions.locale === locale.value)[0]?.description || packageData?.description || "")
  }, [packageData, locale])

 
  const addPackageLocalization = (e) => {
    e.preventDefault()
    
    const data = {
      locale: locale?.value,
      description: description,
      name: name
    }


    axios({
      method: "POST",
      url: `${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/packages/${packageData.id}/localization`,
      headers: { 'content-type': 'application/json', Authenticate: `Bearer ${localStorage.token}` },
      data: data,
      id: packageData.id
    })
    .then((data) => {
      if (data.status == 200) {
        updatePackageName(data?.data?.data?.package.id)
        toast.success("Updated package localization successfully!");
        handleCloseModal("AddPackageLocale")
        setUpdate(!update);
      }
    })
    .catch((error) => { toast.error(error.message) })
  }

  const updatePackageName = (id) => {
    const packageData = {
      package: {
        name: name,
        description: description
      }
    };
  
    axios({
      method: "PUT",
      url:`${REACT_APP_MAINUS_BASEURL}/packages/${id}`,
      // url:`http://127.0.0.1:3000/api/v2/internal/packages/${packageData?.id}`,
      data: packageData,
      headers: { Authenticate: `Bearer ${localStorage.token}`, 'content-type': 'application/json' }
    })
    .then((data) => {
      if (data.status == 200) {
        toast.success("Updated package name!");
      }
    })
    .catch((error)=> { 
    })
  }  

  return (
    <div className="modal fade" id="AddPackageLocale" data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content p-4">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">🧾 Add Package Localization</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div className="modal-body p-5 d-flex gap-4">
            {/* EN/default details */}
            <div className="col-6">
              <p><strong>{locale?.value === "en" ? "ENGLISH" : "SPANISH"}</strong></p>
              <p>Id: <strong> {packageData?.id}</strong></p>
              <p>Name:</p>
              <p><strong>{ name }</strong></p>
              <p>Description: </p>
              <p className="text-justify"><strong>{description}</strong></p>
            </div>

            {/* Add translation */}
            <div className="col-6">
              <form onSubmit={addPackageLocalization}>
                <div className="form-floating mb-4">
                  <select
                    value={locale.value}
                    onChange={(e) => setLocale({ value: e.target.value })}
                    className="form-control form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                  >
                    { localizationOptions?.map((option) => (
                      <option key={`option-${option.value}-${option.name}`} value={option.value}>{option.name}</option>
                    ))}
                  </select>
                  <label htmlFor="floatingInput" className="text-secondary">Locale</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    type="text"
                    placeholder="Name"
                  />
                  <label htmlFor="floatingInput" className="text-secondary">Name</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control textarea"
                    type="textarea"
                    placeholder="Description"
                    rows="8"
                  />
                  <label htmlFor="floatingInput" className="text-secondary">Description</label>
                </div>
                
                <div class="modal-footer">
                  <button className="cta">Save changes</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPackageLocale