import React, { useContext, useEffect }  from 'react';
import {useNavigate} from 'react-router-dom';
/* ----- Components ----- */
import { Layout } from '../../components/Layout/Layout';
import doctors from '../../assets/doctors.svg'
import { AuthContext } from '../../context/AuthContext';
import book_a_service from '../../assets/home-appointment.png'
import view_bookings from '../../assets/View-Bookings.png'
import view_lab_results from '../../assets/ViewLabResults.png'
import { toast } from 'react-toastify';
// import ParticlesConfig from '../../utils/ParticlesConfig';

import "../../Styles.scss"
import AppLogout from "../../components/AppLogout";

const Home = () => {
  const { userState } = useContext(AuthContext)
  const navigate = useNavigate()
  // console.log(userState)

  useEffect(()=>{
    if(userState?.role !== "Doctor"){
      toast.error("Unauthorized")
      return navigate("/")
    }
  },[])

  const handleNavigate = type => {
      type === "book_a_service"
      ? navigate('/md/book_service')
      : type === "view_bookings"
      ? navigate('/md/bookings')
      : type === "view_lab_results"
      ? navigate('/md/lab_results')
      : navigate('/md/home')
  }


  return (
   <>
   <AppLogout>
    <Layout>

      <main id="book__service"> 
      {/* <div ><ParticlesConfig /></div> */}
          <div className="col-md text-center mt-5 px-5">
            <h2 className='text-dark'>Hello,  { userState?.user ? userState.user.first_name ? `Dr. ${userState.user.first_name}!` : userState.user.email : ""  }</h2>
            {/* { userState?.office ? <h6 className='text-muted'> You are logged-in using your ( { userState.office } ) account.</h6> : <></> } */}
            <p className='h6 text-secondary'>What would you like to do today?</p>
            <button onClick={ ()=> navigate("/md/register")} className="btn lah_gradient text-white border-0 p-2 mt-2">Add a Patient</button>
          </div>

        <div className="container-fluid bg-trasparent my-4 p-5"> 
          <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-3 g-3">
           
            <div className="col"> 
              <div className="card h-100 shadow-sm"> 
                <img src={book_a_service} className="card-img-top" alt="book_out_of_pocket"/> 
                
                <div className="card-body"> 
                  <div className="clearfix mb-3"> 
                  
                  </div> 
                    <h5 className="card-title text-center">Create a home service appointment <br/>with your patient.</h5>   
                    <div className="text-center my-2"> 
                      <span onClick={ ()=> handleNavigate("book_a_service") } className="btn lah_gradient text-white border-0 p-2"><strong>Book a Service</strong></span> 
                    </div> 
                  </div> 
              </div> 
            </div> 

            <div className="col"> 
              <div className="card h-100 shadow-sm"> 
                <img src={view_bookings} className="card-img-top" alt="book_with_insurance"/> 
                
                <div className="card-body"> 
                  <div className="clearfix mb-3"> 
                  
                  </div> 
                    <h5 className="card-title text-center">Track, manage, and view the status <br/>of your patient's appointments.</h5> 
                    <div className="text-center my-2"> 
                      <span onClick={ ()=> handleNavigate("view_bookings") } className="btn lah_gradient text-white border-0 p-2"><strong>View Bookings</strong></span> 
                    </div> 
                  </div> 
              </div> 
            </div> 

            <div className="col"> 
              <div className="card h-100 shadow-sm"> 
                <img src={view_lab_results} className="card-img-top" alt="book_with_insurance"/> 
                
                <div className="card-body"> 
                  <div className="clearfix mb-3"> 
                  
                  </div> 
                    <h5 className="card-title text-center">View and manage <br/>the lab results of your patients.</h5> 
                    <div className="text-center my-2"> 
                      <span onClick={ ()=> handleNavigate("view_lab_results") }  className="btn lah_gradient text-white border-0 p-2"><strong>View Lab Results</strong></span> 
                    </div> 
                  </div> 
              </div> 
            </div> 
          </div> 
        </div> 
      </main>

    </Layout>
  )
  </AppLogout>
   </>
  )
}

export default Home