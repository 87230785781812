import React from 'react';

export default function Basket(props) {
  
  const { cartItems, onAdd, onRemove} = props;
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.price, 0);
  // const taxPrice = itemsPrice * 0.14;
  // const shippingPrice = itemsPrice > 2000 ? 0 : 20;
  const discountFee = itemsPrice * 0
  const serviceFee = 99
  // const serviceFee = 500
  // const totalPrice = itemsPrice + taxPrice + shippingPrice;
  const totalPrice = (itemsPrice + serviceFee)- discountFee;
  
 
  return (
    <aside className="h-100 h-custom">
      <h5 className='gradient-text'><i className='h5 bx bx-cart p-0 m-0'></i> Your Cart </h5>
      <div>
        { cartItems.length === 0 && <div><strong>Start by adding tests to your cart...</strong></div> }

        { cartItems && cartItems.map((item) => (
          
          <div key={item.id} className="row">
            <div className="col-8">{item.name}</div>
            <div className="col-2 d-flex gap-2">
              <button type="button" onClick={() => onRemove(item)} className="remove badge bg-info text-dark shadow-none border-0">
                -
              </button>{' '}
              <div>{item.qty}</div>
              <button type="button" onClick={() => onAdd(item)} className="add badge bg-info text-dark shadow-none border-0">
                +
              </button>
            </div>
            <div className="col-2 d-flex justify-content-between gap-2">
              <span>x</span>
              <span>${item.price}</span>
            </div>
            
            
          </div>
        ))}

        {cartItems.length !== 0 && (
          <>
            <hr></hr>
            <div className="row">
              <div className="col-4 qty-product">Items Price</div>
              <div className="col-4 qty-product text-right">${itemsPrice.toFixed(2)}</div>
            </div>
            <div className="row">
              <div className="col-4 qty-product">Discount</div>
              <div className="col-4 qty-product text-right">${discountFee.toFixed(2)}</div>
            </div>
            <div className="row">
              <div className="col-4 qty-product">Draw Fee</div>
              <div className="col-4 qty-product text-right">
                ${serviceFee.toFixed(2)}
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <strong>Total Price</strong>
              </div>
              <div className="col-8 text-right">
              {/* <strong>${ (Math.round(totalPrice * 100) / 100).toFixed(2) }</strong> */}
                <strong>${totalPrice.toFixed(2)}</strong>
              </div>
            </div>
            <hr />
            
          </>
        )}
      </div>
    </aside>
  );
}