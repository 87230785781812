import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];



const AppLogout = ({ children }) => {
  let timer;
  let timerNotification;

useEffect(() => {
  Object.values(events).forEach((item) => {
    window.addEventListener(item, () => {
      resetTimer();
      handleTimer();
    });
  });
}, []);

const resetTimer = () => {
  if (timer) clearTimeout(timer);
  if (timerNotification) clearTimeout(timerNotification);
};


const logoutToastId = "logout-toast-notification";

const AutoLogoutToast = () => (
  <div className="toast-logout-notification-body">
    <p>For your security, you are being timed-out due to inactivity. Please choose to stay signed in or to log off. 
Otherwise, you will be logged off automatically in one minute.</p>
    
    <button className="btn lah_gradient text-white border-0 px-3 px-sm-2 mx-2 mx-sm-1">continue</button>
  </div>
);



const handleTimer = () => {

  timerNotification = setTimeout(() => {
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });

    toast.error(AutoLogoutToast, {
      toastId: logoutToastId,
      autoClose: 59000,
      hideProgressBar: false,
      className: "toast-logout-notification",
      icon: false
    })
  
  }, 300000);

  timer = setTimeout(() => {
    resetTimer();
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });
    logoutAction();
  }, 360000);
};

const logoutAction = () => {

  localStorage.clear();
  window.location.pathname = "/";

};
  return children;
};



export default AppLogout;