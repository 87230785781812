import React,{ useState, useContext }  from 'react';
import Header from './Header';
import Sidebar from './Sidebar'
import { AuthContext } from '../../context/AuthContext';
import SidebarClinic from './SidebarClinic';


export const Layout = ({children, currentUser}) => {
    const { userState } = useContext(AuthContext)
    const [toggle, setToggle] = useState(false)
    const [isActive, setIsActive] = useState(false);

    const handleToggle =()=> {
        setToggle(!toggle)
        setIsActive(current => !current);
    }

  return (
    <div className={`container-fluid m-0 p-0 d-flex h-100 overflow-auto ${toggle ? "toggled" : ""}`} id="wrapper">  
      {userState?.role == "Doctor" ? <Sidebar /> : <SidebarClinic /> }
      <div id="page-content-wrapper" className='overflow-auto vh-100 scrollbar-div'> 
        <Header handleToggle={handleToggle} toggle={toggle} isActive={isActive} currentUser={currentUser}/>
        {children}
      </div>
    </div>
  )
}
