import React from 'react'

export default function NextButton({ onClick, children }) {
  
  return (
    <button onClick={onClick} className="btn lah_gradient text-white border-0 px-3 px-sm-2 mx-2 mx-sm-1">
        <div className='d-flex justify-content-center align-items-center p-2 p-sm-1'>
            <span>{children}</span>
            <i class='bx bxs-chevron-right back-icon'></i>
        </div>
    </button>
  )
}
