import React from 'react'

const Pagetitle = () => {
  
    return (
        <>
            {
            window.location.href.includes('md/clinic/home')
            ?<div id="page-title" className='d-flex align-items-center'><span className="fs-5 text-secondary">🏠 Home</span></div>
            :window.location.href.includes('md/clinic/bookings')
            ?<span className="fs-5 text-secondary">🧾 Bookings</span>
            :window.location.href.includes('md/clinic/patients')
            ?<span className="fs-5 text-secondary">🧑 Patients</span>
            :window.location.href.includes('md/clinic/lab_results')
            ?<span className="fs-5 text-secondary">🧪 Lab Results</span>
            :window.location.href.includes('md/clinic/doctors')
            ?<span className="fs-5 text-secondary">Doctors</span>
            :window.location.href.includes('md/contact_us')
            ?<span className="fs-5 text-secondary">☎️ Contact Us</span>
            :<></>
            }
        </>
  )
}

export default Pagetitle