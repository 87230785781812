import React, { useState, useEffect, useContext, useReducer } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios'
import { Layout } from '../../components/Layout/Layout';
import { AuthContext } from '../../context/AuthContext';
import Spinner from '../../components/Layout/Spinner';
import { toast } from 'react-toastify';
import no_data from '../../assets/nodata.svg';
import ReturnPrev from '../../components/ReturnPrev';
import { handleSort } from '../../utils/helpers';


const ClinicDoctors = () => {
   
    const { REACT_APP_CLINIC_BASEURL } = process.env;
    const { userState } = useContext(AuthContext)
    const navigate = useNavigate()
    const [clinicMDs, setClinicMDs] = useState([])
    const [patient, setPatient] = useState([])
    const [loading, setLoading] = useState(false)
    const [update, setUpdate] = useState(false);
    const requestHeaders = { 'Authenticate': `Bearer ${localStorage.token}`, 'content-type': 'application/json' }
    const clinic_id = localStorage.admin_id

    // useEffect(() => {
    //     console.log("Patients: ", clinicMDs)
    // }, [clinicMDs]);
    
    useEffect(() => {
        setLoading(true)
        axios.get(`${REACT_APP_CLINIC_BASEURL}/clinic/${clinic_id}/doctors`, { headers: requestHeaders })
        
        .then((data)=> {
        setLoading(false)
        //   console.log(data?.data?.data);
        const doctorsResp = data?.data?.data?.doctors
        setClinicMDs(...clinicMDs, doctorsResp)
        })
        .catch((error)=> { 
        setLoading(false)
        toast.error(error)
        })    
      }, []); 

    return (
        <Layout >
            <>
                <main className='container-fluid main-parent-container'>
                    { loading 
                    ?
                    <Spinner /> 
                    :  
                    <>
                    <div className='d-flex justify-content-end w-100 px-5 my-2'>  
                        <ReturnPrev />
                    </div>
                    { clinicMDs.length !== 0
                    ? 
                    <div className='card border rounded-3 table-card-container scrollbar-div'>  
                        <div className="table-responsive scrollbar-div">
                            <table className="bg-white table table-sm header-fixed">
                                <thead className="sticky-top bg-white" >
                                    <tr> 
                                        <th>ID</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                    </tr>

                                    </thead>
                                    <tbody> 
                                        { handleSort(clinicMDs)?.map((md, index) => {
                                            return (
                                                <tr>
                                                    <td>{md.id}</td>
                                                    <td>{md.first_name}</td>
                                                    <td>{md.last_name}</td>
                                                    <td>{md.mobile_number}</td>
                                                    <td>{md.email}</td>
                                                </tr> 
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    
                        :
                        <div className='w-100 position-relative'>
                            <div className='container-fluid w-100 d-flex flex-column justify-content-center align-items-center'><img src={no_data} className="no-data-img mb-4"/><span>No data available.</span></div>
                        </div>
                        }
                    </>
                    }
                </main>
            </>
        </Layout>
    )
}

export default ClinicDoctors