import React, { useState} from 'react'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
  GoogleMap,
  Marker,
} from '@react-google-maps/api'



const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';



const MapContainer = ({google, getLocation, currentPosition, setCurrentPosition }) => {

// const PlacesAutocomplete = () => {
  // const {currentPosition,setCurrentPosition} = useState({
  //   address:"201 S. Biscayne Blvd, Miami, Florida, United States",
  //   lat:25.77222,
  //   lng:-80.186748
  // })

  // const geocoder = new google.maps.Geocoder()

  const { REACT_APP_GMAP_API_KEY} = process.env
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    // requestOptions: input,
    debounce: 300
  })

  const registerRef = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })

  


  const handleInput = e => {
    // Update the keyword of the input element
    setValue(e.target.value)
  }

  const defaultCenter = {lat: 25.761681, lng: -80.191788}

  const reverseGeocode = ({ latitude: lat, longitude: lng}) => {
    const url = `${geocodeJson}?key=${REACT_APP_GMAP_API_KEY}&latlng=${lat},${lng}`;
    // searchInput.current.value = "Getting your location...";
    fetch(url)
        .then(response => response.json())
        .then(location => {
          
          // console.log(location.results[0].formatted_address)
          
          // setCurrentPosition({address:description, lat:lat, lng:lng}) 
          setCurrentPosition({address:location.results[0].formatted_address, lat:lat, lng:lng}) 
          // setAddress(location.results[0].formatted_address);
          setValue(location.results[0].formatted_address);
              
        })

      }

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        // console.log('📍 Coordinates: ', { lat, lng });
        setCurrentPosition({address:description, lat:lat, lng:lng}) 
        // console.log(currentPosition);
        // setNewCoordinates({ lat: lat, lng: lng })
      }).catch(error => {
        console.log(' Error: ', error)
      });
  }


  // set current location
  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        reverseGeocode(position.coords)
        // getLocation(position.coords)
      })
    }
  }


  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    
    reverseGeocode({latitude: lat, longitude: lng})
    // setCurrentPosition({address, lat, lng}) 
    
  }

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li
          key={id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    })



  return (
    <>
    <div className="d-flex flex-column w-100 justify-content-center align-content-center align-items-center">
      <div className="input-group" ref={registerRef}>
        <input
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Enter Patient Address"
          className="form-control rounded-start"
        />
        <button onClick={findMyLocation} className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04"><i className='bx bx-current-location text-danger px-3 loc-icon' ></i></button>
        {status === 'OK' && <ul className="w-100 z-top" >{renderSuggestions()}</ul>}
        
      </div>
    </div>
  
      <GoogleMap
        google={google}
        mapContainerStyle={{ width: '100%', height: '240px', position:'relative', margin:'12px', display:'none'}}
        zoom={14}
        center={ currentPosition?.lat ? currentPosition : defaultCenter }
      >
        <Marker
          position={ currentPosition?.lat ? currentPosition : defaultCenter }
          onDragEnd={(e) => onMarkerDragEnd(e)}
          draggable={true} 
        /> 
    </GoogleMap>
    
    </>
  );
};

export default MapContainer;