import React, {useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import logo from'../../assets/logo.png'
import { AuthContext } from '../../context/AuthContext';


const Footer = () => {
  const navigate = useNavigate()
  const { userState } = useContext(AuthContext)
  const footerStyle={
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  }

  return (
    <footer className="text-center text-lg-start text-white">
      <div className="text-center p-4" style={ footerStyle }>
        <span className="text-reset  fw-bold">{userState?.office}</span>
      </div>
    </footer>

  )
}

export default Footer