import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function FavoriteButton({ setUpdateFave, updateFav, favoritableProductId, favoritablePackageId, checkIfFave }) {
    const type = !!favoritablePackageId ? "Package" : "Product";
    const favoriteId = type === "Package" ? parseInt(favoritablePackageId) : parseInt(favoritableProductId);
    const { REACT_APP_MD_MAINUS_BASEURL } = process.env;
    const requestHeaders = { 'Authenticate': `Bearer ${localStorage.token}`, 'content-type': 'application/json' }
    const md_id = localStorage.admin_id
   
    const handleFavoriteClick = e => {
        e.preventDefault()
        
        const payload = {
          doctor_id: parseInt(md_id),
          type: type,
          favorite_id: favoriteId
        };

        if(checkIfFave) {
          axios({
            method: "DELETE",
            url:`${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/favorites/${!!favoritableProductId ? favoritableProductId : favoritablePackageId}/remove_favorite`,
            data: payload,
            headers: requestHeaders
          })
          .then((data) => {
            if (data.status === 200) {
              toast.success("You removed this item from your favorites")
              setUpdateFave(!updateFav)
            }
          })
          .catch((error)=> {
            toast.error(`Something went wrong, please try again.`)
          })
        } else {
          axios({
            method: "POST",
            url:`${REACT_APP_MD_MAINUS_BASEURL}/doctor/${md_id}/favorites/add_favorite`,
            data: payload,
            headers: requestHeaders 
          })
          .then((data) => {
            if (data.status === 200) {
              toast.success("You set this item to your favorites")
              setUpdateFave(!updateFav)
            }
          })
          .catch((error)=> { 
            toast.error("Something went wrong, please try again.")
          })
        }
    }

    return (
        <span onClick={ (e) => handleFavoriteClick(e) }>
            { 
              checkIfFave
                ? <i class='bx bxs-heart favorite-btn fave'></i>
                : <i class='bx bx-heart h4 favorite-btn'></i>
            }
        </span>
    );
}

export default FavoriteButton