import React, {useCallback } from 'react'
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";

const ParticlesConfig = () => {

    const particlesInit = useCallback(async engine => {
        // console.log(engine);
        await loadFull(engine);
      }, []);
    
      const particlesLoaded = useCallback(async container => {
          console.log(container);
      }, []);
    
  return (
    <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
            fpsLimit: 120,
            detectOn: "canvas",
            interactivity: {
                events: {
                    onClick: {
                        enable: false,
                        mode: "push",
                    },
                    onHover: {
                        enable: false,
                        mode: "repulse",
                    },
                    resize: true,
                },
                modes: {
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 200,
                      duration: 0.4,
                    },
                    grab: {
                      distance: 1000,
                      links: {
                        opacity: 1
                      }
                    },
                    bubble: {
                      distance: 400,
                      size: 40,
                      duration: 2,
                      opacity: 8,
                      speed: 3
                    },
                    remove: {
                      quantity: 2
                    }
                    
                },
            },
            particles: {
                color: {
                    value: "#4d70e2",
                },
                links: {
                    color: "#00cac2",
                    distance: 150,
                    enable: true,
                    opacity: 0.3,
                    width: 1,
                },
                // collisions: {
                //   enable: true,
                // },
                move: {
                    directions: "none",
                    enable: true,
                    bounce: false,
                    outMode: "out",
                    random: false,
                    speed: 2,
                    straight: false,
                    attract: {
                      enable: false,
                      rotateX: 600,
                      rotateY: 1200
                    }
                },
                number: {
                    density: {
                        enable: true,
                        area: 1000,
                    },
                    value: 42,
                },
                opacity: {
                    value: 0.3,
                    random: false,
                    anim: {
                      enable: false,
                      speed: 1,
                      opacity_min: 0.1,
                      sync: false
                    }
                },
                shape: {
                    type: "circle",
                    stroke: {
                      width: 0,
                      color: "#000000"
                    },
                    polygon: {
                        nb_sides: 5
                    },
                    image: {
                        src: "img/github.svg",
                        width: 100,
                        height: 100
                    }
                },
                size: {
                    value: 3,
                    random: true,
                    anim: {
                        enable: false,
                        speed: 6,
                        size_min: 0.1,
                        sync: false
                    }
                },
            },
            detectRetina: true,
        }}
      />
  )
}

export default ParticlesConfig