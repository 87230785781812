import React, { useState, useEffect, useReducer, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { useNavigate } from 'react-router';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
/* ----- Global ----- */
import { AuthContext } from './context/AuthContext';
import { BookingContext } from './context/BookingContext';

/* ----- Pages ----- */
import AuthPage from './pages/auth/AuthPage';
import Login from './pages/auth/Login';
import LoginClinic from './pages/auth/LoginClinic';
import RegisterPx from './pages/customer/RegisterPx';
import PasswordUpdate from './pages/auth/PasswordUpdate';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import EmailSent from './pages/email_sent/EmailSent';
import Home from './pages/home/Home';
import ClinicHomePage from './pages/home/ClinicHomePage';
import BookService from './pages/booking/BookService';
import Bookings from './pages/booking/Bookings';
import ClinicBookings from './pages/clinic/ClinicBookings';
import Customers from './pages/customer/Customers';
import ClinicPatients from './pages/clinic/ClinicPatients';
import LabResults from './pages/lab_result/LabResults';
import ClinicLabResults from './pages/clinic/ClinicLabResults';
import PageNotFound from './pages/page_not_found/PageNotFound';
import ContactUs from './pages/contact_us/ContactUs';
import BookingMulti from './pages/booking/BookingMulti';
import BookingMultiUpload from './pages/booking/BookingMultiUpload';
import ClinicDoctors from './pages/clinic/ClinicDoctors';
import BookOutOfPocket from './pages/booking/BookOutOfPocket';
import BookWithInsurance from './pages/booking/BookWithInsurance';
import CreateCustomPackage from './pages/package/CreateCustomPackage';
import PackageIndex from './pages/package/PackageIndex';

// import AppLogout from "./components/AppLogout";


/* ----- Styles $ Toast ----- */
import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

// const Login = lazy(()=> import("./pages/auth/Login"))
// const LoginClinic = lazy(()=> import("./pages/auth/Login"))
// const Booking = lazy(()=> import("./pages/booking/Bookings"))
// const Customers = lazy(()=> import("./pages/customer/Customers"))

/* ----- Headers ----- */
let user = localStorage.getItem("admin") ? JSON.parse(JSON.stringify(localStorage.getItem("admin"))) : null;
let role = localStorage.getItem("role") ? JSON.parse(JSON.stringify(localStorage.getItem("role"))) : null;
let office = localStorage.getItem("office") ? JSON.parse(JSON.stringify(localStorage.getItem("office"))) : null;
let token = localStorage.getItem("token") ? JSON.parse(JSON.stringify(localStorage.getItem("token"))) : null;
let isLoggedIn = localStorage.getItem("isLoggedIn") ? JSON.parse(JSON.stringify(localStorage.getItem("isLoggedIn"))) : null;

/* ----- Initialize states ----- */
const initialState = {
  user: user,
  role: role,
  office: office,
  token: token,
  isLoggedIn: isLoggedIn,
};

const initialBookingsState = {
  bookings: []
};


// export const AuthContext = React.createContext();
const AuthReducer = (initialState, action) => {
  switch (action.type) {

    case "LOGIN":
      return {
        ...initialState,
        user: action?.payload?.data?.data?.doctor || action?.payload?.data?.data?.clinic,
        role: action?.payload?.data?.data?.doctor?.role || action?.payload?.data?.data?.clinic?.role,
        office: action?.payload?.data?.data?.doctor?.office?.name,
        token: action?.payload?.token,
        sign_in_count: action?.payload?.data?.data?.doctor?.sign_in_count || action?.payload?.data?.data?.clinic?.sign_in_count,
        isLoggedIn: true
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: null,
        token: null,
        role: null,
        office: null,
        sign_in_count: null,
        isLoggedIn: false
      };
    case "REFRESH_TOKEN":
      return {
        ...initialState,
        token: action?.payload.updatedToken
      }


    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const BookingsReducer = (initialState, action) => {
  switch (action.type) {

    case "GET_LIST":
      return {
        ...initialState,
        website_bookings: action?.payload?.data?.data?.bookings.website_bookings,
        upload_bookings: action?.payload?.data?.data?.bookings.upload_bookings,
        bookings: [...action?.payload?.data?.data?.bookings.website_bookings, ...action?.payload?.data?.data?.bookings.upload_bookings]
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const App = () => {
  const { REACT_APP_MD_AUTH_BASEURL, REACT_APP_CLINIC_AUTH_BASEURL } = process.env;
  const navigate = useNavigate();
  const [userState, userDispatch] = useReducer(AuthReducer, initialState);
  const [bookingsState, bookingsDispatch] = useReducer(BookingsReducer, initialBookingsState);
  const [loading, setLoading] = useState(false)
  const [loggedInUser, setLoggedInUser] = useState()


  const getloginEndpoints = [
    `${REACT_APP_MD_AUTH_BASEURL}/login`,
    `${REACT_APP_CLINIC_AUTH_BASEURL}/login`
  ]

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (window?.location?.toString().includes("forgot_password") || window?.location?.toString().includes("reset_password")) {
        toast.error("You are currently logged in, please logout first")
        navigate('/')
      }

      Promise.all(getloginEndpoints.map((endpoint) => axios.get(endpoint, { headers: { Authenticate: `Bearer ${localStorage.token}`, 'content-type': 'application/json' } })))
        .then((data) => {
          let mdloggedIn = data[0];
          let clinicLoggedIn = data[1];
          // console.log(mdloggedIn)
          // console.log(clinicLoggedIn)
          setLoggedInUser(mdloggedIn || clinicLoggedIn)

          if (localStorage.getItem('role') === "Clinic") {

            userDispatch({ type: 'LOGIN', payload: clinicLoggedIn });
            if (window?.location?.pathname === "/md/clinic/login" || window?.location?.pathname === "/") {
              navigate('/md/clinic/home')
            }
          }
          else if (localStorage.getItem('role') === "Doctor") {

            userDispatch({ type: 'LOGIN', payload: mdloggedIn });

            if (window?.location?.pathname === "/md/login" || window?.location?.pathname === "/") {
              navigate('/md/home')
            }

          }
          else {
            navigate('/')
            localStorage.clear()
            toast.error('Session expired, please login')
          }
        })
        .catch((error) => {
          localStorage.clear()
          navigate('/')
          toast.error('Session expired, please login')
          setLoading(false)
        })
    }
    else if (window?.location?.toString().includes("forgot_password") || window?.location?.toString().includes("reset_password")) {
      console.log("Can access this page even if not logged in")
    }
    else {
      navigate('/')
      toast.error('Please login')
      localStorage.clear()
    }
  }, [])

  useEffect(() => {
    const URL = role === "Doctor" ? getloginEndpoints[0] : getloginEndpoints[1];
    const headers = { 'Authenticate': `Bearer ${localStorage.token}`, 'content-type': 'application/json' };
    if (localStorage.token) {
      axios.get(URL, { headers }).then((data) => {
        if (data.status === 200) {
          localStorage.setItem('token', data?.data?.token)
          userDispatch({ type: 'REFRESH_TOKEN', payload: { updatedToken: data?.data?.token } })
        }
      }).catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          userDispatch({ type: 'LOGOUT' })
        }
      });
    }

  }, [window.location.pathname])


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        pauseOnFocusLoss
        theme='dark'
      />

      <AuthContext.Provider value={{ userState, userDispatch }} >
        <BookingContext.Provider value={{ bookingsState, bookingsDispatch }}>
          <Routes>
            {/* <Route exact path="/" element={<AuthPage />} /> */}
            <Route exact path="/" element={<Login />} />
            <Route exact path="/md/login" element={<Login />} />
            <Route exact path="/md/register" element={<RegisterPx />} />
            <Route exact path="/md/home" element={<Home />} />
            <Route exact path="/md/clinic/login" element={<LoginClinic />} />
            <Route exact path="/md/clinic/home" element={<ClinicHomePage />} />
            <Route exact path="/md/book_service" element={<BookService />} />
            <Route exact path="/md/book_out_of_pocket" element={<BookOutOfPocket />} />
            <Route exact path="/md/book_with_insurance" element={<BookWithInsurance />} />
            <Route exact path="/md/bookings" element={<Bookings />} />
            <Route exact path="/md/clinic/bookings" element={<ClinicBookings />} />
            <Route exact path="/md/customers" element={<Customers />} />
            <Route exact path="/md/clinic/patients" element={<ClinicPatients />} />
            <Route exact path="/md/lab_results" element={<LabResults />} />
            <Route exact path="/md/clinic/lab_results" element={<ClinicLabResults />} />
            <Route exact path="/md/contact_us" element={<ContactUs />} />
            <Route exact path="/md/update_password" element={<PasswordUpdate />} />
            <Route exact path="/md/clinic/update_password" element={<PasswordUpdate forClinic={true} />} />
            <Route exact path="/md/forgot_password" element={<ForgotPassword />} />
            <Route exact path="/md/clinic/forgot_password" element={<ForgotPassword forClinic={true} />} />
            <Route exact path="/md/reset_password/" element={<ResetPassword />} />
            <Route exact path="/md/clinic/reset_password" element={<ResetPassword forClinic={true} />} />
            <Route exact path="/md/forgot_password/success" element={<EmailSent />} />
            <Route exact path="/md/clinic/doctors" element={<ClinicDoctors />} />
            <Route exact path="/md/package/add" element={<CreateCustomPackage />} />
            <Route exact path="/md/packages" element={<PackageIndex />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BookingContext.Provider>
      </AuthContext.Provider >
    </>
  )
}

export default App