// import React, { createContext, useState } from 'react'


// export const BookingsContext = createContext({
//     bookings: [],
//     setBookings: ()=> null
// })


// export const BookingsProvider = ({children}) => {
//     const [ bookings, setBookings ] = useState([])  
    

//     // const setBookingscb = (data) => {
//     //   setBookings(data)
//     // }

//     const value = { bookings, setBookings }


//   return (
//     <BookingsContext.Provider value={value}>
//       {children}
//     </BookingsContext.Provider>
//   )
// }



import React from "react";

const BookingContext = React.createContext();

export { BookingContext };
